import i18next from "i18next";
import {initReactI18next} from 'react-i18next';

import ru from "./ru"
import en from "./en"
import uk from "./uk"
// import pl from "./pl"
// import lv from "./lv"
import ln from "./ln"
import {getDefaultLanguage} from "helpers/localStorage";

/**
 * This will help to avoid loading big translation files and to be more convenient to look at the list of translations and edit it
 * Also we should write test for NS to checks, is all translations exist. Or use i18n internal functionality to do this
 */

const resources = {
  ru: {
    translation: ru
  },
  en: {
    translation: en
  },
  uk: {
    translation: uk
  },
  ln: {
    translation: ln
  },
  // pl: {
  //   translation: pl
  // },
  // lv: {
  //   translation: lv
  // },
};
const lng = getDefaultLanguage()
i18next
  .use(initReactI18next)
  .init({
    fallbackLng: false,
    returnEmptyString: false,
    initImmediate: true,
    resources,
    lng: lng,
  });
export default i18next;
