export default {
  long: {

    ru: 'Rusų',
    en: 'Anglų ',
    uk: 'Ukrainiečių',
    he: 'Hebrajų',
    pl: 'Lenkų',
    lv: 'Latvių',
    ln: 'Lietuvių'
  },
  short: {
    ru: 'Rus',
    en: 'Ang',
    uk: 'Ukr',
    he: 'Heb',
    pl: 'Len',
    lv: 'Lat',
    ln: 'Lietuvių',

  },
  /**
   * remove
   */
  ru: 'Rusų',
  en: 'Anglų',
  uk: 'Ukrainiečių',
  he: 'Hebrajų',
  pl: 'Lenkų',
  lv: 'Latvių',
  ln: 'Lietuvių',

}