import React, {Fragment, useState} from 'react'
import {Link} from 'react-router-dom'
import LanguageSwitcher from './LanguageSwitcher';
import IconBurger from 'components/Icons/Burger';
import NavBar from './Navbar';
import s from './index.module.scss'
import classNames from 'classnames/bind'
import {renderLogo} from "helpers/renderLogo";
import {NavStaticPage} from "containers/Pages/Static/Nav";
import UserActions from "./UserActions";
import HeaderSetting from "./HeaderSetting/HeaderSetting";
import {useLocation} from "react-router";
import {Trans} from "react-i18next";
import {getToken} from "../../helpers/localStorage";

const cx = classNames.bind(s)

type HeaderProps = {
  setting?: boolean,
  changePublish?: () => void,
  match?: any
}

function Header(props: HeaderProps) {
  const isAuth = !!getToken()
  const {pathname} = useLocation()
  const isCreateEvent = pathname.includes('/create-event')
  const [open, setOpen] = useState(false)
  const toggleNavBar = () => {
    setOpen(!open)
  }

  return (
    <Fragment>
      <header className={cx('Component')}>
        <div className={cx('Container')}>
          <Link className={cx('Logo')} to='/'>
            {renderLogo()}
          </Link>
          <NavStaticPage/>

          <div className={cx('Actions')}>
            {!pathname.includes('/create-event')
              && isAuth && <Link to='/create-event' className={cx('CreateLink')}>
                <Trans i18nKey="event.createEvent"/>
              </Link>}
            <LanguageSwitcher
              onChange={() => null}
              className={cx('LanguageSwitcher')}
              isShort={false}/>
            <UserActions/>
            <button
              type='button'
              onClick={toggleNavBar}
              className={cx('SidebarBtn')}>
              <IconBurger/>
            </button>
          </div>
        </div>
        {props.setting && !isCreateEvent && (
          <HeaderSetting
            match={props.match}
            changePublish={() => props.changePublish && props.changePublish()}/>
        )}
      </header>
      <NavBar isOpen={open} close={toggleNavBar}/>
    </Fragment>
  )
}

export default Header
