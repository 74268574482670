import React, {FC} from "react";
import s from './index.module.scss';
import classNames from "classnames/bind";
import ReactTooltip from "react-tooltip";
import {useTranslation} from "react-i18next";

const cx = classNames.bind(s);
type SeasonTicketPropsType = {
  className?: string,
  id: string,
  tooltipText?: string
}
export const SeasonTicket: FC<SeasonTicketPropsType> = ({className, id, tooltipText}) => {
  const [t] = useTranslation();
  return <div className={cx('Component', className || '')}>
    <div className={cx('MarkWrap')}>
      <div className={cx('Mark')}>{t('Season ticket')}</div>
      <svg
        data-tip data-for={String(id)} width="18" height="18" viewBox="0 0 18 18" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.99984 17.3332C4.39734 17.3332 0.666504 13.6023 0.666504 8.99984C0.666504 4.39734 4.39734 0.666504 8.99984 0.666504C13.6023 0.666504 17.3332 4.39734 17.3332 8.99984C17.3332 13.6023 13.6023 17.3332 8.99984 17.3332ZM8.99984 15.6665C10.7679 15.6665 12.4636 14.9641 13.7139 13.7139C14.9641 12.4636 15.6665 10.7679 15.6665 8.99984C15.6665 7.23173 14.9641 5.53603 13.7139 4.28579C12.4636 3.03555 10.7679 2.33317 8.99984 2.33317C7.23173 2.33317 5.53603 3.03555 4.28579 4.28579C3.03555 5.53603 2.33317 7.23173 2.33317 8.99984C2.33317 10.7679 3.03555 12.4636 4.28579 13.7139C5.53603 14.9641 7.23173 15.6665 8.99984 15.6665ZM8.1665 4.83317H9.83317V6.49984H8.1665V4.83317ZM8.1665 8.1665H9.83317V13.1665H8.1665V8.1665Z"
          fill="#BABABA"/>
      </svg>

    </div>
    <ReactTooltip id={String(id)} type="success" place="left"
                  effect="solid">
      <span className={cx('Text')} dangerouslySetInnerHTML={{__html: t(tooltipText || 'This ticket allows you to buy a ticket for the entire season')}}/>
    </ReactTooltip>
  </div>
}