export default {
  SET_EVENT_DATA: '@@eventEdit/SET_EVENT_DATA',
  EDIT: '@@eventEdit/EDIT',
  RESET: '@@eventEdit/RESET',
  REQUSET_UPDATE: '@@eventEdit/REQUEST_UPDATE_INFO',
  REQUEST_EVENT_DATA: '@@eventEdit/REQUEST_EVENT_DATA',
  ERROR_EVENT_DATA: '@@eventEdit/ERROR_EVENT_DATA',
  RESET_DATE: '@@eventEdit/RESET_DATA',
  ADD_TICKET_TYPE: '@@eventEdt/ADD_TICKET_TYPE',
  REMOVE_TICKET_TYPE: '@@eventEdt/REMOVE_TICKET_TYPE',
  ADD_TICKET_COLOR: '@@eventEdt/ADD_TICKET_COLOR',
  CHANGE_TICKET_TYPE: '@@eventEdt/CHANGE_TICKET_TYPE',
  ADD_ORGANIZER: '@@eventEdit/ADD_ORGANIZER',
  ADD_QUESTION: '@@eventEdit/ADD_QUESTION',
  EDIT_QUESTION: '@@eventEdit/EDIT_QUESTION',
  DELETE_QUESTION: '@@eventEdit/DELETE_QUESTION',
  ADD_UPDATE_FIELDS: '@@eventEdit/ADD_UPDATE_FIELDS',
  OPERATION_WITH_TICKET_TYPE_DELETE: "@@eventEdit/OPERATION_WITH_TICKET_TYPE_DELETE",
  OPERATION_WITH_TICKET_TYPE_UPDATE: "@@eventEdit/OPERATION_WITH_TICKET_TYPE_UPDATE",
  OPERATION_WITH_TICKET_TYPE_PRIORITY: "@@eventEdit/OPERATION_WITH_TICKET_TYPE_PRIORITY",

  OPERATION_WITH_QUESTION_ADD: '@@eventEdit/OPERATION_WITH_QUESTION_ADD',
  OPERATION_WITH_QUESTION_UPDATE: '@@eventEdit/OPERATION_WITH_QUESTION_UPDATE',
  OPERATION_WITH_QUESTION_PRIORITY: '@@eventEdit/OPERATION_WITH_QUESTION_UPDATE',

  SCHEMA_GENERATE: '@@eventEdit/SCHEMA_GENERATE',
  SCHEMA_ADD_ROW: '@@eventEdit/SCHEMA_ADD_ROW',
  SCHEMA_EDIT_ROW: '@@eventEdit/SCHEMA_EDIT_ROW',
  SCHEMA_COPY_ROW: '@@eventEdit/SCHEMA_COPY_ROW',
  SCHEMA_REMOVE_ROW: '@@eventEdit/SCHEMA_REMOVE_ROW',
}
