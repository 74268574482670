import React, {FC, forwardRef, memo, useState} from "react";
import UploadFile from "../../index";
import s from './index.module.scss';
import classnames from "classnames/bind";
import {UploadFilePropsType} from "../../index.props";
import IconClose from "../../../../Icons/Close";
import {useTranslation} from "react-i18next";
import {isSatchel} from "constants/";
import FieldError from "../../../../UI/FieldError";
import {getFileExtension} from "helpers/stringHelper";
import {ProccessLoadingIcon} from "containers/Pages/Event/Icons/Icons";

const cx = classnames.bind(s);
const UploadImage: FC<Omit<UploadFilePropsType, 'setLoad' | 'loading' | 'onChangeUpload'> & {
  img: string | null,
  onRemove: () => void,
  text?: string,
  formats?: Array<string>
} & { ref: any }> =
  forwardRef(({
                className,
                children,
                img,
                onRemove,
                text,
                formats = ['jpg', 'jpeg', 'png'],
                onChange,
                ...uploadFileProps
              }, ref) => {
      const [loading, setLoading] = useState(false)
      const [error, setError] = useState<null | string>(null);
      const [t] = useTranslation()
      const onUpload = async (file: any, fileName: string) => {
        try {
          setError(null);
          if (formats.some((format) => {
            return format === getFileExtension(fileName)
          })) {
            await onChange?.(file);
          } else {
            setError('Format invalid');
            setTimeout(() => setError(null), 2000)
          }

        } catch (e) {
          // @ts-ignore

        } finally {
          setLoading(false)
        }
      }

      return (
        <>
          <div className={cx('Component')}>
            <UploadFile
              loading={loading}
              setLoad={setLoading}
              // @ts-ignore
              ref={ref as any}
              accept="image/*"
              className={cx('UploadFile', className ?? '')}
              onChangeUpload={onUpload}
              {...uploadFileProps}

            >
              {img
                ? <img src={img} alt=""/>
                : <span
                  className={`button-orange-light ${isSatchel ? 'button-orange-light-black' : ''}`}>{t('event.ticket.uploadPicture')}</span>
              }

              <span className={cx('Pen')}>
              {loading && <div className={cx('loading')}>
                <ProccessLoadingIcon
                  // @ts-ignore*
                  width={50} height={50} fill={'var(--accent-color)'}/>
              </div>}
            </span>
              {!img && text && <p className={cx('Text')}>{t(text)}</p>}

            </UploadFile>
            {img && (
              <>
                <button onClick={() => onRemove?.()} className={cx("Close")}>
                  <IconClose/>
                </button>
              </>
            )}
          </div>
          {error && <FieldError error={error} classNames={cx('text-error')}/>}
        </>
      )
    }
  )
export default memo(UploadImage)