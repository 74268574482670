import {useSelector} from "react-redux";
import React, {Fragment} from "react";
import {HashLink} from "components/HashLink";
import animatedScroll from "helpers/animatedScroll";
import {useTranslation} from "react-i18next";
import s from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(s);

export function NavStaticPage() {
  const [t] = useTranslation()
  const isStaticPage = useSelector((state) => state.router.location.pathname === '/organizers')
  if (!isStaticPage) return <Fragment/>
  return (
    <nav className={cx('Nav')}>
      <HashLink
        scroll={(el) => animatedScroll(null, el)}
        to="/organizers#how-it-works">
        {t('landing.howItWorks.title')}
      </HashLink>
      <HashLink
        scroll={(el) => animatedScroll(null, el)}
        to="/organizers#pricing">
        {t('landing.pricing.title')}
      </HashLink>
      <HashLink
        scroll={(el) => animatedScroll(null, el)}
        to="/organizers#advantages">
        {t('landing.advantages.title')}
      </HashLink>
    </nav>

  )
}
