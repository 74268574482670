import "babel-polyfill"
import 'isomorphic-unfetch'
import 'react-app-polyfill/ie11';
import React from 'react'
import ReactDOM from 'react-dom'
import Raven from 'raven-js'
import AsyncRoot from 'containers/Root';
import * as serviceWorker from './service-worker'

if (!window.location.href.includes('localhost')) {
  Raven.config('https://8eac533c97a741769922ec08da4d171b@sentry.teeko.io/65', {
    environment: process.env.NODE_ENV
  }).install()
}
ReactDOM.render(<AsyncRoot/>, document.getElementById('root'))

serviceWorker.unregister()

