import React, {FC, forwardRef, Fragment, InputHTMLAttributes, Ref} from "react";
import s from './index.module.scss';
import classNames from "classnames/bind";
import {FieldError} from "react-hook-form";
import {parseChildren} from "helpers/parseChildren";
import {TextFieldPropsType} from "./index.props";

const cx = classNames.bind(s);

export const TextField: FC<TextFieldPropsType & InputHTMLAttributes<HTMLInputElement>> =
  forwardRef(({
                label,
                error,
                rightControl,
                classNameInputWrap,
                view = 'border-gray',
                classNameComponent,
                loading,
                disabled,
                showLoader,
                ...inputProps
              }, ref: Ref<HTMLInputElement>) => {
    return (
      <div
        className={cx('Component', {ComponentError: !!error}, view && 'Component_' + view, classNameComponent ?? '')}>
        {label && <label htmlFor={inputProps?.id}>{label}</label>}
        <div className={cx('WrapInput', classNameInputWrap || '')}>
          <input ref={ref} disabled={loading || disabled} {...inputProps} tabIndex={1}/>
          {rightControl ? parseChildren(rightControl) : ''}
          {(loading || showLoader) && <span className={cx('Loading')}>Loading</span>}
        </div>
        <TextError error={error}/>
      </div>
    )
  })

export function TextError(
  {
    error,
    className
  }: { error: FieldError | undefined | { message?: string } | null, className?: string }) {
  if (!error) return <Fragment/>
  return <p
    style={{color: 'red'}}
    dangerouslySetInnerHTML={{__html: error?.message ?? ''}}
    className={className}
  />
}
