import {DateTime} from "luxon";
import React, {Fragment, lazy} from "react";
import s from './index.module.scss';
import classNames from "classnames/bind";
import {useQuery} from "@apollo/client";
import {SLIDER_EVENTS} from "apollo/queries/SLIDER_EVENTS";
import useMatchMedia from 'use-match-media-hook'
import {toSlice} from "../../../../helpers/toSlice";

const SlideMobile = lazy(() =>/*webpackChunkName: "SliderMobile"*/ import("./SliderMobile"))
const SlideDesktop = lazy(() =>/*webpackChunkName: "SlideDesktop"*/ import("components/MyCarousel"))

const cx = classNames.bind(s);
const queries = [
  '(max-width: 1024px)'
]

const SliderEvents = () => {
  const [mobile] = useMatchMedia(queries)
  const {data} = useQuery<{ sliderEvents: Array<any> }>(SLIDER_EVENTS)
  const slides = data?.sliderEvents?.map(event => ({
    location: toSlice(event?.address, 30) ?? '',
    img: event?.image || require('assets/img/sharing-experience@2x.png'),
    title: toSlice(event?.name, 20) ?? '',
    description: event?.rawDescription.length > 100 ? event.rawDescription.slice(0, 100) + '...' : event.rawDescription ?? '',
    slug: event?.slug,
    date: event?.begins ? (`${DateTime.fromISO(event?.begins).toFormat('DD')}` + (event?.ends ? ` - ${DateTime.fromISO(event.ends).toFormat('DD')}` : '')) : ''
  })) || []

  if (data?.sliderEvents?.length === 0 || !data) return <Fragment/>
  if (mobile) return <SlideMobile slides={slides}/>
  return (
    <div className={cx('Component')}>
      {slides.length > 0 && <SlideDesktop time={2000} slides={slides}/>}
    </div>
  )
}
export default SliderEvents
