export default {
  intro: {
    header: "Bilietų pardavimo į jūsų renginius paslauga",
    analytics: "Analizė \nir ataskaitos",
    tickets: "PromoAndDiscount \npatvirtinimas ir aktyvavimas",
    money: "Išmokėti pelną \nį kortelę",
    notifications: "Klientų \npranešimai SMS žinutėmis",
    fb: "Sinchronizavimas su jūsų renginio \nFacebook puslapiu",
    promocode: "Reklaminiai kodai \nsu nuolaidomis",
  },
  howItWorks: {
    title: "Kaip tai veikia?",
    items: [
      {
        header: "Sukurti ir redaguoti savo įvykius",
        text:
          "Mūsų sistema leidžia jums sukurti ir tvarkyti savo renginį. Galite iš karto suprasti, kiek bilietų parduota ir kiek iki šiol uždirbote. Galite nustatyti puslapio išvaizdą, kad ji atitiktų jūsų prekės ženklą, ir bendrauti su auditorija naudodami renginio viršelio paveikslėlį ir aprašymą",
        list: [
          "greita registracija 24 valandas per parą, 7 dienas per savaitę",
          "Sąskaitos su skirtingais prieigos lygiais",
          "Klientų informavimas SMS žinutėmis",
        ],
      },
      {
        header: "Lengvesnė renginių rinkodara",
        text:
          "Mūsų sistema suteikia paprastas naudoti priemones, kuriomis galima eksportuoti ir importuoti dalyvių sąrašus į pašto kampanijų įrankius, pavyzdžiui, MailChimp, Sendgrid arba eSputnik.",
        list: [
          "Lanksti nuolaidų sistema naudojant promo kodus",
          "Naudokite mūsų įrankius su rinkodaros įrankių integracija",
        ],
      },
      {
        header: "Tvarkykite savo dalyvius ir tikrinkite rezultatus",
        text:
          "Įvairios ataskaitos padės suprasti, kurie kanalai yra veiksmingiausi. Be to, galite lengvai importuoti ir eksportuoti savo dalyvių bazę.",
        list: [
          "Įvairios ataskaitos",
          "Klientų importas ir eksportas",
          "Suprasti efektyvius pardavimo kanalus su mūsų ataskaitomis",
        ],
      },
    ],
  },
  pricing: {
    title: "Kainodara",
    items: [
      {
        header: "Nemokama renginių organizatoriams",
        text:
          "Jokių mėnesinių mokėjimų ir prenumeratos už naudojimąsi mūsų paslauga",
      },
      {
        header: "Nemokami bilietai į nemokamus renginius",
        text: "Visos nemokamų renginių funkcijos yra nemokamos",
      },
      {
        header: "Mažas mokestis už mokamus bilietus",
        text:
          "Iš viso imame 5 % mokestį už mokėjimų apdorojimą ir platformos aptarnavimą",
      },
    ],
  },
  aboutUs: {
    title: "Geriausias jūsų renginių valdymo asistentas",
    text:
      "satchel.events – tai renginių organizavimo ir valdymo platforma. Atsižvelgėme į geriausią dešimčių renginių organizatorių patirtį ir atsiliepimus, kad suteiktume geriausias ir naudingiausias funkcijas. Mūsų tikslas – paversti renginių valdymą jums lengvu ir produktyviu",
  },
  advantages: {
    title: "Privalumai",
    header: "Privalumai naudojantis mūsų paslauga",
    items: [
      {
        header: "Pagalba\n atsakant į jūsų klausimus",
        text: "Mes jums padedame nuo pirmojo puslapio iki paskutinio dalyvio",
      },
      {
        header: "Greita pardavimo pradžia\n",
        text:
          "Pardavimų paleidimas paprastai trunka nuo 1-2 valandų iki 1 dienos",
      },
      {
        header: "Greitas pasitraukimas\n iš bilietų pardavimo",
        text:
          "Pas mus pinigus gausite per 1-2 dienas po apmokėjimo, o ne po renginio",
      },
    ],
  },
  startCooperate: {
    header:
      "Pradėkite pardavinėti bilietus su satchel.events ir uždirbkite daugiau pinigų!",
    btn: "Pradėti dabar",
  },
};
