import React from 'react';

export const HouseIcon = ({fill = '#333'}: { fill?: string }) => (
  <svg width="31" height="27" viewBox="0 0 31 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.6253 26.6997H2.37533C1.98855 26.6997 1.61762 26.5461 1.34413 26.2726C1.07064 25.9991 0.916992 25.6281 0.916992 25.2414V14.2849C0.916969 14.0765 0.961604 13.8706 1.04789 13.6809C1.13417 13.4912 1.26011 13.3222 1.4172 13.1853L6.75033 8.53471V1.90804C6.75033 1.52127 6.90397 1.15033 7.17746 0.876843C7.45095 0.603352 7.82189 0.449707 8.20866 0.449707H28.6253C29.0121 0.449707 29.383 0.603352 29.6565 0.876843C29.93 1.15033 30.0837 1.52127 30.0837 1.90804V25.2414C30.0837 25.6281 29.93 25.9991 29.6565 26.2726C29.383 26.5461 29.0121 26.6997 28.6253 26.6997ZM11.1253 23.783H15.5003V14.9485L9.66699 9.86179L3.83366 14.9485V23.783H8.20866V17.9497H11.1253V23.783ZM18.417 23.783H27.167V3.36637H9.66699V6.46825C10.0082 6.46825 10.351 6.58783 10.6251 6.82846L17.9168 13.1853C18.0739 13.3222 18.1998 13.4912 18.2861 13.6809C18.3724 13.8706 18.417 14.0765 18.417 14.2849V23.783ZM21.3337 12.1164H24.2503V15.033H21.3337V12.1164ZM21.3337 17.9497H24.2503V20.8664H21.3337V17.9497ZM21.3337 6.28304H24.2503V9.19971H21.3337V6.28304ZM15.5003 6.28304H18.417V9.19971H15.5003V6.28304Z"
      fill={fill}/>
  </svg>
)
export const HouseSimpleIcon = ({fill = '#333'}: { fill?: string }) => (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.6667 5.68045L9.66675 1.30235C9.20839 0.892881 8.61502 0.666504 8.00008 0.666504C7.38514 0.666504 6.79177 0.892881 6.33341 1.30235L1.33341 5.68045C1.06872 5.91689 0.857496 6.20695 0.713806 6.5313C0.570115 6.85565 0.497256 7.20686 0.500079 7.56153V14.8362C0.500079 15.4984 0.763471 16.1335 1.23231 16.6018C1.70115 17.0701 2.33704 17.3332 3.00008 17.3332H13.0001C13.6631 17.3332 14.299 17.0701 14.7678 16.6018C15.2367 16.1335 15.5001 15.4984 15.5001 14.8362V7.55321C15.5017 7.19993 15.4283 6.85033 15.2846 6.5275C15.141 6.20467 14.9304 5.91596 14.6667 5.68045ZM9.66675 15.6685H6.33341V11.5068C6.33341 11.2861 6.42121 11.0744 6.57749 10.9183C6.73377 10.7622 6.94573 10.6745 7.16675 10.6745H8.83341C9.05443 10.6745 9.26639 10.7622 9.42267 10.9183C9.57895 11.0744 9.66675 11.2861 9.66675 11.5068V15.6685ZM13.8334 14.8362C13.8334 15.0569 13.7456 15.2686 13.5893 15.4247C13.4331 15.5808 13.2211 15.6685 13.0001 15.6685H11.3334V11.5068C11.3334 10.8446 11.07 10.2094 10.6012 9.74115C10.1323 9.27287 9.49645 9.0098 8.83341 9.0098H7.16675C6.50371 9.0098 5.86782 9.27287 5.39898 9.74115C4.93014 10.2094 4.66675 10.8446 4.66675 11.5068V15.6685H3.00008C2.77907 15.6685 2.5671 15.5808 2.41082 15.4247C2.25454 15.2686 2.16675 15.0569 2.16675 14.8362V7.55321C2.1669 7.43503 2.19224 7.31824 2.24109 7.2106C2.28995 7.10296 2.36119 7.00695 2.45008 6.92895L7.45008 2.55918C7.60215 2.42574 7.79766 2.35215 8.00008 2.35215C8.2025 2.35215 8.39801 2.42574 8.55008 2.55918L13.5501 6.92895C13.639 7.00695 13.7102 7.10296 13.7591 7.2106C13.8079 7.31824 13.8333 7.43503 13.8334 7.55321V14.8362Z"
      fill={fill}/>
  </svg>

)
