import React, {Fragment, PureComponent} from 'react'
import './index.scss'

import {getDefaultLanguage, logIn, RoleType} from 'helpers/localStorage'
import {Link} from 'react-router-dom'
import ForgotPasswordForm from './ForgotPasswordForm'
import PhoneVerificationForm from 'containers/PhoneVerificationForm'
import PasswordField from './components/PasswordField'
import PhoneInput from 'components/PhoneInput'
import Checkbox from 'components/Checkbox'
import FieldError from 'components/UI/FieldError'
import Loader from 'components/Loader'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import {Trans, withTranslation} from 'react-i18next';
import {edit, showSignModal} from 'store/Base/actions'
import {connect} from 'react-redux'
import {CHECK_FACEBOOK_ID, FACEBOOK_TOKEN_AUTH, SIGN_IN, SIGN_NOT_REGISTERED, SIGN_UP} from 'apollo/mutations'
import {CHECK_SIGN_UP_TOKEN} from 'apollo/queries';
import IconClose from "../../components/Icons/Close";
import s from './ModalSign.module.scss';
import classNames from 'classnames/bind';
import {FacebookCircleIcon} from "../Pages/Event/Icons/Icons";
import {addNotification} from "store/Notifications/actions";
import ImageParallax from "./components/ImageParallax/ImageParallax";
import {compose} from 'redux'
import client from 'apollo/'
import {Radio} from "../../components/UI/ReactHookForm/Radio";

const cx = classNames.bind(s);

const defaultState = {
  agreeWithTerms: false,
  attempts: 0,
  exceeded: false,
  agreeWithDataProcessing: false,
  userId: '',
  showForgotPassword: false,
  loadingSignUp: false,
  loadingSignIn: false,
  loadingInvitedUser: false,
  rememberMe: false,
  facebookToken: null,
  facebookId: null,
  isScroll: false,
  tab: 'login',
  error: '',
  showCodeForm: false,
  invitedUser: null,
  x: 0,
  y: 0,
  email: {
    value: '',
    error: ''
  },
  firstName: {
    value: '',
    error: ''
  },
  password: {
    value: '',
    error: ''
  },
  phone: {
    value: '',
    error: ''
  }
}

class ModalSign extends PureComponent {
  state = defaultState

  get isSignUpPath() {
    return this.props.location.pathname.includes('/signup/')
  }

  get isInvitedUser() {
    return !!this.state.invitedUser
  }

  get invitedToken() {
    return this.isSignUpPath ? this.props.location.pathname.replace('/signup/', '') : ''
  }

  // Close modal, reset state
  close = async () => {
    this.props.close()
    this.setState(defaultState)
  }

  resetErrors() {
    this.setState({
      error: ''
    })
  }

  onFacebookAuth = (result) => {
    if (!result.accessToken || !result.id) return false

    this.setState({
      facebookToken: result.accessToken,
      facebookId: result.id,
      email: {
        ...this.state.email,
        value: result.email
      }
    })

    // check is we have user with this id in system
    client.mutate({
      mutation: CHECK_FACEBOOK_ID,
      variables: {
        facebookId: result.id
      }
    }).then(resp => {
      const haveUser = resp.data.checkFacebookId.ok
      const tabLogin = this.state.tab === 'login'
      if (tabLogin && !haveUser) {
        this.setState({tab: 'sign up'})
        this.resetErrors()
      } else {
        this.facebookTokenAuth()
      }
    })
  }

  facebookTokenAuth = () => {
    // Auth in teeko system
    client.mutate({
      mutation: FACEBOOK_TOKEN_AUTH,
      variables: {
        facebookToken: this.state.facebookToken
      }
    }).then(resp => {
      this.setState({loadingSignUp: false})
      logIn(resp.data.tokenAuthWithFb.token, this.props.config.preventRedirect)
      this.props.close()
    }).catch(() => {
      this.setState({
        error: 'Invalid credantails',
        loadingSignIn: false
      })
    })
  }

  signIn = () => {
    client.mutate({
      mutation: SIGN_IN,
      variables: {
        email: (this.isInvitedUser && this.state.invitedUser.email) || this.state.email.value,
        password: this.state.password.value
      }
    }).then(resp => {
      logIn(resp.data.tokenAuth.token, false,
        this.state.tab === 'sign up'
          ? '/profile'
          : this.props.role === 'visitor'
            ? '/reservations'
            : null)
      localStorage.setItem('role', this.props.role)

      this.close()
    }).catch(() => {
      this.setState({
        error: 'Invalid credantails',
        loadingSignIn: false
      })
    })
      .finally(() => {
        this.setState({loadingSingIn: false})
      })
  }

  signUp = () => {
    const {email, phone, password, firstName} = this.state

    if (phone.error) {
      this.setState({loadingSignUp: false})
      return false
    }

    client.mutate({
      mutation: SIGN_UP,
      variables: {
        language: getDefaultLanguage().toUpperCase(),
        signup: {
          firstName: firstName.value,
          email: email.value,
          phone: phone.value.replace(/[^+\d]/g, ''),
          facebookId: this.state.facebookId || undefined,
          password: password.value
        }
      }
    }).then(resp => {
      this.setState({
        showCodeForm: true,
        userId: resp.data.signup.user.id,
        loadingSignUp: false
      })
    })
      .catch(e => {
        const error = e.graphQLErrors[0]
        if (error && error.message) {
          if (error.message.includes('phone')) {
            this.setState({
              loadingSignUp: false,
              phone: {
                ...this.state.phone,
                error: error.message
              }
            })
            return;
          }
          if (error.message.includes('email')) {
            this.setState({
              loadingSignUp: false,
              email: {
                ...this.state.email,
                error: error.message
              }
            })
            return;
          }
          switch (error.message) {
            case 'User with this Phone already exists.':
              this.setState({
                loadingSignUp: false,
                phone: {
                  ...this.state.phone,
                  error: 'User with this Phone already exists'
                }
              })
              break
            case 'Invalid phone number':
            case 'The phone number entered is not valid.':
              this.setState({
                loadingSignUp: false,
                phone: {
                  ...this.state.phone,
                  error: 'Invalid phone number'
                }
              })
              break
            case 'Please add phone number':
              this.setState({
                loadingSingUp: false,
                phone: {
                  ...this.state.phone,
                  error: 'Please add phone number'
                }
              })
              break;
            case 'Password must have at least 8 characters':
              this.setState({
                loadingSignUp: false,
                password: {
                  ...this.state.password,
                  error: error.message
                }
              })
              break
            case 'User with this Email already exists.':
              this.setState({
                loadingSignUp: false,
                email: {
                  ...this.state.email,
                  error: 'User with this Email already exists'
                }
              })
              break
            case 'Invalid email':
              this.setState({
                loadingSignUp: false,
                email: {
                  ...this.state.email,
                  error: 'Enter a valid email address'
                }
              })
              break

            default:
              return ''
          }
        }
      })
      .finally(() => {
        this.setState({loadingSignUp: false})
      })
  }

  // This method is usefull for users, that try to sign up with email notification (invited by ACL)
  signUpNotRegistered() {
    const {email, phone, password, invitedUser, firstName} = this.state

    if (phone.error) {
      this.setState({loadingSignUp: false})
      return false
    }

    client.mutate({
      mutation: SIGN_NOT_REGISTERED,
      variables: {
        language: getDefaultLanguage().toUpperCase(),
        token: this.invitedToken,
        signup: {
          firstName: firstName.value,
          email: invitedUser.email ? undefined : email.value,
          phone: invitedUser.phone ? undefined : phone.value.replace(/[^+\d]/g, ''),
          facebookId: this.state.facebookId || undefined,
          password: password.value
        }
      }
    }).then(resp => {
      if (invitedUser.phone) {
        this.signIn()
      } else {
        this.setState({
          showCodeForm: true,
          userId: resp.data.signupNotRegistered.user.id,
          loadingSignUp: false
        })
      }
    }).catch(e => {
      const error = e.graphQLErrors[0]

      this.setState({loadingSignUp: false})

      if (error && error.message) {
        switch (error.message) {
          case 'User with this Phone already exists.':
            this.setState({
              phone: {
                ...this.state.phone,
                error: 'User with this Phone already exists'
              }
            })
            break
          case 'Invalid phone number':
            this.setState({
              phone: {
                ...this.state.phone,
                error: error.message
              }
            })
            break
          case 'Password must have at least 8 characters':
            this.setState({
              password: {
                ...this.state.password,
                error: error.message
              }
            })
            break
          case 'User with this Email already exists.':
            this.setState({
              email: {
                ...this.state.email,
                error: 'User with this Email already exists'
              }
            })
            break
          case 'Invalid email':
            this.setState({
              email: {
                ...this.state.email,
                error: 'Enter a valid email address'
              }
            })
            break
          default:
            return ''
        }
      }
    })
  }

  componentDidMount() {
    localStorage.setItem('role', 'visitor')
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {config} = this.props
    if (config && !prevProps.config) {
      document.body.style.overflow = 'hidden'

      // if config is string -- this is `TabType` of `SignModalConfig`
      this.setState({tab: typeof config === 'string' ? config : config.tab})
      this.resetErrors()

      // if now is open SignUp page - request user`s info
      if (this.isSignUpPath) {
        this.requestUsersInfo()
      }
    } else if (!config && prevProps.config) {
      // Hide modal
      document.body.style.overflow = 'auto'
    }
  }
  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.sign(e)
    }
  }
  SignForm = () => {
    const {
      tab, email, phone, facebookToken,
      password, loadingSignUp, loadingSignIn,
      showForgotPassword, error, showCodeForm,
      invitedUser, firstName
    } = this.state
    const isLogin = tab === 'login'
    const isSignUp = tab === 'sign up'
    const showPasswordField = !facebookToken || isLogin
    const disableBtn = (loadingSignUp && isSignUp) || (loadingSignIn && isLogin)
    const displayEmailField = !this.isInvitedUser || !invitedUser.email
    const displayPhoneField = isSignUp && (!this.isInvitedUser || !invitedUser.phone)
    if (isLogin || !showCodeForm) {
      return (
        <>
          <form onKeyDown={e => {
            if (isSignUp && !this.state.agreeWithTerms) return;
            this.onKeyDown(e)
          }}
                onSubmit={e => this.sign(e)}>
            {isSignUp && (
              <div className={cx('simpleInput')}>

                <label>
                  <Trans i18nKey='user.firstName'/>
                  <input
                    required
                    className={firstName.error && 'border-danger'}
                    value={firstName.value}
                    onChange={e => this.setState({firstName: {value: e.target.value, error: ''}})}
                    type="text"/>
                  <FieldError error={firstName.error}/>
                </label>
              </div>
            )}
            {displayEmailField && (
              <div className={cx('simpleInput')}>
                <label>
                  Email
                  <input
                    required
                    autoComplete="username email"
                    className={email.error && 'border-danger'}
                    value={email.value}
                    onChange={e => {
                      this.setState({email: {value: e.target.value, error: ''}})
                    }}
                    type="email"/>
                  <FieldError error={email.error}/>
                </label>
              </div>
            )}
            {displayPhoneField && (
              <div className={cx('simpleInput')}>
                <label><Trans i18nKey='sign.phone'/></label>
                <PhoneInput
                  error={!!phone.error}
                  value={phone.value}
                  onChange={(value) => this.setState({phone: {value, error: ''}})}/>
                <FieldError error={phone.error}/>
              </div>
            )}

            {showPasswordField &&
              <div className={cx('simpleInput')}><label>
                <Trans i18nKey='sign.password'/>
                <PasswordField
                  password={password}
                  editField={(value) => this.setState({password: {value, error: ''}})}/>
                <FieldError error={password.error}/>
              </label>
              </div>}
            {isSignUp && <Fragment>

              <div className="agreeWithTerm">
                <Checkbox
                  onChange={(v) => this.setState({agreeWithTerms: v})}
                  checked={this.state.agreeWithTerms}
                  id="remember"
                  required={true}
                  custom
                />
                <label>
                  <Trans i18nKey='sign.iAgreeWith'/>
                  <Link onClick={this.close} target={"_blank"} to='/terms'>
                    <Trans i18nKey='sign.serviceTerms'/></Link>
                </label>
              </div>
            </Fragment>} {/*checkbox agree*/}
            <p className="text-danger text-center">{error && <Trans i18nKey={`error.${error}`}/>}</p>

            {isLogin &&
              <div className={'wrap-remember'}>
                <div className={cx('rememberCheck')}>
                  <Checkbox onChange={(v) => this.setState({rememberMe: v})}
                            checked={this.state.rememberMe}
                            id="remember"
                            custom
                  />
                  <label className={cx('wrap-agree')} htmlFor="remember">
                    <Trans i18nKey={'sign.rememberMe'}/>
                  </label>
                </div>
                <span
                  onClick={e => {
                    e.preventDefault()
                    this.setState({showForgotPassword: !showForgotPassword})
                  }}
                  className="forgot"><Trans i18nKey='sign.forgotBtn'/></span>
              </div>
            }

            <div className="login-row">
              <button
                className={cx('button-orange', 'mt20', 'mb20')}
                disabled={this.state.exceeded || disableBtn ||
                  (isSignUp && !this.state.agreeWithTerms)}>
                {disableBtn
                  ? <Loader/>
                  : <Trans i18nKey={`${isLogin ? 'sign.enter' : 'sign.create'}`}/>}
              </button>

            </div>
          </form>
        </>
      )
    }
    return null
  }
  sign = (e) => {
    e && e.preventDefault()
    const {tab, attempts, exceeded} = this.state

    if (attempts === 5) {
      this.setState({exceeded: true}, () => {
        setTimeout(() => {
          this.setState({exceeded: false, attempts: 0})
        }, 10000)
      })
      this.props.addNotification({
        type: 'error',
        content: <Trans i18nKey="error.attemptsError"/>,
        autoClose: 5000,
      })
      return false;
    } else {
      this.setState({attempts: this.state.attempts + 1})
    }
    if (tab === 'sign up') {
      this.setState({loadingSignUp: true})
      this.isInvitedUser ? this.signUpNotRegistered() : this.signUp()
    } else if (tab === 'login') {
      this.setState({loadingSignIn: true})
      this.signIn()
    }
    this.setState({loadingSingUp: false})
  }

  requestUsersInfo() {
    this.setState({loadingInvitedUser: true})
    client.query({
      query: CHECK_SIGN_UP_TOKEN,
      variables: {token: this.invitedToken}
    }).then(({data}) => {
      this.setState({invitedUser: data.checkInvitataionToken, loadingInvitedUser: false})
    }).catch(e => {
      this.setState({loadingInvitedUser: false})
      this.close()
    })
  }

  render() {
    const {tab, showForgotPassword, showCodeForm, loadingInvitedUser, facebookToken} = this.state
    const {config} = this.props
    const isLogin = tab === 'login'
    const isSignUp = tab === 'sign up'

    if (this.isSignUpPath && loadingInvitedUser) return (
      <div className={`overlay-modal ${!!config && 'show'}`}>
        <div className="modal">
          <Loader/>
        </div>
      </div>
    )
    return (
      <div className={`overlay-modal ${!!config && 'show'}`} onClick={this.close}>
        <div className={`modal ${!showForgotPassword && 'withImage'} ${showCodeForm && 'widthAuto'}`}
             onMouseMove={({clientX: x, clientY: y}) => window.innerWidth > 992 && this.setState({x, y})}
             onClick={e => e.stopPropagation()}>
          {!showForgotPassword && window.innerWidth > 992 && !showCodeForm &&
            <ImageParallax isLogin={isLogin} x={this.state.x} y={this.state.y}/>}
          {config && !config.disableClose && (
            <button onClick={this.close} className="close">
              <IconClose/>
            </button>
          )}

          <div className={`${!showForgotPassword && 'maxWidth500'}`}>
            {(!showForgotPassword) && <h3><Trans i18nKey={`sign.${isLogin ? 'in' : 'up'}`}/></h3>}
            <div className={`modal-main`}>
              {isLogin && !showForgotPassword && <>
                <Radio
                  onChange={({target: {value}}) => {
                    this.props.editBase({field: 'role', value})
                  }}
                  value={RoleType.Visitor}
                  classNameComponent={cx('Radio')}
                  checked={this.props.role === RoleType.Visitor}
                  id={'visitor'}
                  name={'role'}
                  label={this.props.t('Sign in as a event visitor')}
                />
                <Radio
                  onChange={({target: {value}}) => {
                    this.props.editBase({field: 'role', value})
                  }}
                  checked={this.props.role === RoleType.Organizer}
                  value={RoleType.Organizer}
                  classNameComponent={cx('Radio')}
                  id={'organizer'}
                  name={'role'}
                  label={this.props.t('Sign in as a organizer')}
                />
              </>}
              {(!showForgotPassword) && <this.SignForm/>}
              {isSignUp && showCodeForm
                && <PhoneVerificationForm
                  onSuccess={
                    this.state.facebookToken
                      ? this.facebookTokenAuth
                      : this.signIn
                  }
                  userId={this.state.userId}/>}
            </div>
            <div className="modal-footer">
              {isLogin && showForgotPassword && <ForgotPasswordForm/>}
              {(!showForgotPassword) && <>
                {(!facebookToken && !(showCodeForm)) && <>
                  <div className={cx('fz1728', 'c8c')}><Trans i18nKey={`sign.${isLogin ? 'orIn' : 'orUp'}`}/>:</div>
                  {/*<div className={cx('signWithApp', 'mt20', 'mb20')}>*/}
                  {/*  <FacebookLogin*/}
                  {/*    appId={process.env.REACT_APP_FACEBOOK_KEY}*/}
                  {/*    fields="name,email"*/}
                  {/*    isMobile={true}*/}
                  {/*    disableMobileRedirect={true}*/}
                  {/*    render={props => {*/}
                  {/*      return <button onClick={props.onClick} className={cx('fb')}>*/}
                  {/*        <FacebookCircleIcon/>*/}
                  {/*      </button>*/}
                  {/*    }}*/}
                  {/*    callback={this.onFacebookAuth}/>*/}
                  {/*</div>*/}
                </>
                }
                <div className={cx('fz1728', 'c8c')}>
                  {/*<Trans i18nKey={`sign.${isLogin ? 'createAccount' : 'loginAccount'}`}/>*/}
                  <button className={cx('btn-orange', 'fz1728')}
                          onClick={() => {
                            this.setState({tab: isLogin ? 'sign up' : 'login'})
                            this.resetErrors()
                          }}>
                    <Trans i18nKey={'sign.here'}/>
                  </button>
                </div>
              </>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

// for get more information about config - see `Base/actions`
const mapState = ({Base, router}) => ({
  config: Base.showSignModal,
  role: Base.role,
  location: router.location
})

const mapDispatch = (dispatch) => ({
  close() {
    dispatch(showSignModal(null))
  },
  addNotification(config) {
    dispatch(addNotification(config))
  },
  editBase({field, value}) {
    dispatch(edit({field, value}))
  }
})

export default compose(
  withTranslation(),
  connect(mapState, mapDispatch)
)(ModalSign)
