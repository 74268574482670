import React, {useCallback, useState} from "react";
import classNames from "classnames/bind";
import s from './PreviewBlock.module.scss'
import {WarningIcon} from "../Icons/Icons";
import {useDispatch} from "react-redux";
import {updateEventInfo} from "store/EventEdit/actions";
import Loader from "components/Loader";
import {useTranslation} from "react-i18next";
import {addNotification as addNotificationAction} from "store/Notifications/actions";
import {isSatchel} from "constants/";

const cx = classNames.bind(s)
const PreviewBlock = ({
                        isPublished,
                        canPublish,
                        refetch,
                        eventId,
                        slug,
                        locationName,
                        address
                      }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const updateEventInfoField = useCallback((field, value, id, slug) => dispatch(updateEventInfo(field, value, id, slug)), [dispatch])
  const addNotification = useCallback((alert) => dispatch(addNotificationAction(alert)), [dispatch])
  const [t] = useTranslation()
  
  const changePublish = (e) => {
    setLoading(true)
    if (!locationName || !address) {
      addNotification({
        type: 'error',
        content: t('error.fillDateLocationName'),
        autoClose: 5000,
      })
      setLoading(false)
      return;
  
    }
    updateEventInfoField('isPublished', !isPublished, eventId.length > 10 ? atob(eventId).replace(/\D/g, '') : eventId, slug)
      .then(() => {
        refetch()
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return <div className={cx('container', 'main')}>
    {loading && <div className={cx('wrap-loader-overlay')}><Loader/></div>}
    <WarningIcon width={25} height={25}/>
    <div>
      <p>{t(`event.${isPublished ? 'wantToAddDraft' : 'wantToAddPublish'}`)} {" "}
        {canPublish &&
        <span>{t(`event.${isPublished ? 'isDraftToAdd' : 'isPublish'}`)}</span>
        }
      </p>
      {canPublish &&
      <button
        onClick={changePublish}
        disabled={loading}
        className={`button-orange-light ${isSatchel? 'button-orange-light-black': ''}`}>
        {t(`event.${isPublished ? 'yesDraft' : 'yesPublish'}`)}
      </button>}
    </div>
  </div>
}
export default PreviewBlock
