import React, {FC} from "react";
import s from './index.module.scss';
import classNames from "classnames/bind";
import {PaperPropsType} from "./index.props";

const cx = classNames.bind(s);

export const Paper: FC<PaperPropsType> = ({children, view = 'paper', className, ...props}) => {
  return (
    <div className={cx(className || '', {Ticket: view === 'ticket'}, {Component: view === "paper"})} {...props}>
      <div className={cx('Circle', 'Right')}/>
      <div className={cx('Circle', 'Left')}/>
      {children}
    </div>
  )
}