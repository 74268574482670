import React, {DetailsHTMLAttributes, FC} from 'react'
import {FontWeight, TypographyPropsType, TypographyVariant, variantsMapping} from "components/Typography/index.props";

import classNames from 'classnames/bind';
import s from './index.module.scss'

const cx = classNames.bind(s)

export const Typography: FC<TypographyPropsType & DetailsHTMLAttributes<HTMLParagraphElement>>
  = ({
       variant = TypographyVariant.body,
       color,
       children,
       weight,
       className: propsClassName,
       ...props
     }) => {
  const Component = variant ? variantsMapping[variant] : 'p'
  const styles = {
    color: color ?? '#333',
    fontWeight: weight ?? 400
  }
  const classNames = cx(
    'typography', {
      [`typography--variant-${variant}`]: variant,
      [`typography--variant-${weight}`]: weight
    }) + " " + (propsClassName ?? '')
  return <Component
    className={classNames}
    style={color ? styles : null}
    {...props}
  >
    {children}
  </Component>
}
