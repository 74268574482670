export default {
  organizerLanding: 'Як це працює',
  company: 'Компанія',
  aboutUs: 'Про нас',
  team: 'Команда',
  career: 'Кар\'єра',
  contacts: 'Контакти',
  resources: 'Організаторам',
  terms: 'Умови користування',
  policy: 'Політика конфіденційності',
  support: 'База знань',
  faq: 'FAQ',
  telegram: 'Зв\'язатися з нами в telegram',
  cancellationPolicy: 'Політика скасування та відшкодування платежів',
  
  office: {
    title: 'Контакти',
    street: 'вул. Інститутська, 2а',
    city: 'Київ, Україна, 01001',
    address: 'Київ, вул. Інститутська, 2а',
  }
}
