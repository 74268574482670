export default {
  addOwnLetter: 'Добавить свое письмо',
  nameOwnLetter: 'Название письма',
  locationOnMap: 'Локация на карте:',
  needActiveFinance: 'Для публикации нужно иметь активный финансовый счет',

  needAddOrganizer: 'Необходимо добавить контактное лицо',

  descriptionOwnLetter: 'Описание письма',
  wantToAddDraft: 'Данное событие опубликовано.',
  isDraftToAdd: 'Возможно вы хотите его скрыть в черновики?',
  yesDraft: 'Да, скрыть',
  yesPublish: 'Да, опубликовать',
  wantToAddPublish: 'Данное событие не опубликовано и видно только тем, кому открыт доступ в satchel.events.',
  isPublish: 'Возможно вы хотите опубликовать событие?',
  timeWarningMessage: 'Устанавливая время, обратите внимание, что 00-00 это начало нового дня, а 23-59 окончание дня',
  withoutScript: 'Не используйте тег script',
  addTicket: 'Для публикации добавьте, как минимум, один тип билета',
  participants: {
    labels: {
      orders: 'Заказы',
      addParticipant: 'Добавить участника\n',
      invoices: 'Cписок выставленных счетов',

      emailParticipant: 'Email участника',
      name: 'Имя',
      firstCol: 'Имя',
      email: 'Email',
      phone: 'Телефон',
      ticketName: 'Название билета',
      code: 'Код',
      ticketTypePrice: 'Цена',
      createdFormat: 'Созданный',
      statusTrans: 'Статус',
      paymentType: 'Тип оплаты',
      coupon: 'Промокод'

    },
    filter: {
      all: 'Все заказы',
      allEndOrders: 'Все завершенные заказы',
      allNotEndOrders: 'Незавершенные заказы',
      orderWithPromo: 'Заказы с использованием промокода',
      orderAwait: 'Заказы, которые ожидают подтверждения'
    },
    searchPlaceholder: '№ заказа, имя, e-mail, номер телефона',
    filterDateFrom: 'Выберите период (с):',
    filterDateTo: 'До:'
  },
  beginsMoreEnds: 'Дата начала проведения события позже даты окончания события.',
  beginsTimeMoreEnds: 'Время начала проведения события позже времени окончания события.',
  ticketsFieldsRequired: 'Для создания нового вопроса вернитесь к секции "Создание билетов" и укажите данные поля: название билета, цена, для всех типов билета. ',
  promo: {
    addNewPromo: 'Добавление нового промокода\n',
    addNewGroup: 'Добавление групповой скидки\n',
    sumSale: 'Сумма скидки',
    name: 'Наименование',
    saleApply: 'Скидка применяется при покупке',
    from: 'от',
    to: 'до',
    maxQuantityUnlimited: 'Максимальное количество билетов для действия скидки, не ограничено',
    or: 'или',
    allTypes: 'Все типы',
    promoHasGroup: 'Данный промокод является групповым, скидка применяется при покупке от\n',
    tickets: 'билетов',
    dateActivate: 'Дата активации',
    dateDeactivate: 'Дата деактивации',
    timeActivate: 'Время активации',
    timeDeactivate: 'Время деактивации',
    promoUnlimited: 'Данный промокод не имеет ограничений в использовании\n',
    thisPromoUse: 'Данный промокод можно использовать\n',
    time: 'раз',
    nameDiscount: 'Название',
    activeFrom: 'Действителен с',
    activeTo: 'Действителен до',
    promoIsEmpty: 'Список ваших промокодов пуст',
    labels: {
      promo: 'Промокод',
      discount: 'Скидка\n',
      groupDiscount: 'Групповая скидка\n',
      code: 'Промокод',
      prefix: 'Префикс',
      isPresale: 'Presale',

      ticketTypes: 'Тип билета',
      validTo: 'Действителен до',
      type: 'Тип',
      activations: 'Количество',

      name: 'Название',
      begins: 'Действителен с',
      ends: 'Действителен до',
      validFrom: 'Действительный',
    },
    validNone: 'Не указано',
    f: 'С',
    t: 'До'
  },
  fieldEmptyDay1: 'У вас есть не заполненные поля! Пожалуйста, нажмите на',
  fieldEmptyDay2: ' и заполните все обязательные поля (помеченные звездочкой). ',
  fieldEmptyForMultilanguage: 'Обратите внимание что у вас мультиязычное событие и обязательные поля должны быть заполненны для всех языков',
  uploadDocument: 'Загрузка документов',
  orDropFileHere: 'Или перетащите файлы сюда',
  downloadFile: 'Загрузить файл',
  incorrectFormat: 'Изображение должно быть формата: jpeg, jpg, pdf',
  downloadDocument: 'Загрузить документы',
  warningForQuestion: 'Вы не можете сохранить новый вопрос не заполнив все обязательные поля (помеченные звездочкой).' +
    '\n' +
    'Возможно вы забыли указать поле "Вопрос" для другого языка',
  youChoice: 'Вы выбрали день',
  undoChange: 'Отменить изменения',
  saveChange: 'Сохранить изменения',
  promoIncorrect: 'Промокод неверный',
  nameQuestion: 'Наименование вопроса',
  actions: 'Действие',
  events: 'События',
  deleteContact: 'Удалить контакт',
  editContact: 'Изменить контакт',
  contactTitle: 'Контактные лица',
  responseFor: 'Отвечает за',
  selectContact: 'Выбрать контакт',
  goodJob: 'Отличная работа! Вы почти закончили.',
  goodJobEditPage: 'Отличная работа! Сохраните изменения.',

  goodJobDescription: 'Активируйте свой ивент, управляйте и отслеживайте свои продажи билетов с помощью отчетов и аналитики в реальном времени.',
  additionalPhone: 'Дополнительный номер телефона',
  linkFacebook: 'Ссылка на facebook',
  additionalContact: 'Дополнительный контакт',
  addNewContact: 'Добавить контакт',
  creatingEvent: 'Создание мероприятия',
  editEvent: 'Редактирование мероприятия',
  creatingEventDescription: 'Укажите информацию о вашем ивенте, заполните соответствующие поля и нажмите кнопку “сохранить”',
  commonInfo: 'Общая информация',
  addImageEvent: 'Добавьте фоновое изображение ивента',
  uploadImage: 'Загрузить изображение',
  recommendEventImage: 'рекомендуемый размер 665 x 350, файлы должны быть в форматах: jpeg, jpg, pdf',
  orDropHere: 'Или перетащите изображение сюда',
  dateAndLocation: 'Дата и локация',
  oneLocation: 'Oдна локация на все дни',
  eventOnline: 'Мероприятие Online',
  addDay: 'Добавить день',
  day: 'День',
  selectDateStart: 'Дата проведения ивента (начало) ',
  selectTimeStart: 'Время проведения ивента (начало)',
  selectTimeEnd: 'Время проведения ивента (окончание)',
  selectTimeZone: 'Выберите часовой пояс',
  settingFinance: 'Настройка счета для вывода денег',
  ddmmyy: 'дд/мм/гг',
  createTicket: 'Создание билетов',
  nameTicket: 'Название билета',
  quantityTicket: 'КОЛИЧЕСТВО',
  priceTicket: 'Цена',
  actionsTicket: 'ДЕЙСТВИЯ',
  ticketWithAgree: 'Билет с подтверждением (билет можно будет купить только с подтверждением от организатора)',
  simplePay: 'Купить несколько билетов на одну анкету',
  ticketColor: 'Цвет билета',
  hideSetting: 'Скрыть настройки',
  selectStartProfit: 'Дата начала продаж',
  selectEndProfit: 'Дата окончания продаж',
  selectTimeStartProfit: 'Время начала продаж',
  selectTimeEndProfit: 'Время окончания продаж',
  registrationFormTitle: 'Форма регистрации',


  eventIsEmpty: 'Список событий пуст',
  incorrectSymbol: 'Невалидный символ!',
  from: 'от ',
  add: 'Добавить',
  integrationModalTitle: 'Добавить',
  inputGA: 'Введите ваш код Google Analytics, чтобы получать статистику по ивенту:',
  inputFP: 'Введите ваш код Facebook Pixel, чтобы получать статистику по ивенту:',
  myLetter: 'Хочу свой текст для письма',
  moveFile: 'Перетащите файлы сюда или',
  fileFormat: 'Файлы должны быть в форматах: jpeg, jpg, pdf',
  popup: {
    messageFirst: 'Вы успешно зарегистрировались на мероприятие',
    messageSecond: 'Пожалуйста, проверьте вашу почту',
    messageThree: ': входящий, промо, спам'
  },
  errorMessage: {
    ticketsAreBought: 'Вы не можете удалить событие. Билеты уже куплены.',
    eventDelete: 'Событие успешно удалено.',
    eventDontDelete: 'Cобытие не удалено.'
  },
  registrationForm: {
    selectTypeQuestion: 'Виберите тип вопроса',
    typeText: 'Текстовое поле',
    typeOptions: 'Варианты ответов',
    typeFiles: 'Поле с файлом',
    description: 'Вопросы помеченые звездочкой, будут обязательны для заполнения в форме регистрации',
    modal: {
      title: 'Добавление нового вопроса ',
      withVariant: 'с вариантами ответа',
      withFile: 'с файловым полем',
      inputQuestion: 'Введите вопрос',
      selectTypeTickets: 'Выберите тип(ы) билета',
      requiredQuestion: 'Вопрос является обязательным и будет помечен',
      inputOwnAnswer: 'Введите свой ответ',

    }
  },
  subscribe: 'Подписаться на рассылку от satchel.events',
  create: 'Создать событие',
  duplicate: 'Дублировать',
  created: 'Событие создано',
  generatingPayment: 'Ожидайте, генерируем форму оплаты',
  edited: 'Событие изменено',
  createEvent: 'Создать событие',
  isDraft: 'Черновик',
  drafts: 'Черновики',
  draft: 'В черновики',
  currency: 'Валюта',
  published: 'Опубликовано',
  pastEvents: 'Прошедшие события',
  eventsArchive: 'События в архиве',
  modify: 'Кабинет события',
  view: 'Просмотр',
  preview: 'Предпросмотр',
  publish: 'Опубликовать',
  description: 'Описание',
  shading: 'Добавить затемнение картинки события',
  addEventImg: 'Добавить фоновую картинку события',
  eventImgRecommended: 'Рекомендуемый размер: 665x350',
  starts: 'Начало события',
  ends: 'Окончание события',
  startsSales: 'Начало продаж',
  endsSales: 'Окончание продаж',
  title: 'Название события',
  location: 'Место проведения события',
  locationTitle: 'Название места проведения события',
  locationDescription: 'Описание места проведения события',
  dateAndTime: "Дата и время",
  addInCalendar: "Добавить в календарь",
  shareEvent: "Поделиться событием с друзьями:",
  organizerName: 'Контактное лицо',
  organizerSpecialization: 'По каким вопросам обращаться',
  newOrganizer: 'Новое контактное лицо',
  editOrganizer: 'Изменить контактое лицо',
  addLogo: 'Добавить логотип',
  tickets: 'Билеты',
  organizerText: 'Всю остальную информацию о вашей организации вы можете добавить после создания или вы можете ',
  doItNow: 'сделать это сейчас',
  users: ' пользователей',
  ticketss: 'билетов',
  ticketsTitle: 'Промокоды и скидки',
  ticketsDelete: 'Билет успешно удален',
  isDeleteTicket: 'Удалить билет',
  registrationTitle: 'Форма регистрации',
  participantsTitle: 'Участники',
  billingTitle: 'Финансовая информация',
  integrationsTitle: 'Интеграции',
  statisticTitle: 'Статистика',
  accessTitle: 'Права доступа',
  ticketsSold: 'Билетов продано',

  totalIncome: 'Общий доход онлайн',
  phoneNumber: 'Номер телефона',
  agree: 'Я соглашаюсь с ',
  agreeUse: 'условиями использования ',
  agreeAnd: 'и ',
  agreePolicy: 'политикой конфиденциальности',
  totalAmount: 'Общая сумма:',
  showOnMap: 'Показать на карте',

  delete: 'Удалить событие',
  eventCart: {
    breadCrumb: {
      event: 'События',
    },
    tabs: {
      all: 'Все',
      active: 'Активные',
      passed: 'Прошедшие',
      draft: 'Черновик'
    },
    title: 'Управление событиями',
    contactPerson: 'Контактные лица',
    contact: 'контакта'
  },

  questions: {
    'First Name': 'Имя',
    'Last Name': 'Фамилия',
    'Phone Number': 'Номер телефона',
    'Email': 'Email',
  },
  attendees: {
    addForm: {
      title: "Добавить участника",
      selectTicketType: "Выберите тип билета:",
      price: "Оплаченная сумма"
    },
    showResult: 'Просмотр заказа',
    sendLetterAgain: 'Перевыслать e-mail с подтверждением',
    sendAll: 'Отправить всем',
    send: 'Отправить',
    activate: 'Активировать',
    deactivate: 'Деактивировать',
    refund: 'Вернуть деньги',
    activation: 'Активация билетов',
    base: 'База участников',
    enterTextSms: 'Введите текст SMS',
    status: "Статус",
    price: 'Оплачено',
    ticketPrice: 'Цена билета',
    date: 'Дата',
    questionnaire: 'Анкета',
    statuses: {
      UNFINISHED: 'Оплата незавершена',
      DECLINED: 'Подтверждение отклонено',
      PAID: 'Успешно оплачено',
      REGISTERED: 'Зарегистрирован',
      ERROR: 'Ошибка при оплате',
      DEACTIVATED: 'Деактивирован',
      ACTIVATED: 'Активирован',
      AWAITING_PAYMENT: 'Ожидается оплата',
      AWAITING_APPROVE: 'Ожидается подтверждение',
      ARCHIVED_UNFINISHED: 'Заархивирован'
    },
    filters: {
      ALL: 'Все',
      WEEK: 'За последнюю неделю',
      TODAY: 'За сегодня',
      PAID: 'Оплаченные',
      DECLINED: 'Неоплаченные',
      APPROVE: 'Ожидают подтверждение'
    },
    actions: 'Действия',
    approve: 'Подтвердить',
    decline: 'Отказать',
  },
  ticket: {
    inputInfo: 'Пожалуйста, введите информацию о госте ниже',
    important: 'Вы можете создавать различные типы билетов с разными ценами / ролями / датами продажи, сделать ограниченную / неограниченное количество каждого типа билета. Также вы можете задать дату и время, когда начинается или заканчивается продажа каждого типа билета. Например, создать билет "ранняя птичка" и задать дату и время, когда возможность покупки этого билета автоматически исчезнет с сайта. И в то же время автоматически появится возможность купить другой тип билета по более высокой цене, который был недоступен для покупки раньше. Вы можете создавать одноразовые промокоды, промокоды на определенное количество участников и промокод на неограниченное количество использований. Название промокода может быть задано вами, или автоматически сгенерированно сервисом. Скидку промокода можно фиксировать как в гривнах так и в процентах.То есть вы можете создать промокод со скидкой 10% или например со скидкой 100 гривен. Вы даже можете создать промокод со скидкой 100%, если это необходимо :) ',
    newType: 'Новый тип билетов',
    free: 'Бесплатно',
    name: 'Название билета',
    date: 'Дата',
    quantity: 'Количество',
    ticket: 'Билет',
    price: 'Цена',
    discount: 'Скидка',
    discountIncluded: 'Поздравляем, включена групповая скидка, это сохранило вам',
    saved: 'Поздравляем, с промокодом вы сэкономили',
    moreTicket: 'Еще один билет',
    addCoupon: 'Добавить промокод',
    addDiscount: 'Добавить групповую скидку',
    addDescription: 'Добавить описание',
    simpleCoupon: 'Обычный промокод',
    multipleCoupon: 'Несколько промокодов',
    promocode: 'Промокод',
    remainingTickets: 'Осталось билетов: ',
    total: 'Всего:',
    end: 'Билеты закончились',
    salesEnd: 'Продажа закончилась',
    signupTickets: 'Приобрести билеты: ',
    buy: 'Купить',
    pay: 'Оплатить',
    reg: 'Зарегистрироваться',
    buyTicket: 'Купить билет',
    buyTickets: 'Купить билеты: ',
    advancedSettings: 'Дополнительные настройки',
    withApprove: 'С подтверждением',
    simplifiedPurchase: 'Разрешить покупку более одного билета на одну анкету',
    uploadPicture: 'Загрузить картинку'
  },
  registration: {
    mainDescription: 'Описание перед формой регистрации',
    addQuestion: 'Добавить вопрос',
    editQuestion: 'Изменить вопрос',
    newQuestion: 'Новый вопрос',
    question: 'Вопрос',
    type: 'Тип',
    required: 'Обязательный вопрос',
    forSomeTypes: 'Для билетов',
    edit: 'Изменить',
    hint: 'Подсказка',
    create: 'Создать',
    simpleText: 'Строка ввода',
    bigText: 'Поле для текста',
    select: 'Список',
    dropdown: 'Список с поиском',
    options: 'Варианты ответов',
    addOption: 'Добавить вариант',
    questionPriority: 'Порядковый номер вопроса'
  },
  integrations: {
    text: 'Тут вы сможете добавить собственные скрипты ',
    ga: 'Введите ваш код Google Analytics, чтобы получать статистику по событию',
    fp: 'Facebook Pixel'
  },
  access: {
    header: 'Здесь вы можете управлять доступом к управлению событием другими людей.',
    items: [
      'Вы можете самостоятельно добавлять любое количество людей в администрирование событием и управлять их ролями:',
      '- Соорганизатор (имеет доступ редактировать все как основатель, но не может удалить событие).',
      '- Клиент-менеджер (имеет доступ к списку участников мероприятия, может выгружать в xlsx, csv) и может изменять Google Analytics ID.',
      '- Волонтеры (имеют доступ к списку участников и активации билетов на входе в дни мероприятия).',
      '- Оператор (может создавать промокод и редактировать описание события)',
    ],
    add: 'Добавить человека',
    role: 'Роль',
    roleType: {
      OPERATOR: 'Оператор',
      CO_ORGANIZER: 'Соорганизатор',
      SALES: 'Клиент-менеджер',
      VOLUNTEER: 'Волонтер'
    },
    statusType: {
      ADDED: 'Добавлен',
      INVITED: 'Приглашен'
    },
    name: 'Имя',
    phone: 'Телефон',
    status: 'Статус',
    user: 'Пользователь',
    actions: 'Действия',
    invite: 'Пригласить',
    edit: 'Редактировать',
    remove: 'Удалить',
    save: 'Сохранить',
    cancel: 'Отменить'
  },
  billing: {
    createBilling: {
      text: 'Пожалуйста, добавьте ',
      link: 'свои документы',
      orWait: ' для создания договора с satchel.events'
    },
    chooseBilling: 'Пожалуйста, выберите и сохраните счет',
    hideAll: 'Скрыть все',
    showAll: 'Показать все'
  },
  payment: {
    PENDING: 'Ваш платеж отправлен, пожалуйста ожидайте подтверждения.',
    OK: 'Поздравляем, ваш платеж принят!',
    FAILURE: 'Ваш платеж не принят, пожалуйста свяжитесь со службой поддержки.',
    downloadTickets: 'Скачать билеты',
    registratedSuccessful: 'Поздравляем, регистрация успешно завершена!',
    awaitingApprove: 'Некоторые из ваших билетов должны пройти проверку. Пожалуйста, дождитесь письма на указанную вами почту',
  },
  creatingHint: 'Пожалуйста, сначала создайте событие',
  save: 'Сохранить',
  organizerOf: 'Контактное лицо',

  mainSettings: {
    title: 'Основные настройки'
  },
  locationSettings: {
    title: 'Настройки места проведения'
  },
  languageSettings: {
    title: 'Языковые настройки',
    isMultilanguage: 'Мультиязычное событие',
    selectAvailable: 'Выберите доступные языки',
    selectDefault: 'Выберите основной язык'
  },
  hint: {
    previous_text: 'Ранее было введено: ',
    is_multilanguage_changed: '<0>Внимание!</0>\nВы изменили настойки мультиязычности вашего события, из-за чего ваше событие было отправлено в черновики.\nПожалуйста, проверьте наличие нужных вам переводов у собития, билетов и вопросов в форме регистрации.\n Затем опубликуйте событие заново.'
  }
}
