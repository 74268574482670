export default {
  'You can buy a ticket only if you have a promotional code': 'Bilietą galite nusipirkti tik turėdami reklamos kredito kodą.',
  'You can\'t create order without tickets.': 'Negalite sukurti užsakymo be bilietų.',
  'Row is required': 'Eilutė būtina',
  'Seat is required': 'Sėdynės būtina',
  'This ticket type is only with coupon': 'Šis bilietas yra tik su kuponu.',
  'Field is required': 'Lauką būtina užpildyti',
  'Email is not valid': 'El. paštas negalioja',
  'Incorrect coupon': 'Neteisingas kuponas',

  financeDeleted: 'Finansinė sąskaita ištrinta',
  financeNotDeleted: 'Finansinė sąskaita neištrinta',
  eventIsEmpty: 'Šis įvykis neegzistuoja arba organizatorius jo nepaskelbė',
  ups: 'Ups ..... ',
  somethingWrong: 'kažkas nutiko ne taip',
  integrationMinLength: 'Kodas yra per trumpas. Mažiausias simbolių skaičius turi būti daugiau nei 5',
  inputFinance: 'Įveskite finansinę informaciją',
  slugExists: 'Šis slugas jau egzistuoja',
  enterSlug: 'Įveskite slug',
  prevSlugNotAvailable: 'Atkreipkite dėmesį, kad senoji nuoroda į renginį nebus prieinama.',
  'slug egzistuoja': 'Atsiprašome, šio slug\'o išsaugoti negalima, jis jau užimtas',
  'Vartotojas su šiuo slapyvardžiu jau egzistuoja': 'Vartotojas su šiuo slapyvardžiu jau egzistuoja',
  'fillDateLocationName': 'Užpildykite vietos pavadinimą ir adresą',
  'fillAllDateLocationName': 'Įveskite visų kalbų vietos pavadinimą ir adresą',
  'attemptsError': 'Viršytas autorizacijos bandymų skaičius. Bandykite dar kartą po kelių sekundžių',
  'Prašome įkelti paveikslėlį': 'Prašome įkelti paveikslėlį',
  'Prašome pridėti telefono numerį': 'Prašome pridėti telefono numerį',
  'Organizatorius su šiuo vardu jau egzistuoja': 'Kontaktinis asmuo su šiuo vardu jau egzistuoja',
  'Organizatorius su šiuo el. pašto adresu jau egzistuoja': 'Kontaktinis asmuo su šiuo el. pašto adresu jau egzistuoja',
  'Organizatorius, turintis šį telefoną, jau egzistuoja': 'Kontaktinis asmuo su šiuo telefono numeriu jau egzistuoja',
  'incorrectSmsCode': 'Kodas neteisingas arba jo galiojimo laikas pasibaigęs',
  'emailVerificateError': 'Atsiprašome, bet patvirtinimo nuoroda nebėra aktyvi arba jau aktyvuota. Bandykite dar kartą.',
  'resetPasswordCodeError': 'Atsiprašome, bet nuoroda nebėra aktyvi. Bandykite dar kartą.',
  'Reikalingas laukas': 'Reikalingas laukas',
  'Negaliojantys įgaliojimai': 'Negaliojantys įgaliojimai',
  'Neteisingas telefono numeris': 'Neteisingas telefono numeris',
  'Įveskite galiojantį el. pašto adresą': 'Neteisingas el. pašto adresas',
  'Slaptažodį turi sudaryti ne mažiau kaip 8 simboliai': 'Slaptažodį turi sudaryti ne mažiau kaip 8 simboliai',
  'Naudotojas su šiuo el. pašto adresu jau egzistuoja': 'Naudotojas su šiuo el. pašto adresu jau egzistuoja',
  'Naudotojas su šiuo telefonu jau egzistuoja': 'Naudotojas su šiuo telefonu jau egzistuoja',
  'Jūs jau esate užsiprenumeravęs': 'Jūs jau esate užsiprenumeravęs',
  'Neteisingas senas slaptažodis': 'Neteisingas senas slaptažodis',
  'Neteisingas akcijos kodas': 'Neteisingas akcijos kodas',
  'Pabaigos data negali būti vėlesnė nei renginio pabaigos data': 'Pabaigos data negali būti vėlesnė nei renginio pabaigos data (%{data})',
  'Pradžios data negali būti vėlesnė už pabaigos datą': 'Pradžios data negali būti vėlesnė už pabaigos datą',
  'Negalite nustatyti praeities datos': 'Negalite nustatyti praeities datos',
  'Negalite nustatyti pabaigos datos praeityje': 'Negalite nustatyti pabaigos datos praeityje',
  'Negalite nustatyti pabaigos datos prieš pradžią': 'Negalite nustatyti pabaigos datos prieš pradžios datą',
  'Kažkas nepavyko, kreipkitės į palaikymo tarnybą': 'Kažkas nepavyko. Prašome susisiekti su palaikymo tarnyba. ',
  'Neteisingas kodas': 'Neteisingas kodas. Prašome dar kartą nuskaityti QR kodą',
  'Neturite prieigos, kreipkitės į organizatorių': 'Neturite leidimo aktyvuoti bilietą. Prašome susisiekti su organizatoriumi ',
  'Dalyvio statusas negalioja': 'Negalima aktyvuoti bilieto. Bilieto statusas: ',
  'Jūs negalite sau priskirti vaidmens': 'Jūs negalite sau priskirti vaidmens',
  'Kvietimas jau išsiųstas': 'Kvietimas jau išsiųstas',
  'Pasirinkite galimas kalbas': 'Pasirinkite galimas kalbas',
  'Įvyko klaida, patikrinkite laukus': ' Įvyko klaida. Prašome patikrinti įvestus duomenis.',
  'Daugiau bilietų nėra': 'Daugiau bilietų nėra',
  'Promokodas yra neaktyvus': 'Promokodas yra neaktyvus',
  'Prašome užpildyti visus laukus': 'Prašome užpildyti visus laukus',
  'Prašome užpildyti visus laukus visomis kalbomis': 'Prašome užpildyti visus laukus visomis kalbomis',

  'Prašome pridėti dienas': 'Prašome pridėti dieną',


}
