import React, {PureComponent, useEffect} from 'react'
import {Trans, withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {editAttendee, editAttendeeRow, editTicket, onCreateOrder, setAttendees} from 'store/Event/actions'
import {compose} from 'redux'
import AttendeeForm from './AttendeeForm'
import classNames from 'classnames/bind'
import s from './../Event.module.scss'
import {AddIcon, GiftIcon} from '../Icons/Icons'
import {CSSTransition, Transition, TransitionGroup} from "react-transition-group";
import {useGetUnoccupiedTickets} from "../../../../apollo/queries/useUnoccupiedTickets";

const cx = classNames.bind(s)

function RegistrationForm({
                            attendees,
                            checkoutUrl,
                            ticketTypes,
                            addNewAttendee,
                            paymentSubmitted,
                            currentDiscount,
                            isWithPromo,
                            editRow,
                            fetchSeats,
                            lng,
                            ...props
                          }) {

  const {data, refetch} = useGetUnoccupiedTickets({
    variables: {ticketTypeId: props.ticketType.oldId,}, notifyOnNetworkStatusChange: true
  })
  useEffect(() => {
    if ((fetchSeats?.attendeeIndex || fetchSeats?.attendeeIndex === 0)
      && fetchSeats?.ticketTypeId && +props.ticketType.oldId === +fetchSeats.ticketTypeId
    ) {
      refetch({ticketTypeId: fetchSeats?.ticketTypeId})
      editRow({
        field: 'seat', value: null,
        attendeeIndex: fetchSeats.attendeeIndex,
        ticketTypeId: fetchSeats.ticketTypeId

      })

    }
  }, [fetchSeats])
  const whichShowMessage = (currentDiscount) => {
    if (!currentDiscount.isGroupDiscount && currentDiscount.savings > 0) {
      return <><GiftIcon/><span> <Trans i18nKey={'event.ticket.saved'}/> {+currentDiscount.savings} EUR</span></>
    } else if (currentDiscount.isGroupDiscount) {
      return <><GiftIcon/><span><Trans i18nKey={'event.ticket.discountIncluded'}/>
        {+currentDiscount.savings} EUR</span></>
    }
  }
  const addAttendeeFormBtn = (ticketType, attendeesForms, addNewAttendee, ticketTypeId) => {

    if ((ticketType.availableQuantity ? ((ticketType.availableQuantity > attendeesForms.length) && !ticketType.simplePay) : !ticketType.simplePay)) {
      return (<div
        className={cx('add-item')}
        onClick={() => {
          addNewAttendee({id: ticketTypeId, value: +ticketTypes[ticketTypeId].selected + 1})
        }}>
        <AddIcon/>
        <span><Trans i18nKey={'event.ticket.moreTicket'}/></span>
      </div>)
    } else {
      return <div className={cx('add-item')}><span></span></div>
    }
  }
  const attendeesKeys = Object.keys(attendees)
  const attendeesLength = attendeesKeys.reduce((sum, id) => sum += attendees[id].length, 0)
  const bookPlaces = {}
  attendeesKeys.forEach((ticketTypeId) => {
    const rows = attendees[ticketTypeId]?.forEach(e => {
      if (e.row && e.seat) {
        if (ticketTypeId in bookPlaces) {
          if ([e.row] in bookPlaces[ticketTypeId]) {
            bookPlaces[ticketTypeId][e.row] = [...bookPlaces[ticketTypeId][e.row], e.seat]
          } else {
            bookPlaces[ticketTypeId][e.row] = [e.seat]
          }
        } else {
          bookPlaces[ticketTypeId] = {}
          bookPlaces[ticketTypeId][e.row] = [e.seat]
        }
      }
    })
  })
  if (checkoutUrl || !attendeesLength || paymentSubmitted) return null
  return (<div className='event-registration-form'>
    <TransitionGroup className="todo-list">
      {attendeesKeys
        .filter(ticketTypeId => ticketTypeId.toString() === props.ticketType.oldId.toString())
        .map(ticketTypeId => {
          const attendeesForms = attendees[ticketTypeId]
          const ticketType = ticketTypes[ticketTypeId]
          if (!attendeesForms.length) return null
          return (<CSSTransition
            key={ticketTypeId}
            timeout={500}
          >
            <div className='ticket-form' id='ticket-form'>
              <div className={cx('sale')}>

                <p className={cx('text-center')}>
                  <Transition timeout={500}
                              in={(!currentDiscount.isGroupDiscount && currentDiscount.savings > 0) || currentDiscount.isGroupDiscount}>
                    {state => (<div className={`fade ${state} `}>
                      {whichShowMessage(currentDiscount, props.currency)}
                    </div>)}
                  </Transition>
                </p>
              </div>
              <TransitionGroup>
                {attendeesForms.map((form, i) => (<CSSTransition
                    key={form.id}
                    timeout={300}
                    classNames="item"
                  >
                    <AttendeeForm
                      attendees={attendees}
                      bookPlaces={bookPlaces[ticketTypeId] || {}}
                      isRows={ticketType?.isRows}
                      rows={data?.getUnoccupiedTickets ?? []}
                      isWithPromo={isWithPromo}
                      lng={lng?.toLowerCase()}
                      getDiscountSum={props.getDiscountSum}
                      getTotalSum={props.getTotalSum}
                      id={props.id}
                      ticketTypeId={ticketTypeId}
                      attendee={form}
                      isMultilanguage={props.isMultilanguage}
                      defaultLanguage={props.defaultLanguage}
                      attendeeIndex={i}
                      editBillingAddress={props.editBillingAddress}
                    />
                  </CSSTransition>

                ))}
              </TransitionGroup>

              {addAttendeeFormBtn(ticketType, attendeesForms, addNewAttendee, ticketTypeId)}

            </div>
          </CSSTransition>)
        })}
    </TransitionGroup>
  </div>)

}

const mapState = ({Event, router}) => ({
  fetchSeats: Event.fetchSeats,
  attendees: Event.attendees,
  ticketTypes: Event.ticketTypes,
  checkoutUrl: Event.checkoutUrl,
  simplePay: Event.simplePay,
  paymentSubmitted: Event.paymentSubmitted,
  queryString: router.location.search,
})

const mapDispatch = (dispatch, props) => ({
  resetAttedeesErrors(attendees) {
    dispatch(setAttendees(attendees))
  },
  edit(payload) {
    dispatch(editAttendee(payload))
  },
  editRow({field, value, attendeeIndex, ticketTypeId}) {
    dispatch(editAttendeeRow({
      attendeeIndex,
      ticketTypeId,
      field,
      value,
    }))
  },

  addNewAttendee(payload) {
    dispatch(editTicket(payload))
    dispatch(setAttendees())
  }, onCreateOrder(order) {
    dispatch(onCreateOrder(order))
  },
})

export default compose(connect(mapState, mapDispatch), withTranslation(),)(RegistrationForm)
