export default {
  organizerLanding: 'Pricing',
  company: 'Company',
  aboutUs: 'About us',
  team: 'team',
  career: 'Career',
  contacts: 'Contacts',
  resources: 'Organizers',
  terms: 'Terms of Use',
  policy: 'Privacy Policy',
  support: 'Knowledge Base',
  faq: 'FAQ',
  telegram: 'Contact us on telegram',
  cancellationPolicy: 'Refunds and cancellation policy',
  
  office: {
    title: 'Contacts',
    street: 'st. Khreshchatyk, 10 ',
    city: 'Kyiv, Ukraine, 01001',
    address: 'Kyiv, Khreshchatyk, 10'
  }
}
