import React, {Fragment, memo, useEffect, useState} from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import {DateTime} from 'luxon';
import IconCalendar from 'components/Icons/Calendar';
import IconPin from 'components/Icons/Pin';
import {PosterEvents_posterEvents_edges_node} from 'apollo/__generated_types__/PosterEvents';
import Button from 'containers/Pages/Landing/Button';
import {useTranslation} from 'react-i18next';
import formatLanguage from 'helpers/formatLanguage';
import {Link} from 'react-router-dom';
import Loader from "../Loader";
import {LogoCard} from "../../containers/Pages/Event/Icons/Icons";
import LazyLoad from "react-lazyload";

const cx = classNames.bind(styles)

function EventCard({
                     name, locationName,
                     image, begins,
                     slug, timezoneField,
                     address, prices,
                     shortDescription, currency,
                     viewType = 'card',
                     isMultilanguage, isOnline,
                     defaultLanguage, ...eventData
                   }: PosterEvents_posterEvents_edges_node & { viewType?: 'card' | 'row' }) {
  const beginsDateTime = DateTime.fromISO(begins, timezoneField ? {zone: timezoneField} : {setZone: true})
  const [, i18n] = useTranslation()
  const formattedCurrentLng = formatLanguage(i18n.language)
  const formattedDefaultLng = formatLanguage(defaultLanguage)
  const somePay = prices && prices.some((el: any) => el > 0)
  const [loader, setLoader] = useState(true)
  useEffect(() => {
    if (!image) {
      setLoader(false)
    }
  }, [])
  const buttonText = somePay ? 'event.ticket.buyTicket' : 'event.ticket.reg';
  return (
    <li className={cx('Component', viewType === 'row' && 'Component_full')}>
      <Link to={`/event/${slug}`}
            className={cx('Image')}

      >
        {loader && <div className={cx('wrap-loader')}><Loader/></div>}
        {!image && <div className={cx('wrap-loader')}><LogoCard/></div>}
        <div>
          <LazyLoad throttle={200} height={370}  offset={0}>
            <img
              src={image}
              className={loader ? 'opacity0 posterImage' : cx('Img')}
              onLoad={() => {
                setLoader(false)
              }}
            />
          </LazyLoad>
        </div>

      </Link>
      <div className={cx('Main')}>
        {prices && prices.length !== 0 && (
          <span className={cx('Price')}>
            {prices.map((price, i) => {
              const separator = prices.length > 1 && prices.length - 1 > i ? ' - ' : null
              return (
                <Fragment key={i}>
                  <span>{Number(price) > 0 ? `${price} EUR` : "Free"}</span>
                  {separator && <span> {" "}{separator}{" "} </span>}
                </Fragment>
              )
            })}
          </span>
        )}
        <div className={cx('Info')}>
          <Link to={`/event/${slug}`} className={cx('Title')}>
            {!isMultilanguage
              ? name
              : (
                (eventData as any)[`name${formattedCurrentLng}`]
                || (eventData as any)[`name${formattedDefaultLng}`]
              )
            }
          </Link>
          <p className={cx('Param')}>
            <IconCalendar/>
            <span>{beginsDateTime.toFormat('HH:mm dd.MM')} {beginsDateTime.offsetNameShort}</span></p>
          <p className={cx('Param', 'Calendar')}><IconPin/> <span>{
           isOnline? 'Online': (!isMultilanguage
              ? locationName
              : (
                (eventData as any)[`locationName${formattedCurrentLng}`]
                || (eventData as any)[`locationName${formattedDefaultLng}`]
              )
          ) || address}</span></p>

          <p className={cx('Description')}>{shortDescription}</p>
        </div>

        <Button
          isLink={true}
          className={cx('Btn', 'orange')}
          text={buttonText}
          to={`/event/${slug}`}
        />
      </div>
    </li>
  )
}

export default memo(EventCard)
