import React, {memo} from 'react'
import ModalWrapper from "../ModalWrapper";
import CustomButton from "../Button/CustomButton/CustomButton";
import {Trans} from "react-i18next";

const ModalAgree = ({label, close, isOpen, onClick}) => {
  return (
    <ModalWrapper close={close} isOpen={isOpen}>
      <h4 className="caption">{label}?</h4>
      <div className={'wrap-btn  pt50'}>
        <CustomButton background={'#fff'} width={205} color={'var(--accent-color)'} border={'var(--accent-color)'}
                      onClick={close}>
          <Trans i18nKey={'event.access.cancel'}/>
        </CustomButton>

        <CustomButton width={205} onClick={onClick}>
          <Trans i18nKey={'event.access.remove'}/>
        </CustomButton>
      </div>
    </ModalWrapper>
  )
}
export default memo(ModalAgree)
