import i18n from '../locale'
import client from 'apollo/'
import {CHANGE_LANGUAGE} from 'apollo/mutations'
import store, {history} from 'store/'
import {requestProfile, reset, setToken} from 'store/Profile/actions'
import {reset as resetFinances} from 'store/Finances/financesReducer'
import {clearAll} from 'store/Notifications/actions'
import {edit} from "../store/Base/actions";

export function getDefaultLanguage(toCapitalize?: boolean) {
  let savedLng = window.localStorage.getItem('defaultLanguage')
  const browserLng = (navigator.language || (navigator as any).userLanguage).slice(0, 2)
  if (savedLng && !['en', 'uk', 'ru', 'pl', 'lv', 'ln'].includes(savedLng)) {
    window.localStorage.setItem('defaultLanguage', 'ln')
    savedLng = 'ln'
  }
  const defaultLanguage = savedLng || (['en', 'uk', 'ru', 'pl', 'lv', 'ln'
  ].includes(browserLng) ? browserLng : 'ln')
  return toCapitalize ? `${defaultLanguage[0].toUpperCase()}${defaultLanguage[1]}` : defaultLanguage
}

export const setLanguage = async (language = (getDefaultLanguage as any)()) => {
  window.localStorage.setItem('defaultLanguage', language)
  await i18n.changeLanguage(language)
  if (getToken()) {
    (client as any).mutate({
      mutation: CHANGE_LANGUAGE,
      variables: {
        language: language.toUpperCase(),
      },
    })
  }
}

export const logOut = (redirect = true) => {
  return new Promise((res: any, rej) => {
    localStorage.removeItem('token')
    localStorage.removeItem('referrer');
    localStorage.removeItem('role');
    (client as any).resetStore()
    store.dispatch(reset())
    store.dispatch(edit({field: 'role', value: RoleType.Visitor}))
    store.dispatch(resetFinances())
    localStorage.removeItem('role')
    store.dispatch(clearAll())
    if (redirect) {
      history.push('/')
    }
    res?.()
  })
}

export const getToken = () => localStorage.getItem('token')

export enum RoleType {
  Organizer = 'organizer',
  Visitor = 'visitor'
}

export const logIn = (token: string, preventRedirect?: any, redirectTo?: any) => {
  localStorage.setItem('token', token)
  store.dispatch(setToken(token))
  store.dispatch(requestProfile())
  if (!preventRedirect) {
    history.push(redirectTo || '/dashboard')
  }
}
