import 'isomorphic-unfetch';
import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import store from 'store/';
import { setErrorStatus } from 'store/Base/actions';
import { getToken, logOut } from 'helpers/localStorage';
import { REACT_APP_API } from 'constants/index';


const errorCodes = onError(({networkError, graphQLErrors}) => {
  const message = graphQLErrors && graphQLErrors[0]?.message
  switch (message) {
    case 'Password was changed after refresh token':
      logOut()
      return;
  }
  if (!networkError) {
    return false
  }
  let status = networkError.statusCode
  if (status === 400) {
    return false
  }
  if (status === 401) {
    logOut()
    return false
  }
  store.dispatch(setErrorStatus(status))
})


const cache = new InMemoryCache()

const httpLink = createHttpLink({
  uri:  REACT_APP_API
})

const authLink = setContext((_, {headers}) => {
  const token = getToken()
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
    }
  }
})


export default new ApolloClient({
  fetchOptions: {fetch},
  link: from([errorCodes, authLink, httpLink]),
  cache
})
