import {lazy} from "react";
import NotFound from '../Pages/NotFound/NotFound'
import Dashboard from '../Pages/Dashboard/Dashboard'
import AgreementCookie from "../Pages/Landing/AgreementCookie";
import Event from 'containers/Pages/Event'
import Posters from 'containers/Pages/Posters'
import OwnPosters from 'containers/Pages/Posters/OwnPosters'

const Profile = lazy(() => import(/* webpackChunkName: "Profile" */  'containers/Pages/Profile/Profile'))
const SmsConfirmation = lazy(() => import(/* webpackChunkName: "SmsConfirmation" */  'containers/Pages/SmsConfirmation'))
const Search = lazy(() => import(/* webpackChunkName: "Search" */'containers/Pages/Search'))
const Landing = lazy(() => import(/* webpackChunkName: "Landing" */'containers/Pages/Landing'))
const TicketActivation = lazy(() => import(/* webpackChunkName: "TicketActivation" */'containers/Pages/TicketActivation'))
const SignUp = lazy(() => import('containers/Pages/SignUp'))
const EventEdit = lazy(() => import('containers/Pages/EventEdit/EventEdit'))
const ResetPassword = lazy(() => import('containers/Pages/ResetPassword'))
const EmailVerification = lazy(() => import('containers/Pages/EmailVerification'))
const Terms = lazy(() => import(/* webpackChunkName: "Terms" */'containers/Pages/Terms'))
const Static = lazy(() => import(/* webpackChunkName: "Static" */'containers/Pages/Static'))
const Reservations = lazy(() => import(/* webpackChunkName: "Reservations" */'containers/Pages/Reservations'))
export {
  NotFound,
  Dashboard,
  AgreementCookie,
  Event,
  Posters,
  OwnPosters,
  Profile,
  Reservations,
  Search,
  Landing,
  TicketActivation,
  SignUp,
  EventEdit,
  ResetPassword,
  EmailVerification,
  Terms,
  Static,
  SmsConfirmation
}
