import {Dispatch, Ref, SetStateAction, useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {ApplicationState} from 'store/reducers';

export function useTab<TabsEnum>(initial: TabsEnum): { tabActive: TabsEnum, setTab: (tab: TabsEnum) => void } {
  const [tab, setTab] = useState<TabsEnum>(initial);

  return {
    tabActive: tab,
    setTab: (tab) => setTab(tab)
  }
}

export function useDidMount(fn: Function, dependencies: Array<any> = []) {
  const ref = useRef<boolean>(false)
  useEffect(() => {
    if (!ref.current) {
      ref.current = true
    } else {
      fn();
    }
  }, dependencies)
}

export function useModal<T>(initialState = false, initialPayload: T | null = null) {
  const [isOpen, setIsOpen] = useState<boolean>(initialState)
  const [payload, setPayload] = useState<null | T>(initialPayload)

  const open = (payload?: T) => {
    setIsOpen(true)
    setPayload(payload ?? null)
  }

  const close = () => {
    setIsOpen(false)
    setPayload(null)
  }
  const changePayload = (payload: T) => {
    setPayload(payload)
  }
  return {isOpen, open, close, payload, changePayload}
}

type UseDropdown = {
  onClose?: () => void,
  isOpen?: boolean
}

export function useDropdown({onClose, isOpen = false}: UseDropdown = {}): {
  open: boolean, toggle: any, ref: Ref<any>, setOpen: Dispatch<SetStateAction<boolean>>,
} {
  const [open, setOpen] = useState(isOpen)
  const ref = useRef<HTMLDivElement>(null)
  const toggle = () => setOpen(!open)

  useEffect(() => {
    const mousedownListener = (e: MouseEvent) => {

      if (ref.current && e.target && !ref.current.contains(e.target as Node)) {
        document.removeEventListener('mousedown', mousedownListener)
        setOpen(false)
      }
    }

    const keydownListener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setOpen(false)
        e.preventDefault()
        e.stopPropagation()
      }
    }

    if (open) {
      document.addEventListener('mousedown', mousedownListener)
      document.addEventListener('keydown', keydownListener)
    } else {
      document.removeEventListener('mousedown', mousedownListener)
      document.removeEventListener('keydown', keydownListener)
      onClose?.()
    }

    return () => {
      document.removeEventListener('keydown', keydownListener)
      document.removeEventListener('mousedown', mousedownListener)
    }
  }, [open])

  return {open, toggle, ref, setOpen}
}


/**
 * @param timeout time in seconds
 * @return left time in seconds, stop and reset methods
 */
export const useTimeout = (timeout: number) => {
  const [timeLeft, setTimeLeft] = useState(timeout)

  useEffect(() => {
    if (timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1)
      }, 1000)

      return () => {
        clearInterval(intervalId)
      }
    }
  }, [timeLeft])
  return {
    timeLeft,
    stop: () => setTimeLeft(0),
    reset: () => setTimeLeft(timeout),
  }
}

export function useStore<TSelected = unknown>(
  selector: (state: ApplicationState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
) {
  return useSelector(selector, equalityFn)
}
