import React, {FC} from 'react';
import {ColorEnum} from "../../../components/Typography/index.props";
import {DefaultIconPropsType} from './plus';

export const CheckDoubleIcon: FC<{ fill?: ColorEnum }> = ({fill = ColorEnum.black}) => {
  return (
    <svg width="22" height="13" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.602 8.46801L12.014 9.88001L20.48 1.41401L21.894 2.82801L12.014 12.708L5.65 6.34401L7.064 4.93001L9.189 7.05501L10.602 8.46701V8.46801ZM10.604 5.64001L15.556 0.687012L16.966 2.09701L12.014 7.05001L10.604 5.64001ZM7.777 11.295L6.364 12.708L0 6.34401L1.414 4.93001L2.827 6.34301L2.826 6.34401L7.777 11.295Z"
        fill={fill}/>
    </svg>

  )
}
export const CheckSimpleIcon: FC<{ fill?: ColorEnum }> = ({fill = ColorEnum.black}) => {
  return (
    <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.952 7.054L6.364 8.466L14.83 0L16.244 1.414L6.364 11.294L0 4.93L1.414 3.516L3.539 5.641L4.952 7.053V7.054Z"
        fill={fill}/>
    </svg>


  )
}
export const CheckIcon: FC<Omit<DefaultIconPropsType, 'orientation'>>
  = ({
       className,
       height = 9,
       width = 13,
       fill = ColorEnum.black
     }) => {
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 14 10" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M2 4.54414L5.45593 8L12.2994 2" stroke={fill} strokeWidth="2.5" strokeLinecap="round"/>
    </svg>
  )
}