import {gql, useQuery} from '@apollo/client'
import {QueryHookOptions, QueryResult} from '@apollo/client/react/types/types';

export type GetUnoccupiedTicketsVariables = {
  ticketTypeId: string
}
export type UnoccupiedRowType = {
  row: {
    seats: Array<number>,
    rowNumber: number
  }[]
}

export type GetUnoccupiedTicketsReturn = {
  getUnoccupiedTickets: {
    row: Array<UnoccupiedRowType>
  }[]
}

export function useGetUnoccupiedTickets(options?: QueryHookOptions<GetUnoccupiedTicketsReturn, GetUnoccupiedTicketsVariables>): QueryResult<GetUnoccupiedTicketsReturn, GetUnoccupiedTicketsVariables> {
  return useQuery<GetUnoccupiedTicketsReturn, GetUnoccupiedTicketsVariables>(GetUnoccupiedTicketsDocument, options)
}

export const GetUnoccupiedTicketsDocument = gql`
    query GetUnoccupiedTickets (
        $ticketTypeId: ID
    ) {
        getUnoccupiedTickets (
            ticketTypeId:   $ticketTypeId
        ) {
            row {
                seats
                rowNumber
            }
        }
    }
`
