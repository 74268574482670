import React from 'react';
import s from './index.module.scss';
import classNames from "classnames/bind";
import {useTranslation} from "react-i18next";

const cx = classNames.bind(s);
export const GoFuck = () => {
  const [t] = useTranslation()
  return (
    <div className={cx('Component')}>
      <div className={cx('container', 'wrap')}>
        <p className={cx('Text')}>{t('goFuck')}</p>
        <a href={'https://savelife.in.ua/donate/ '} target={"_blank"} className={cx('Btn')}>
          {t('helpArmy')}
        </a>
      </div>
    </div>
  )
}
