export type AttendeeType = {
  ticketType?: {
    isSeasonTicketType: boolean,
    isSmsConfirmation: boolean,
    event?: {
      id: any,
      name?: string,
      locationName?: string,
      timezoneField: string,
    }
  },
  statusConfirmation: AttendeeStatusConfirmationEnum,
  eventCurrency?: string,
  address?: string,
  ticketName: string,
  firstName: string,
  ticketTypePrice: number,
  lastName: string,
  email: string,
  begins?: string,
  ends?: string,
  phone: string,
  id: any
}

export enum AttendeeStatusConfirmationEnum {
  DEFAULT = 'DEFAULT',
  SENDING = 'SENDING',
  CONFIRMED = 'CONFIRMED',
  NO_CONFIRM = 'NO_CONFIRM'
}

export type UseAttendeeTicketsReturn = {
  getAttendeeTickets: Array<AttendeeType>
}

