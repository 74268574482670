import QuestionField from "../RegistrationForm/AttendeeForm/QuestionField";
import React, {FC} from "react";
import s from './index.module.scss';
import classNames from "classnames/bind";
import {useTranslation} from "react-i18next";
import {countries} from "../../../../helpers/countries";

const cx = classNames.bind(s);
type  BillingFormType = {
  "email": { value: string, error: null | string },
  "address": { value: string, error: null | string },
  "city": { value: string, error: null | string },
  "country": { value: string, error: null | string },
  "firstName": { value: string, error: null | string },
  "lastName": { value: string, error: null | string },
  "zip": { value: string, error: null | string },

}
export const BillingForm: FC<{ billingAddress: BillingFormType, edit: (line: keyof BillingFormType, field: 'value' | 'error', value: string) => void }> = (
  {
    billingAddress,
    edit
  }) => {
  const [t] = useTranslation()
  return (
    <div className={cx('Component')}>
      <h5 className={cx('fz2550', 'text-left')}>
        {t('Billing address')}
      </h5>

      <div className={cx('Fields')}>
        {(Object.keys(billingAddress) as any).map((fieldName: keyof BillingFormType) => {
          if (fieldName !== 'country') {
            return (
              <QuestionField
                maxLength={255}
                key={fieldName}
                // inputType={fieldName === 'zip' ? 'number' : null}
                error={billingAddress[fieldName].error}
                classNameComponent={cx('Field')}
                labelCustomName={'labelInput'}
                inputWrapCustomClass={'defaultInput'}
                name={t(fieldName)}
                value={billingAddress[fieldName].value}
                edit={(value: string) => {
                  edit(fieldName, 'value', value)
                }}
                questionType={'INPUT'} isRequired/>
            )
          }
          return (
            <QuestionField
              labelCustomName={'labelInput'}
              classNameComponent={cx('Field')}
              edit={(name: string) => edit(fieldName, 'value', countries?.find(c => c?.name === name)?.code ?? name)}
              key={fieldName}
              error={billingAddress[fieldName].error}
              questionType={'DROPDOWN'}
              name={t(fieldName)}
              value={
                billingAddress[fieldName].value
                  ? countries.find(c => c.code === billingAddress[fieldName]?.value)?.name
                  : billingAddress[fieldName].value}
              options={countries.map(c => c.name)}
              isRequired={true}
            />
          )
        })}
      </div>

    </div>
  )
}