import {gql} from "@apollo/client";
import {
  MULTILANGUAGE_NAME_EVENT_FIELDS,
  MULTILANGUAGE_DESCRIPTION_EVENT_FIELDS,
  MULTILANGUAGE_LOCATION_NAME_EVENT_FIELDS,
  MULTILANGUAGE_LOCATION_DESCRIPTION_EVENT_FIELDS
} from "../queries";

export const SLIDER_EVENTS = gql`
    query {
        sliderEvents {
            slug
            image
            begins
            ends
            rawDescription
            ${MULTILANGUAGE_NAME_EVENT_FIELDS}
            ${MULTILANGUAGE_DESCRIPTION_EVENT_FIELDS}
            ${MULTILANGUAGE_LOCATION_NAME_EVENT_FIELDS}
            ${MULTILANGUAGE_LOCATION_DESCRIPTION_EVENT_FIELDS}
            address
        }
    }
`
