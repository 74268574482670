export default {
  name: 'Pavadinimas',
  companyName: 'Įmonės pavadinimas',
  phone: 'Telefonas',
  password: 'Slaptažodis',
  enter: 'Prisijunkite',
  create: 'Sukurti',
  emailOrPhone: 'El. paštas arba telefonas',
  inUp: 'Prisijunkite / Užsiregistruokite',
  up: 'Paskyros kūrimas',
  in: 'Prisijunkite',
  out: 'Atsijungimas',
  orUp: 'arba užsiregistruokite su',
  orIn: 'arba prisijunkite naudodami',
  forgot: 'Slaptažodžio atkūrimas',
  forgotBtn: 'Pamiršote slaptažodį',
  rememberMe: 'Prisiminti mane',

  inputEmail: 'Įveskite el. pašto adresą, kurį naudojote registracijos metu:',
  needHelp: 'Man reikia pagalbos!',
  forgotSuccess: 'Jei esate užsiregistravę svetainėje, jums bus išsiųstas el. laiškas su nuoroda pakeisti slaptažodį.',
  email: 'Jūsų el. paštas',
  slogan: 'Įvykių valdymas ir bilietų pardavimas, kaip turėtų būti',
  recovery: 'Atkūrimas',
  enterNewPas: 'Įveskite naują slaptažodį',
  endSign: 'Registracijos pabaiga',
  passwordReseted: 'Slaptažodis sėkmingai pakeistas',
  vereficationMessage: 'Į jūsų telefono numerį buvo išsiųstas patvirtinimo kodas.',
  iAgreeWith: 'Sutinku su ',
  serviceTerms: 'paslaugų teikimo sąlygos',
  dataProcessing: 'mano asmens duomenų tvarkymas',
  createAccount: 'Nėra paskyros? Sukurkite ją',
  loginAccount: ' Jau turite paskyrą? Įveskite',

  here: 'čia'
}