import React from 'react'
import s from './NotFound.module.scss'
import classNames from 'classnames/bind'
import {DotStaticPage} from 'containers/Pages/Event/Icons/Icons'
import {Triangle} from '../Event/Icons/Icons'
import {Trans} from "react-i18next";
import {useHistory, useLocation} from "react-router";
import Header from "components/Header";

const cx = classNames.bind(s)

function NotFound(
  {
    className ='', children, isEmpty = false, content = true, render = null
  }) {
  const {push} = useHistory()
  const {pathname} = useLocation()

  const handleBack = (path = '') => () => {
    if (path) {
      push(path)
      return;
    }
    if (!pathname.includes('/dashboard')) {
      push('/')
    } else {
      if (window.history.length) {
        window.history.back()
      } else {
        window.location.reload()
      }
    }
  }

  return (
    <div className={`${cx('wrap-static')} ${className}`}>
      <Header/>

      <section className={cx('s-box')}>
        <div className="container">
          <div className={cx('wrap-box')}>
            <Triangle left={-52} top={110} width={51} height={41} fill={'var(--accent-color)'} rotate={'10'}/>
            <Triangle right={102} bottom={110} width={51} height={41} fill={'var(--accent-color)'} rotate={'135'}/>
            <DotStaticPage left={0} width={39} height={39} bottom={145} stroke={'var(--accent-color)'}/>
            <div className={cx('box')}>
              <DotStaticPage left={-184} width={19} height={19} top={10} stroke={'var(--accent-color)'}/>
              <DotStaticPage right={-48} top={140} width={34} height={34} stroke={'var(--accent-color)'}/>
              {children}
              {render && render(handleBack)}
              <div className={cx('content', {empty: isEmpty})}>
                {content && <>
                  <button onClick={handleBack()}>
                    <Trans
                      i18nKey={`${!pathname.includes('/dashboard') ? 'toHome' : window.history.length ? 'back' : 'user.refresh'}`}/>
                  </button>
                  <div>
                    <strong><Trans i18nKey={'error.ups'}/> </strong>
                    <span><Trans i18nKey={'error.somethingWrong'}/></span>
                  </div>
                </>}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default NotFound
