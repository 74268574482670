import React from 'react'
import {createAction} from 'helpers/createAction'
import client from '../../apollo/'
import {EVENT} from 'apollo/queries'
import {DateTime} from 'luxon'
import ActionTypes from './actionTypes'
import {addNotification} from 'store/Notifications/actions'
import {Trans} from 'react-i18next';
import UPDATE_EVENT from 'apollo/mutations/UPDATE_EVENT';
import {SET_FINANCE_ID} from "../Finances/financesReducer";


export const loadEvent = (slug) => async (dispatch) => {
  dispatch({type: ActionTypes.REQUEST_EVENT_DATA})
  const eventRequest = await client.query({
    query: EVENT,
    fetchPolicy: 'no-cache',
    variables: {
      slug
    }
  })
  const event = eventRequest.data.event
  const timezoneField = event.timezoneField || DateTime.local().zoneName
  // convert API's availableLanguages array to local availableLanguages object
  let availableLanguages = {
    en: false,
    uk: false,
    ru: false,
    // pl: false,
    // lv: false,
    ln: false
  }

  event.availableLanguages && event.availableLanguages.forEach(lng => availableLanguages[lng] = true)

  event.ticketTypes && event.ticketTypes.length > 0 && event.ticketTypes.map(ticketType => {
    ticketType.oldTicket = true
    if (ticketType.color === null) {
      ticketType.color = '#00B68A'

    }
    if (ticketType.rows) {
      // ticketType.isRows = ticketType.isRows
      ticketType.from = ticketType.rows?.[0]?.rowNumber
      ticketType.to = ticketType.rows.length - 1 + ticketType.rows?.[0]?.rowNumber
    }
    ticketType.questions = ticketType.questions.map(question => ({...question, oldQuestion: true}))

  })
  let ticketTypeIdQuestions = event.ticketTypes && event.ticketTypes.length > 0 && event.ticketTypes[0].id

  dispatch({
    type: ActionTypes.SET_EVENT_DATA,
    payload: {
      isOnline: event.isOnline,
      days: event.days,
      ticketTypes: event.ticketTypes,
      isPublished: event.isPublished,
      finances: event.finances ? event.finances.id : null,
      fullFinance: event?.finances ?? null,
      loading: false,
      id: event.id,
      timezoneField,
      slug: event.slug,
      slugState: event.slug,
      googleAnalytics: event.googleAnalytics,
      pixelScript: event.pixelScript,
      image: event.image,
      /* Use without timezone */
      begins: DateTime.fromISO(event.begins, timezoneField ? {zone: timezoneField} : {setZone: true}).setZone('local', {keepLocalTime: true}),
      ends: DateTime.fromISO(event.ends, timezoneField ? {zone: timezoneField} : {setZone: true}).setZone('local', {keepLocalTime: true}),
      name: event.name,
      locationName: event.locationName,
      locationDescription: event.locationDescription,
      address: event.address,
      position: event.position ? event.position && event.position.coordinates : [0, 0],

      description: event.description,
      isDarkEnabled: event.isDarkEnabled,
      myRole: event.myRole,
      isOwner: event.isOwner,
      hasFinances: event.hasFinances,
      organizers: event.organizers.map((organizer) => organizer.id),
      currency: event.currency,
      nameEn: event.nameEn,
      nameRu: event.nameRu,
      nameUk: event.nameUk,
      nameHe: event.nameHe,
      namePl: event.namePl,
      nameLv: event.nameLv,
      nameLn: event.nameLn,
      descriptionEn: event.descriptionEn,
      descriptionRu: event.descriptionRu,
      descriptionUk: event.descriptionUk,
      descriptionHe: event.descriptionHe,
      descriptionPl: event.descriptionPl,
      descriptionLv: event.descriptionLv,
      descriptionLn: event.descriptionLn,

      locationNameEn: event.locationNameEn,
      locationNameRu: event.locationNameRu,
      locationNameUk: event.locationNameUk,
      locationNameHe: event.locationNameHe,
      locationNamePl: event.locationNamePl,
      locationNameLv: event.locationNameLv,
      locationNameLn: event.locationNameLn,
      locationDescriptionEn: event.locationDescriptionEn,
      locationDescriptionRu: event.locationDescriptionRu,
      locationDescriptionUk: event.locationDescriptionUk,
      locationDescriptionHe: event.locationDescriptionHe,
      locationDescriptionPl: event.locationDescriptionPl,
      locationDescriptionLv: event.locationDescriptionLv,
      locationDescriptionLn: event.locationDescriptionLn,
      savedIsMultilanguage: event.isMultilanguage,
      isMultilanguage: event.isMultilanguage,
      defaultLanguage: event.defaultLanguage.toLowerCase(),
      savedDefaultLanguage: event.defaultLanguage.toLowerCase(),
      availableLanguages,
      ticketTypeIdQuestions
    }
  })
  dispatch({type: SET_FINANCE_ID, financeId: event.finances ? event.finances.id : null})
}


export const updateEventInfo = (field, value, actionId, actionSlug) => async (dispatch, store) => {
  return new Promise(((resolve, reject) => {
    dispatch({type: ActionTypes.REQUSET_UPDATE})
    const state = store().EventEdit
    let {isPublished, id, slug} = state

    const requiredEventInfo = () => {
      if (field === 'finances' && value === null && isPublished) isPublished = false
      return {isPublished}
    }

    client.mutate({
      mutation: UPDATE_EVENT,
      variables: {
        event: {
          ...requiredEventInfo(),
          id: actionId || id,
          slug: actionSlug || slug,
          [field]: value,
        }
      }
    }).then(() => {
      dispatch(edit({field, value}))
      if (field === 'finances') {
        if (value === null && state.isPublished) {
          dispatch(edit({field: 'isPublished', value: false}))
        }
        dispatch(edit({field: 'hasFinances', value: !!value}))
      }
      dispatch(
        addNotification({
          type: 'success',
          content: <Trans i18nKey='event.edited'/>,
          autoClose: 5000,
        })
      )
      resolve()
    }).catch((error) => {
      dispatch(
        addNotification({
          type: 'error',
          content: <Trans i18nKey='notifications.error'/>,
          autoClose: 5000,
        })
      )
      reject(error)
    })
  }))
}

export const operationWithTicketTypeUpdate = (ticketId, field) => ({
  type: ActionTypes.OPERATION_WITH_TICKET_TYPE_UPDATE,
  ticketId,
  field
})

export const operationWithTicketTypeDelete = (ticketId) => {
  return {
    type: ActionTypes.OPERATION_WITH_TICKET_TYPE_DELETE,
    ticketId
  }
}

export const operationWithQuestionUpdate = (questionId, ticketTypeId) => ({
  type: ActionTypes.OPERATION_WITH_QUESTION_UPDATE,
  questionId,
  ticketTypeId
})

export const edit = (payload) => createAction(ActionTypes.EDIT, payload)

export const reset = () => createAction(ActionTypes.RESET)


export const resetDate = () => ({type: ActionTypes.RESET_DATE})

export const addTicketType = (newTicketForEventEdit) => ({type: ActionTypes.ADD_TICKET_TYPE, newTicketForEventEdit})

export const addTicketColor = (color) => ({type: ActionTypes.ADD_TICKET_COLOR, color})

export const changeTicketType = ({id, field, value}) => ({type: ActionTypes.CHANGE_TICKET_TYPE, id, field, value})

export const addOrganizer = (id) => ({type: ActionTypes.ADD_ORGANIZER, id})

export const addQuestion = (questionData, isEventEdit) => ({type: ActionTypes.ADD_QUESTION, questionData, isEventEdit})

export const removeTicketType = (id) => ({type: ActionTypes.REMOVE_TICKET_TYPE, id})

export const editQuestion = (questionId, questionData, isEventEdit) => ({
  type: ActionTypes.EDIT_QUESTION,
  questionId,
  questionData,
  isEventEdit
})

export const deleteQuestion = (questionId, oldQuestion = false, isEventEdit = false) => ({
  type: ActionTypes.DELETE_QUESTION, questionId, oldQuestion, isEventEdit
})

export const addUpdateField = (field) => ({type: ActionTypes.ADD_UPDATE_FIELDS, field})

export const addPriorityTicketInRequest = () => ({type: ActionTypes.OPERATION_WITH_TICKET_TYPE_PRIORITY})
export const addPriorityQuestion = () => ({type: ActionTypes.OPERATION_WITH_QUESTION_PRIORITY})

export const schemaGenerate = (from, to, ticketId) => ({type: ActionTypes.SCHEMA_GENERATE, from, to, ticketId})
export const schemaRemoveRow = (id) => ({type: ActionTypes.SCHEMA_REMOVE_ROW, id})
export const schemaAddRow = () => ({type: ActionTypes.SCHEMA_REMOVE_ROW})
export const schemaEditRow = ({ticketId, id, fieldName, value}) => {
  return {
    type: ActionTypes.SCHEMA_EDIT_ROW, ticketId, id, fieldName, value
  }
}
export const schemaCopyRow = (ticketId, id) => ({type: ActionTypes.SCHEMA_COPY_ROW, ticketId, id})

