import React, {memo, useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {
  editAttendee,
  editQuestionTypePromo,
  editAttendeeCoupon,
  removeAttendee,
  editAttendeeRow
} from 'store/Event/actions'
import QuestionField from './QuestionField'
import PropTypes from 'prop-types'
import s from '../../Event.module.scss'
import classNames from 'classnames/bind'
import {CloseIcon, CompleteIcon, WarningIcon} from '../../Icons/Icons'
import IconAngle from 'components/Icons/Angle'
import {compose} from "redux";
import {Divider, Checkbox, TextField} from "components/modules";
import {RowType} from "../../../../../store/EventEdit/types";
import {UnoccupiedRowType, useGetUnoccupiedTickets} from "../../../../../apollo/queries/useUnoccupiedTickets";


const cx = classNames.bind(s)

type AttendeeFormPropsType = {
  bookPlaces: any,
  attendees: any,
  isRows: boolean,
  editBillingAddress: (line: string, field: string, value: string) => void,
  editRow: ({field, value}: { field: 'seat' | 'row', value: any }) => void,
  edit: (questionId: string, value: string) => void,
  attendeeIndex: number,
  attendee: {
    coupon?: { value?: string },
    id: string,
    questionTypePromocode?: Array<any>,
    questionsOrder: {
      [key: string]: any
    },
    row?: number | string,
    seat?: number | string,
    rowError?: number | string,
    seatError?: number | string,
  },
  ticketTypeId: string,
  isWithPromo: boolean,
  editQuestionPromo: (value: any) => void,
  defaultLanguage: string,
  editCoupon: (value: any) => void,
  getDiscountSum: (id: any) => void,
  getTotalSum: () => void
  id: string,
  lng: string,
  isMultilanguage: boolean,
  rows: UnoccupiedRowType[]
  removeAttendee: (e: any) => void
}
const AttendeeForm = ({
                        bookPlaces,
                        attendee: {row, rowError, seatError, seat, ...attendee}, attendeeIndex, edit, editRow, isRows,
                        ticketTypeId, isWithPromo, rows, editBillingAddress,
                        editQuestionPromo, lng, attendees, ...props
                      }: AttendeeFormPropsType) => {
  debugger
  const [t] = useTranslation()

  const [show, setShow] = useState(true)
  const keysAttendee = Object.keys(attendee?.questionsOrder)
  const firstName = attendee?.questionsOrder[keysAttendee[0]]?.value ? attendee?.questionsOrder[keysAttendee[0]]?.value : ''
  const lastName = attendee?.questionsOrder[keysAttendee[1]]?.value ? attendee?.questionsOrder[keysAttendee[1]]?.value : ''
  const fieldsRequired = [];
  let questionsOrderKeys = Object.keys(attendee?.questionsOrder);
  questionsOrderKeys.forEach((key) => {
    if (attendee?.questionsOrder[key]?.isRequired) {
      fieldsRequired.push(key)
    }
  });
  const handleEditBilling = useCallback((attendeeIndex, priority, value) => {
    const availableAttendees = Object.keys(attendees).filter((ticketId) => attendees[ticketId].length)
    if (availableAttendees.length === 1 && attendeeIndex === 0) {
      let fieldName = priority === 1
        ? 'firstName'
        : priority === 2
          ? 'lastName'
          : priority === 3
            ? 'email'
            : null
      if (fieldName) editBillingAddress(fieldName, 'value', value)
    }

  }, [])
  const checkFieldsOnRequired = () => {
    let fieldsWithValue = []
    for (let i = 0; i < keysAttendee.length; i++) {
      if (attendee?.questionsOrder[keysAttendee[i]]?.value) {
        fieldsWithValue.push(attendee?.questionsOrder[keysAttendee[i]]?.id)
      }
    }
    return fieldsRequired.length === fieldsWithValue.length
  }
  const optionsRows = rows.map(r => r.row?.[0]?.rowNumber)
  const selectedRow = rows?.find(r => r.row?.[0]?.rowNumber === row)
  const bookedSeats = selectedRow ? bookPlaces[selectedRow?.row?.[0]?.rowNumber] : []

  return (
    <div className={cx('item')}>
      <span className={cx('item-number', {'active': show})}>{attendeeIndex + 1}</span>
      <div className={cx('item-wrap')}>
        <div className={cx('item-delete')} onClick={(e) => {
          setTimeout(() => props.getDiscountSum(props.id), 0)
          setTimeout(props.getTotalSum, 0)
          props.removeAttendee(e)
        }}>
          <CloseIcon fill={show ? 'var(--accent-color)' : '#8C8C8C'}/>
        </div>
        <div className={cx('item-header')} onClick={() => setShow(!show)}>
          <h5 className={cx('fz1628', 'fw600')}>
            {checkFieldsOnRequired() ? <CompleteIcon/> : <WarningIcon/>}
            <div className={cx('saved-ticket')}>
              <span>{t('event.ticket.ticket')} №{attendeeIndex + 1} </span>
              {firstName || lastName ? <p>{`(${firstName}  ${lastName})`}</p> :
                t('event.ticket.inputInfo')
              }
            </div>
          </h5>
          <div className={cx('header-arrow')}>
            <IconAngle width={6} height={12} color={show ? 'var(--accent-color)' : '#8C8C8C'}/>
          </div>
        </div>
        {show &&
          <div className={cx('item-content', {'showItem': show})}>
            <div className={cx('item-fields')}>
              {isRows && <div className={cx('SelectSeats')}>
                <QuestionField
                  classNameComponent={cx('Row')}
                  labelCustomName={'labelInput'}
                  inputWrapCustomClass={'defaultInput'}
                  name={t('Rows')}
                  edit={(value: string) => {
                    editRow && editRow({field: 'row', value})
                  }}
                  error={rowError}
                  value={row}
                  options={optionsRows}
                  questionType={'DROPDOWN'} isRequired/>
                <QuestionField
                  error={seatError}
                  disabledOption={Array.isArray(bookedSeats) ? bookedSeats : []}
                  classNameComponent={cx('Seat')}
                  options={selectedRow?.row?.[0]?.seats ?? []}
                  disabled={!row}
                  isOpen
                  labelCustomName={'labelInput'}
                  inputWrapCustomClass={'defaultInput'}
                  name={t('Seat')}
                  value={seat}
                  edit={(value: string) => editRow && editRow({field: 'seat', value})}
                  questionType={'DROPDOWN'} isRequired/>
              </div>}

              {Object.keys(attendee.questionsOrder)
                ?.sort((a, b) => attendee.questionsOrder[a]?.priority - attendee.questionsOrder[b]?.priority)
                ?.map(questionId => {
                  const question = attendee?.questionsOrder[questionId]
                  debugger
                  return (
                    <QuestionField
                      {...question}
                      lng={lng}
                      isMultilanguage={props.isMultilanguage}
                      defaultLanguage={props.defaultLanguage}
                      edit={(v: string) => {
                        edit && edit(questionId, v)
                        handleEditBilling(attendeeIndex, question.priority, v)
                      }}
                      labelCustomName={'labelInput'}
                      inputWrapCustomClass={'defaultInput'}
                      key={questionId}
                    />
                  )
                })}
            </div>
          </div>
        }
      </div>

    </div>
  )
}

AttendeeForm.propTypes = {
  ticketTypeId: PropTypes.string.isRequired,
  attendeeIndex: PropTypes.number.isRequired,
  attendee: PropTypes.object,
  questionTypePromocode: PropTypes.object
}


const mapDispatch = (dispatch: any, props: any) => ({
  edit(questionId: string, value: string) {
    const {attendeeIndex, ticketTypeId} = props
    dispatch(editAttendee({
      attendeeIndex,
      ticketTypeId,
      questionId,
      value,
    }))
  },
  editRow({field, value}: { field: 'seat' | 'row', value: any }) {
    const {attendeeIndex, ticketTypeId} = props
    dispatch(editAttendeeRow({
      attendeeIndex,
      ticketTypeId,
      field,
      value,
    }))
  },
  editQuestionPromo(value: any) {
    const {attendeeIndex, ticketTypeId} = props
    dispatch(editQuestionTypePromo({
      attendeeIndex,
      ticketTypeId,
      promoValue: value,
    }))
  },
  removeAttendee(e: any) {
    e?.preventDefault()
    const {attendeeIndex, ticketTypeId} = props
    dispatch(removeAttendee({attendeeIndex, ticketTypeId}))

  },
  editCoupon(value: string) {
    const {attendeeIndex, ticketTypeId} = props
    dispatch(editAttendeeCoupon({attendeeIndex, ticketTypeId, value}))
  },
})
export default compose(connect(null, mapDispatch),)(memo(AttendeeForm))

