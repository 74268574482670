export default {
  long: {
    ln: 'Литовский',
    ru: 'Русский',
    en: 'Английский',
    uk: 'Украинский',
    he: 'Иврит',
    pl: 'Польский',
    lv: 'Латвийский',
  },
  short: {
    ln: 'Лит',
    ru: 'Рус',
    en: 'Анг',
    uk: 'Укр',
    he: 'Ивр',
    pl: 'Пол',
    lv: 'Лат'
  },
  /**
   * remove
   */
  ln: 'Литовский',
  ru: 'Русский',
  en: 'Английский',
  uk: 'Украинский',
  he: 'Иврит',
  pl: 'Польский',
  lv: 'Латвийский'
}