import {ColorEnum} from 'components/Typography/index.props';
import React from 'react';

export const CloseIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.48528 6L0.242641 1.75736L1.65685 0.343146L5.89949 4.58579L10.1421 0.343146L11.5563 1.75736L7.31371 6L11.5563 10.2426L10.1421 11.6569L5.89949 7.41421L1.65685 11.6569L0.242641 10.2426L4.48528 6Z"
      fill="black"/>
  </svg>

)
export const CloseIconSimple = ({fill = ColorEnum.white, width = 20, height = 20, className = ''}) => (
  <svg className={className} height={height} viewBox="0 0 329.26933 329" width={width} fill={`var(${fill})`}
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/>
  </svg>


)