import error from './error'
import landing from './landing'
import sign from './sign'
import event from './event'
import user from './user'
import footer from './footer'
import languages from './languages'
import searchPage from './search_page'
import postersPage from './posters_page'

export default {
  sign,
  error,
  event,
  landing,
  user,
  languages,
  searchPage,
  postersPage,
  footer,
  'Billing address': 'Платіжна адреса',
  "email": "Email",
  "address": "Адреса",
  "city": "Місто",
  "country": "Країна",
  "firstName": "Імʼя",
  "lastName": "Прізвище",
  "zip": "Пост код",
  "Max length": "Максимальна довжина: {{chars}} символів",

  'Buy a ticket only if you have a promotional code': 'Купувати квиток тільки за наявністю промокоду',

  'Pay as a legal entity (please enter your billing information)': 'Платіть як юридична особа (введіть свою платіжну інформацію)',
  'Pay as a legal entity': 'Платити як юридична особа',
  'Download invoice': 'Завантажити рахунок-фактуру',
  'Paid': 'Оплачено',
  'Sum': 'Сума',
  'Date created': 'Дата створення',
  'Company name': 'Назва компанії',
  'Number of tickets': 'Кількість квитків',
  'Resend invoice': 'Повторно надіслати рахунок',
  'Mark as paid': 'Позначити як оплачений',
  'Mark as not paid': 'Позначити як неоплачений',
  goFuck: 'Російський корабель, іди нах*й!',
  helpArmy: 'Допомогти українській армії',
  showBy: 'Показати по',
  show: 'Показати',

  copy: 'Скопіювати',
  copied: 'Скопійовано',
  aliasDescr1: 'Ви можете вказати унікальний alias і давати посилання людям, в facebook і т.д. у вигляді:',
  aliasDescr2: 'Це зручно, так як люди будуть бачити тільки ваші події.',
  learnMore: 'Дізнатися більше',
  subscribeForm: {
    header: 'Отримуйте листи від satchel.events, щоб завжди бути в курсі подій!',
    success: 'Дякуємо за підписку 😁'
  },
  ticketActivation: {
    title: 'Активація квитків',
    ticketCreated: 'Квиток оформлено',
    order: 'Замовлення'
  },
  toHome: 'На головну',
  cancel: 'Відмінити',
  notifications: {
    smsSent: 'SMS успішно відправлено.',
    saved: 'Збережено',
    error: 'Сталася помилка!',
  },
  verify: {
    sendEmailAgain: 'Надіслати листа ще раз',
    verificatePhone: 'Підтвердити номер телефону',
    emailVerificated: 'Вітаємо, email підтверджено!',
    emailSended: 'Лист відправлений',
    email: 'Підтвердіть ваш email.',
    phone: 'Підтвердіть ваш номер телефону'
  },
  changeLang: 'Змінити мову',
  terms: {
    title: 'Правила користування',
    tabs: {
      organizer: 'Для організаторів',
      customer: 'Для покупців квитків'
    }
  },
  policy: {
    title: 'Політика конфіденційності'
  },
  cookie: {
    text: 'При використанні satchel.events, ви погоджуєтесь з нашою політикою відносно файлів cookie. <0>Дізнатись більше.</0>',
    accept: 'Прийняти',
  },
  send: 'Відправити',
  remove: 'Видалити',
  download: 'Завантажити',
  weekDays: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'нд']
}
