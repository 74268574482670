import 'scss/index.scss'
import React, {memo, Suspense, useEffect} from 'react'
import {Route, Router, Switch} from 'react-router-dom'
import {connect, useSelector} from 'react-redux'
import ModalPhoneVerification from 'containers/ModalPhoneVerification'
import {requestProfile} from 'store/Profile/actions'
import ModalSign from 'containers/ModalSign/ModalSign'
import {STATIC_PATHNAMES} from 'constants'
import {NotFoundIllustation} from "../Pages/Event/Icons/Icons";
import $ from 'helpers/dom'
import {usePageView} from "helpers/hooks";
import {
  AgreementCookie, EmailVerification, ResetPassword, SignUp, EventEdit,
  Event, Landing, Profile, TicketActivation, Search, Dashboard, NotFound,
  Posters, OwnPosters, Static, Terms, Reservations, SmsConfirmation
} from "./modules";
import {GoFuck, CheckIE, Notifications, Preloader, Wrapper} from "components/modules";


function App(props) {
  usePageView()
  const isShowDonateUa = useSelector(state => state.Base.isShowDonateUa)
  useEffect(() => {
    props.requestProfile()
    document.body.appendChild($.create('script', 'https://api.fondy.eu/static_common/v1/checkout/ipsp.js'));
  }, [])

  const {pathname} = props.history.location
  return (
    <Router history={props.history}>
      <div className="app">
        {isShowDonateUa && <GoFuck/>}
        <ModalPhoneVerification/>
        <ModalSign/>
        <AgreementCookie/>
        <CheckIE/>
        <Notifications/>
        <Suspense fallback={<Preloader/>}>
          <Switch>
            <Route path='/' exact
                   component={Posters}/>
            <Route path='/dashboard'
                   component={Wrapper({Component: Dashboard, needToken: true, pathname: pathname})}/>
            <Route path='/reservations'
                   component={Wrapper({Component: Reservations, needToken: true, pathname: pathname})}/>
            <Route path='/all-events-from/:alias' component={OwnPosters}/>
            <Route path='/search' component={Search}/>
            <Route path='/organizers' component={Landing}/>
            <Route path='/ticket-activation/:code' component={TicketActivation}/>
            <Route path='/signup/:token' exact component={Wrapper({Component: SignUp, needToken: false})}/>
            <Route path='/profile' component={Wrapper({Component: Profile, needToken: true})}/>
            <Route path='/event-edit/:slug' component={Wrapper({Component: EventEdit, needToken: true})}/>
            <Route path='/event/:slug' exact component={Event}/>
            <Route path='/event' component={Event}/>
            <Route path='/create-event' component={Wrapper({Component: EventEdit, needToken: true})}/>
            <Route path='/sms-confirmation/:slug/:attendeeId' component={SmsConfirmation}/>
            <Route path='/reset-password/:id/:code'
                   component={Wrapper({Component: ResetPassword, needToken: false})}/>

            <Route path='/verify-email/:id/:code' component={EmailVerification}/>
            {STATIC_PATHNAMES.map(path => <Route key={path} path={path}
                                                 component={path === '/terms' ? Terms : Static}/>)}
            <Route path="*"><NotFound><NotFoundIllustation/></NotFound></Route>
          </Switch>
        </Suspense>
      </div>
    </Router>
  )
}

export default connect(null, {requestProfile})(memo(App))
