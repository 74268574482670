import React, {Component} from 'react'
import {Trans, withTranslation} from 'react-i18next';
import FieldError from 'components/UI/FieldError'
import {FORGOT_PASSWORD} from 'apollo/mutations'
import {compose} from 'redux'
import client from '../../apollo'
import Loader from 'components/Loader'
import s from './ModalSign.module.scss';
import classNames from 'classnames/bind';
import {Link} from "react-router-dom";

const cx = classNames.bind(s)

class ForgotPassword extends Component {
  state = {
    email: {
      value: ''
    },
    showSuccess: false,
    loading: false
  }

  forgotPassword = (e) => {
    e.preventDefault()
    this.setState({loading: true})
    client.mutate({
      mutation: FORGOT_PASSWORD,
      variables: {
        email: this.state.email.value
      }
    })
      .then(() => {
        this.setState({
          showSuccess: true
        })
      })
      .catch(e => {
        const error = e.graphQLErrors[0]
        if (error && error.message) {
          this.setState({
            loading: false,
            email: {
              ...this.state.email,
              error: 'Enter a valid email address'
            }
          })
        }
      })
  }

  render() {
    const email = this.state.email
    return <form onSubmit={this.forgotPassword} className='passwordForm'>
      <h3>
        <Trans i18nKey='sign.forgot'/>
      </h3>
      {this.state.showSuccess ?
        <p><Trans i18nKey='sign.forgotSuccess'/></p>
        :
        <div className={cx('maxWidth500', 'mt30', 'm_auto')}>
          <div className={cx('simpleInput')}>
            <label>
              <Trans i18nKey='sign.email'/>
              <input
                required
                className={email.error && 'border-danger'}
                value={email.value}
                onChange={e => this.setState({email: {value: e.target.value}})}
                type="email"/>
              {email.error && <FieldError error={email.error}/>}
            </label>
            <button className={cx('button-orange')} disabled={this.state.loading}>
              {this.state.loading ? <Loader/> : <Trans i18nKey='send'/>}
            </button>
          </div>
          {/*<p className={cx('needHelp')}>*/}
          {/*  <a href={'mailto:help@teeko.io'}> <Trans i18nKey={'sign.needHelp'}/></a>*/}
          {/*</p>*/}
        </div>
      }
    </form>
  }
}

export default compose(
  withTranslation()
)(ForgotPassword)
