/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CurrencyEnum {
  EUR = "EUR",
  PLN = "PLN",
  UAH = "UAH",
  USD = "USD",
}

/**
 * An enumeration.
 */
export enum EventCurrency {
  EUR = "EUR",
  PLN = "PLN",
  UAH = "UAH",
  USD = "USD",
}

/**
 * An enumeration.
 */
export enum EventDefaultLanguage {
  EN = "EN",
  HE = "HE",
  LV = "LV",
  PL = "PL",
  RU = "RU",
  UK = "UK",
}

export enum LanguageEnum {
  EN = "EN",
  HE = "HE",
  LV = "LV",
  PL = "PL",
  RU = "RU",
  UK = "UK",
  LN = 'LN'
}

export enum PosterSortingEnum {
  NEW = "NEW",
  PRICE = "PRICE",
  PRICE_DESC = "PRICE_DESC",
  START = "START",
  START_DESC = "START_DESC",
}

export enum QuestionTypeEnum {
  DROPDOWN = "DROPDOWN",
  EMAIL = "EMAIL",
  INPUT = "INPUT",
  PHONE_NUMBER = "PHONE_NUMBER",
  SELECT = "SELECT",
  TEXTAREA = "TEXTAREA",
  URL = "URL",
}

export interface AnswerInput {
  questionId: number;
  answer: string;
}

export interface EventInput {
  id?: string | null;
  name?: string | null;
  slug?: string | null;
  description?: string | null;
  isHidden?: boolean | null;
  isPublished?: boolean | null;
  isWithSms?: boolean | null;
  location?: string | null;
  organizer?: string | null;
  finances?: number | null;
  currency?: CurrencyEnum | null;
  timezoneField: string;
  begins?: any | null;
  ends?: any | null;
  quantity?: number | null;
  googleAnalytics?: string | null;
  isChgk?: boolean | null;
  locationName?: string | null;
  locationDescription?: string | null;
  address?: string | null;
  position?: any | null;
  isDarkEnabled?: boolean | null;
  isNameShown?: boolean | null;
  defaultLanguage?: LanguageEnum | null;
  isMultilanguage: boolean;
  isOnline: boolean;
  availableLanguages?: (LanguageEnum | null)[] | null;
  translations?: EventTranslationsInput | null;
}

export interface EventTranslationsInput {
  nameUk?: string | null;
  nameRu?: string | null;
  nameEn?: string | null;
  namePl?: string | null;
  nameLv?: string | null;
  descriptionUk?: string | null;
  descriptionRu?: string | null;
  descriptionEn?: string | null;
  descriptionPl?: string | null;
  descriptionLv?: string | null;
  locationNameUk?: string | null;
  locationNameRu?: string | null;
  locationNameEn?: string | null;
  locationNamePl?: string | null;
  locationNameLv?: string | null;
  locationDescriptionUk?: string | null;
  locationDescriptionRu?: string | null;
  locationDescriptionEn?: string | null;
  locationDescriptionPl?: string | null;
  locationDescriptionLv?: string | null;
}

export interface PosterFilterInput {
  dateFrom?: any | null;
  dateTo?: any | null;
  today?: boolean | null;
  tomorrow?: boolean | null;
  week?: boolean | null;
  name?: string | null;
  freeTickets?: boolean | null;
  priceFrom?: number | null;
  priceTo?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
