import {LogoCard, MoreIcon, SettIcon, UserIcon} from '../Event/Icons/Icons'
import React, {useCallback, useState} from 'react'
import classNames from 'classnames/bind'
import s from './Dashboard.module.scss'
import {Trans, withTranslation} from 'react-i18next'
import {DateTime} from 'luxon'
import {useMutation, gql} from '@apollo/client'
import {ACCESS_ROLES} from 'constants'
import {Link} from 'react-router-dom'
import SettingEvent from "./SettingEvent";
import formatLanguage from "../../../helpers/formatLanguage";
import ModalAgree from "components/ModalAgree/ModalAgree";
import {DELETE_EVENT} from "../../../apollo/mutations";
import {addNotification as addNotificationAction} from "store/Notifications/actions";
import {useDispatch} from "react-redux";
import {compose} from "redux";

const cx = classNames.bind(s)

const DUPLICATE_EVENT = gql`
    mutation ($copyEventId: Int!) {
        copyEvent(copyEventId: $copyEventId) {
            ok
        }
    }
`
const ImgContainer = ({myRole, stylesBack, slug, children}) => {
  if (myRole === ACCESS_ROLES.VOLUNTEER) {
    return <div style={stylesBack} className={cx('event-img')}>{children}</div>
  } else {
    return <Link to={`/event-edit/${slug}`} style={stylesBack} className={cx('event-img')}>{children}</Link>
  }
}
const EventCart = (props) => {
  const {
    defaultLanguage, isMultilanguage,
    description, refetch,
    slug, name, image, currency, isMobile, isPublished, id,
    locationName, begins: b, totalTickets, totalIncomeWithFee,
    timezoneField, myRole, isOwner, address,
    isOnline,
    isUnlimited, user, organizers, ...eventData
  } = props

  const [duplicateMutation] = useMutation(DUPLICATE_EVENT)
  const [deleteMutation] = useMutation(DELETE_EVENT)


  const [loadingDuplicate, setLoadingDuplicate] = useState(false)
  const [show, setShow] = useState(false)
  const [showAgree, setShowAgree] = useState(false)
  const [idDelete, setIdDelete] = useState(null)

  const formattedCurrentLng = formatLanguage(props.i18n.language)
  const formattedDefaultLng = defaultLanguage ? formatLanguage(defaultLanguage) : ''
  let translatedDescription = !isMultilanguage ? description : (
    (eventData)[`description${formattedDefaultLng}`] || (eventData)[`description${formattedCurrentLng}`]
  )
  const begins = DateTime.fromISO(b, timezoneField ? {zone: timezoneField} : {setZone: true})
  if (translatedDescription && translatedDescription.length > 50) {
    translatedDescription = translatedDescription.substr(0, 50) + '...'
  }
  const stylesBack = {
    backgroundImage: `url(${image})`,
  }
  // const currencyFormat = useCallback((currency, totalIncomeWithFee) => {
  //   switch (currency) {
  //     case 'UAH':
  //       return String(totalIncomeWithFee).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') + " " + currency;
  //       break;
  //     case 'RUB':
  //       return String(totalIncomeWithFee).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') + " " + currency;
  //     case 'EUR':
  //       return '€' + String(totalIncomeWithFee).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  //       break
  //     case 'USD':
  //       return '$' + String(totalIncomeWithFee).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  //       break
  //     default:
  //       return totalIncomeWithFee + " " + currency
  //   }
  // }, []);
  const duplicateEvent = () => {
    setLoadingDuplicate(true)
    duplicateMutation({
      variables: {
        copyEventId: id,
      },
    }).then(() => refetch()).catch((error) => console.error(error))
      .finally(() => setLoadingDuplicate(false))
  }
  const dispatch = useDispatch()
  const addNotification = useCallback((alert) => dispatch(addNotificationAction(alert)), [dispatch])

  const showNotification = (type, content, time) => {
    addNotification({
      type,
      content,
      autoClose: time
    })
  }
  const deleteEvent = async (id) => {
    try {
      const result = await deleteMutation({variables: {eventId: +id}});
      if (result.data.deleteEvent && result.data.deleteEvent.ok) {
        showNotification('success', <Trans i18nKey={'event.errorMessage.eventDelete'}/>, 3000)
        refetch()
      } else if (result.data.deleteEvent && !result.data.deleteEvent.ok) {
        showNotification('error', <Trans i18nKey={'event.errorMessage.eventDontDelete'}/>, 3000)
        refetch()
      } else {
        showNotification('error', 'User is not owner', 3000)
        refetch()
      }
    } catch (e) {
      const error = e.graphQLErrors && e.graphQLErrors[0]
      if (error && error.message) {
        if (error.message.includes("Cannot delete some instances of model \'Event\' because they")) {
          showNotification(
            'error',
            <Trans i18nKey={'event.errorMessage.ticketsAreBought'}/>,
            3000)

        } else {
          showNotification('error', 'sorry, some error', 3000)
        }
      }
      refetch()
    }

  }
  return (
    <>
      <article className={cx('event-list')}>
        <div className={cx('show-more')} onClick={() => setShow(!show)}>
				<span>
					<MoreIcon/>
				</span>
        </div>
        <ImgContainer myRole={myRole} slug={slug} stylesBack={stylesBack}>
          {!image && <div className={cx('wrap-loader')}><LogoCard/></div>}
          {myRole && (
            <div className={cx('role')}>
              <Trans i18nKey={`event.access.roleType.${myRole}`}/>
            </div>
          )}
          <div className={cx('overlay', {VOLUNTEER: myRole === ACCESS_ROLES.VOLUNTEER})}>
            <SettIcon fill={'#fff'}/>
          </div>
        </ImgContainer>

        <div className={cx('event-description')}>

          <div className={cx('event-top')}>
            <div>
						<span className={cx('date', 'fz1830')}>
              {begins.toFormat('HH:mm dd.MM')} {" "}
              {isOnline ? 'Online' : locationName || address}</span>
              <Link to={`/event-edit/${slug}`} className={cx('c57', 'fz2530', 'text-left', 'name-event')}>{name ?? ''}</Link>

              <p dangerouslySetInnerHTML={{__html: translatedDescription}} className={cx('fz1830')}></p>
            </div>
          </div>
          <div className={cx('event-bottom', {'without-organizers': organizers.length === 0})}>
            <div>
              <div className={cx('users')}>
                {organizers && organizers
                  .map((el, index) => {
                    return index < 3 && <div key={el.id} className={cx('user')}>
											<span className={cx('avatar')}
                            style={{left: index ? index * 10 + 'px' : 0, backgroundImage: `url(${el.logo || ''})`}}>
                        {!el.logo && <UserIcon fill={'#fff'}/>}
											</span>
                    </div>
                  })}


              </div>
              <p className={cx('contact', 'fz1628')}>
                <span><Trans i18nKey={'event.eventCart.contactPerson'}/>: <small>{organizers.length}</small></span>
              </p>
            </div>
            {(myRole !== ACCESS_ROLES.OPERATOR && myRole !== ACCESS_ROLES.VOLUNTEER) && <div className={cx('fz1628')}>
              <p><Trans i18nKey="event.ticketsSold"/>: <span
                className={cx('accent')}>{totalTickets}</span>
              </p>
              <p><Trans i18nKey="event.totalIncome"/>: <span
                className={cx('accent')}>{totalIncomeWithFee} EUR</span></p>
            </div>}
          </div>
          <SettingEvent setShow={setShow} show={show} myRole={myRole} slug={slug} isOwner={isOwner} id={id}
                        loadingDublicate={loadingDuplicate} duplicateEvent={duplicateEvent}
                        setShowAgree={setShowAgree}
                        setIdDelete={setIdDelete}

          />
        </div>
      </article>
      <ModalAgree
        onClick={async () => {
          await deleteEvent(idDelete)
          setShowAgree(false)
        }}
        isOpen={showAgree}
        label={<Trans i18nKey={'event.delete'}/>}
        close={() => {
          setIdDelete(null)
          setShowAgree(false)
        }}
      />
    </>
  )
}


export default compose(withTranslation())(EventCart)

