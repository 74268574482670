export interface TypographyPropsType {
  variant?: TypographyVariant,
  color?: string | ColorEnum,
  weight?: FontWeight
}

export enum ColorEnum {
  accent = 'var(--accent-color)',
  white = 'white',
  red = 'red',
  text = '#333',
  black = '#212121',
  green = '#4ED51E'
}

export enum FontWeight {
  Normal = 400,
  Medium = 500,
  Bold = 600,
}

export const variantsMapping = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  subheading1: "h6",
  subheading2: "h6",
  body: "p",
  label: "label",
  small: "p"


} as any;

export enum TypographyVariant {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  h6 = "h6",
  label = "label",
  subheading1 = "h6",
  subheading2 = "h6",
  body = "body",
  small = "small"

}

