import {createAction} from 'helpers/createAction'
import ActionTypes from './actionTypes'

export const reset = () => createAction(ActionTypes.RESET)

/**
 * Call after successful creating order (`onCreateOrder` mutation)
 */
export const onCreateOrder = ({checkoutUrl, order}) => createAction(ActionTypes.ON_CREATE_ORDER, {checkoutUrl, order})
/** ??? */
export const editOrderInfo = ({field, value}) => createAction(ActionTypes.EDIT_ORDER_INFO, {field, value})

export const setAttendees = (payload) => createAction(ActionTypes.SET_ATTENDEES, payload)

/** ??? */
export const setOrderId = (orderId) => createAction(ActionTypes.SET_ORDER_ID, orderId)

export const setTickets = (payload = {}) => createAction(ActionTypes.SET_TICKET_TYPES, payload)

export const changeEventLang = (lng) => createAction(ActionTypes.CHANGE_EVENT_LANG, lng)

export const editTicket = ({id, value}) => createAction(ActionTypes.EDIT_TICKET_TYPE, {id, value})

export const removeAttendee = ({ticketTypeId, attendeeIndex}) =>
  createAction(ActionTypes.REMOVE_ATTENDEE, {ticketTypeId, attendeeIndex})

export const editAttendee = ({ticketTypeId, attendeeIndex, questionId, value, field = 'value'}) =>
  createAction(ActionTypes.EDIT_ATTENDEE, {ticketTypeId, attendeeIndex, questionId, value, field})
export const editAttendeeRow = ({ticketTypeId, attendeeIndex, value, field = 'value'}) =>
  createAction(ActionTypes.EDIT_ATTENDEE_ROW, {ticketTypeId, attendeeIndex, value, field})
export const editQuestionTypePromo = ({ticketTypeId, attendeeIndex, promoValue = null}) =>
  createAction(ActionTypes.EDIT_QUESTION_PROMOCODE, {ticketTypeId, attendeeIndex, promoValue})

export const editAttendeeCoupon = ({ticketTypeId, attendeeIndex, value, field = 'value'}) =>
  createAction(ActionTypes.EDIT_ATTENDEE_COUPON, {ticketTypeId, attendeeIndex, value, field})

