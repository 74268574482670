import React, {FC} from 'react';
import s from './index.module.scss'
import classNames from 'classnames/bind';

const cx = classNames.bind(s)
export type Maybe<T> = T | null

type AvatarPropsType = {
  avatar?: Maybe<string>,
  firstName?: Maybe<string>,
  className?: string,
  classNameComponent?: string,
  classNameLabel?: string,
  label?: string
}
export const Avatar: FC<AvatarPropsType> =
  ({
     avatar,
     firstName,
     className,
     classNameComponent,
     classNameLabel,
     label,

   }) => {
    return (
      <div className={cx('Component', classNameComponent || '')}>
        <div className={cx('Avatar', className || '')}>
          {avatar
            ? <img src={avatar ?? ''} alt=""/>
            : firstName ? firstName?.charAt(0) : '?'}
        </div>
        {label && (
          <p className={cx('Label', classNameLabel || '')}
          >{label}</p>
        )}
      </div>
    );
  };

