import error from "./error";
import landing from "./landing";
import sign from "./sign";
import event from "./event";
import user from "./user";
import footer from "./footer";
import languages from "./languages";
import searchPage from "./search_page";
import postersPage from "./posters_page";
import {AttendeeStatusConfirmationEnum} from "../../apollo/hooks/useAttendeeTickets";

export default {
  sign,
  error,
  event,
  landing,
  user,
  languages,
  searchPage,
  postersPage,
  footer,
  'Send an invoice by mail': 'Send an invoice by mail',

  'Billing address': 'Billing address',
  "email": "Email",
  "An error occurred while paying for the event": 'An error occurred while paying for the event.',
  "Please try again later": "Please try again later.",
  "address": "Address",
  "city": "City",
  "country": "Country",
  "firstName": "First name",
  "lastName": "Last name",
  "zip": "Postal code",
  "Max length": "Max length: {{chars}} chars",
  "Image to download": "Image to download",
  "File to download": "File to download",
  "Buy a ticket only if you have a promotional code":
    "Buy a ticket only if you have a promotional code",

  [AttendeeStatusConfirmationEnum.CONFIRMED]: "Confirmed",
  [AttendeeStatusConfirmationEnum.NO_CONFIRM]: "No confirmed",
  "Company name": "Company name",
  "Pay as a legal entity (please enter your billing information)":
    "Pay as a legal entity (please enter your billing information)",
  "Pay as a legal entity": "Pay as a legal entity",
  "Download invoice": "Download invoice",
  Paid: "Paid",
  "Not Paid": "Not paid",
  Sum: "Sum",
  "Date created": "Date created",
  "Number of tickets": "Number of tickets",
  Status: "Status",
  "Resend invoice": "Resend invoice",
  "Mark as paid": "Mark as paid",
  "Mark as not paid": "Mark as not paid",

  goFuck: "Russian warship, go fuck yourself",
  helpArmy: "Help Ukrainian Army",
  showBy: "Show by",
  show: "Show",
  learnMore: "Learn more",
  copy: "Copy",
  copied: "Copied",
  aliasDescr1:
    "You can specify a unique alias and give links to people, on facebook, etc. as:",
  aliasDescr2: "This is convenient since people will only see your events.",
  subscribeForm: {
    header:
      "Receive emails from satchel.events to keep up to date with updates!",
    success: "Thank you for subscribing 😁",
  },
  ticketActivation: {
    title: "Ticket Activation",
    ticketCreated: "Ticket issued",
    order: "order",
  },
  toHome: "Home",
  cancel: "Cancel",
  notifications: {
    smsSent: "SMS sent successfully.",
    saved: "Saved",
    error: "An error has occurred!",
  },
  verify: {
    sendEmailAgain: "Send email again",
    verificatePhone: "Verify phone number",
    emailVerificated: "Congratulations, email confirmed!",
    emailSended: "Email sent",
    email: "Confirm your email.",
    phone: "Confirm your phone number",
  },
  changeLang: "Change language",
  terms: {
    title: "Terms of Service",
    tabs: {
      organizer: "For organizers",
      customer: "For buyers",
    },
  },
  policy: {
    title: "Privacy Policy",
  },
  cookie: {
    text:
      "Using satchel.events, you agree to our cookie policy. <0> Learn more. </ 0> ",
    accept: "Accept",
  },
  send: "send",
  remove: "Remove",
  download: "Download",
  weekDays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
};
