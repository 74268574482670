import React, {PureComponent} from 'react'

import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

import {DateTime} from 'luxon'

import {Trans, withTranslation} from 'react-i18next'
import TicketsForm from './TicketsForm'

import {EVENT_PAGE} from 'apollo/queries'
import {compose} from 'redux'
import {
  changeEventLang,
  editAttendee,
  editAttendeeCoupon,
  editAttendeeRow,
  editOrderInfo,
  onCreateOrder,
  reset,
  setAttendees,
  setTickets,
} from 'store/Event/actions'
import {connect} from 'react-redux'
import './index.scss'

import Footer from 'components/Footer'
import formatLanguage from 'helpers/formatLanguage'
import Banner from './Banner/Banner'
import s from './Event.module.scss'
import classNames from 'classnames/bind'
import EventDetails from './EventDetails/'
import EventOrganizers from './EventOrganizers/'
import update from 'immutability-helper'
import getQueryParam from 'helpers/getQueryParam'
import {CREATE_ORDER} from 'apollo/mutations'
import FieldError from 'components/UI/FieldError'
import Preloader from 'components/Preloader'
import GET_GROUP_DISCOUNT_SUM from 'apollo/mutations/GET_GROUP_DISCOUNT_SUM'
import {Link} from 'react-router-dom'
import Button from 'components/common/Button/Button'
import {setSubscribe} from "store/Event/reducer";
import Checkbox from "components/Checkbox";
import NotFound from "../NotFound/NotFound";
import animatedScroll from "helpers/animatedScroll";
import ModalWrapper from "components/ModalWrapper";
import {CompleteIcon} from "./Icons/Icons";
import Loader from "components/Loader";
import PreviewBlock from "./PriviewBlock/PreviewBlock";
import client from 'apollo/'
import QuestionField from "./RegistrationForm/AttendeeForm/QuestionField";
import {addNotification} from "store/Notifications/actions";
import {isSatchel} from "constants/";
import Header from "components/Header";
import {BillingForm} from "./BillingForm";
import {regexEmail} from "../../../helpers/regex";
import {CloseIconSimple} from "../../../assets/img/icons/close";

const cx = classNames.bind(s)

const defaultBillingData = {
  firstName: {value: '', error: null},
  lastName: {value: '', error: null},
  email: {value: '', error: null},
  address: {value: '', error: null},
  country: {value: '', error: null},
  city: {value: '', error: null},
  zip: {value: '', error: null},
}

class EventPage extends PureComponent {
  pixelWrapperId = `pixel-code-${Math.random()}`;
  myRef = React.createRef();
  myRefTicketBlock = React.createRef()
  state = {
    payAsCompany: false,
    companyName: {value: null, error: null},
    companyEmail: {value: null, error: null},
    hideItem: false,
    isAgreeCheckError: false,
    isAgreeCheck: false,
    showBottom: false,
    isSubscribed: true,
    event: null,
    apiError: false,
    apiEmpty: false,
    canPublish: false,
    isTeam: false,
    loading: true,
    createOrderLoading: false,
    loadingPrice: false,
    price: {
      totalSum: null,
      saving: null,
    },
    billingAddress: defaultBillingData,
    isPopupShow: false,
    popupType: 'success',
    languageWithUrl: '',
    googleAnalyticState: '',
    keysTicketsForPriority: []
  }

  setHideItem = (flag) => {
    this.setState({hideItem: flag})
  }
  fetchData = () => {
    const {setEventLang, match} = this.props
    match.params.slug && client.query({
      query: EVENT_PAGE,
      variables: {
        slug: match.params.slug,
      },
      fetchPolicy: "network-only"
    })
      .then(resp => {
        const eventData = resp.data.eventPage
        if (!eventData) {
          this.setState({apiEmpty: true})
          return;
        }

        const {isTeam, canPublish, event: {ticketTypesOrder: ticketTypesFrozen, ...event}} = eventData
        // this.props.i18n.changeLanguage(event.defaultLanguage.toLowerCase())
        const lng = this.props?.i18n?.language?.toLowerCase()
        setEventLang(event?.availableLanguages?.includes(lng) ? lng : event?.defaultLanguage?.toLowerCase())

        const now = +new Date()
        const ticketTypes = ticketTypesFrozen ? JSON.parse(JSON.stringify(ticketTypesFrozen)) : [];
        // Filter all ticketTypes, remove ticketTypes, that sales didn`t started
        const filteredTicketTypes = ticketTypes && ticketTypes.length > 0 &&
          ticketTypes.map(ticket => {
            ticket.id = ticket.oldId
            ticket.questionsOrder = ticket.questionsOrder && ticket.questionsOrder.map((q) => ({
              ...q,
              id: q.oldId
            }))
            if (now < +DateTime.fromISO(ticket.begins, event.timezoneField ? {zone: event.timezoneField} : {setZone: true})) return null
            if (now > +DateTime.fromISO(ticket.ends, event.timezoneField ? {zone: event.timezoneField} : {setZone: true})) {
              return {...ticket, isExpired: true, selected: 0}
            }
            return {...ticket, selected: 0}
          }).filter(t => !!t)
        // set selected 1 to first available ticketType
        // if only 1 ticketType -- display form
        const firstTicketType = filteredTicketTypes[0] || {}
        if (
          filteredTicketTypes.length === 1
          && !firstTicketType.isExpired
          && (firstTicketType.availableQuantity > 0 ||
            (firstTicketType.availableQuantity === null && firstTicketType.quantity === null))) {
          filteredTicketTypes[0].selected = 1

        }

        /**
         * Set ticketTypess from array to object
         * Key of each ticket is ticket id
         */

        const ticketTypesObject = {}
        filteredTicketTypes && filteredTicketTypes.length > 0 &&
        filteredTicketTypes.forEach((ticketType, index) => {
          ticketTypesObject[ticketType.id] = ticketType
        })
        let keysTicketsForPriority = []
        filteredTicketTypes && filteredTicketTypes.length > 0 &&
        filteredTicketTypes.forEach((ticketType, index) => {
          keysTicketsForPriority.push(ticketType.id)
        })
        this.setState({keysTicketsForPriority})
        /**
         * If organizer add GA -- use it
         */
        if (event.googleAnalytics) {
          ReactGA.initialize(event.googleAnalytics)
          ReactGA.pageview(window.location.pathname + window.location.search)
          this.setState({googleAnalyticState: event.googleAnalytics})
        }
        if (event.pixelScript) {
          // const wrapper = document.createElement('div')
          // wrapper.id = this.pixelWrapperId
          // wrapper.innerHTML = event.pixelScript
          // document.body.appendChild(wrapper)
          ReactPixel.init(event.pixelScript, {}, {autoConfig: true, debug: false});
          ReactPixel.pageView();
        }
        this.setState({
          event, isTeam, canPublish,
          loading: false,
        })
        this.props.setTickets(ticketTypesObject)
        this.props.setAttendees()
        this.getTotalSum()
      })
      .catch(error => {
        this.setState({apiError: true})
        console.error('Error! Fetching data from EVENT_PAGE query', error.message)
      })
      .finally(() => {
        this.setState({loading: false})
        const search = this.props.location.search
        if (search.includes('?success=')) {
          ReactGA.ga('send', 'pageview', '/mypage');
          this.setState({
            isPopupShow: true,
            popupType: search.includes('?success=false') ? 'error' : 'success',

          })
          if (this.props.location.search.indexOf('lang') + 1) {
            const urlParams = new URLSearchParams(this.props.location.search)
            const language = urlParams.get('lang')
            language && (language.length === 2) && this.setState({languageWithUrl: language.charAt(0).toUpperCase() + language.slice(1)})
            language && (language.length === 2) && this.props.i18n.changeLanguage(language.toLowerCase())

          }
        }
      })
  }
  componentDidMount = () => {
    if (/#buy/.test(window.location.href)) {
      setTimeout(() => {
        this.myRefTicketBlock.current && this.myRefTicketBlock.current.scrollIntoView()
      }, 1000)
    }
    window.addEventListener('scroll', this.handleScroll)
    this.fetchData()
    let referrer = localStorage.getItem('referrer')
    document.referrer
    && referrer !== document.referrer
    && localStorage.setItem('referrer', document.referrer)

  }
  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll);
    this.props.reset()
    // Remove pixel code if it was created
    if (document.getElementById(this.pixelWrapperId)) {
      document.getElementById(this.pixelWrapperId).remove()
    }

  }
  handleScroll = () => {
    let offset = (this.myRef && this.myRef.current && this.myRef.current.offsetTop)
    let offsetAreaTicket = (this.myRefTicketBlock && this.myRefTicketBlock.current && this.myRefTicketBlock.current.offsetTop)
    if (offset && offsetAreaTicket) {
      if (window.scrollY < (offsetAreaTicket - 100) && window.scrollY > offset) {
        !this.state.showBottom && this.setState({showBottom: true})
      } else {
        this.state.showBottom && this.setState({showBottom: false})
      }
    }
  }
  showNotification = () => {
    this.props.addNotification({
      type: 'success',
      content: <Trans i18nKey={'verify.emailSended'}/>,
      autoClose: 5000,
    })
  }

  handleCollection(e, type) {
    e && e.preventDefault()
    this.setState({error: ''})
    let billingError = false
    const isShowBilling = !isNaN(+this.props.total) && (+this.props.total > 0)
    if (!this.state.isAgreeCheck) {
      this.setState({
        isAgreeCheckError: true
      });
      return;
    } else if (this.state.isAgreeCheckError) {
      this.setState({
        isAgreeCheckError: false
      });
    }

    if (this.state.payAsCompany && (!this.state.companyName.value || !this.state.companyEmail.value)) {
      if (!this.state.companyName.value) {
        this.setState({
          companyName: {
            value: this.state.companyName.value,
            error: 'Field is required'
          }
        })
      }
      if (!this.state.companyEmail.value) {
        this.setState({
          companyEmail: {
            value: this.state.companyEmail.value,
            error: 'Field is required'
          }
        })
      }
      return;
    }
    if (this.state.payAsCompany && !this.state.companyEmail?.value?.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      this.setState({
        companyEmail: {
          value: this.state.companyEmail.value,
          error: 'Enter a valid email address'
        }
      })
      return;
    }

    if (isShowBilling) {
      for (const fieldName in this.state.billingAddress) {
        if (fieldName === 'email'
          && this.state.billingAddress[fieldName].value
          && !this.state.billingAddress[fieldName].value.match(regexEmail)
        ) {
          this.editBillingAddress([fieldName], 'error', 'Email is not valid')
          billingError = true
        }
        if (this.state.billingAddress[fieldName].value.length > 225) {
          this.editBillingAddress([fieldName], 'error', this.props.t('Max length', {chars: '225'}))
          billingError = true
          break;
        }
        if (!this.state.billingAddress[fieldName].value) {
          this.editBillingAddress([fieldName], 'error', 'Field is required')
          billingError = true
          break;
        }
      }
    }


    if (isShowBilling && billingError) return;
    // if (!this.props.isPublished) return false
    this.setState({createOrderLoading: true})
    const attendeesWithoutErrors = {}
    Object.keys(this.props.attendees).forEach(ticketTypeId => {
      attendeesWithoutErrors[ticketTypeId] = this.props.attendees[ticketTypeId].map(attendee => update(attendee, {
        questionsOrder: questionsOrder => {
          const newQuestions = {}
          Object.keys(questionsOrder).forEach(id => {
            newQuestions[id] = ({...questionsOrder[id], error: ''})
          })
          return newQuestions
        },
        rowError: () => '',
        seatError: () => '',
        coupon: (coupon) => !coupon ? undefined : {value: coupon.value},
      }))
    })
    this.props.resetAttedeesErrors(attendeesWithoutErrors)
    const attendees = []
    const tksource = getQueryParam('tksource', this.props.queryString) || localStorage.getItem('referrer') || 'satchel.events'

    Object.keys(this.props.attendees)
      .forEach((ticketTypeId, attendeeIndex) => {
        let promo = ''
        if (this.props.promocodes[ticketTypeId] !== undefined) {
          promo = this.props.promocodes[ticketTypeId].value
        }
        // const ticket = this.props.ticketTypes?.[ticketTypeId]
        this.props.attendees[ticketTypeId].forEach(attendee => {
          for (let i = 0; i < (this.props.ticketTypes[ticketTypeId].simplePay ? this.props.ticketTypes[ticketTypeId].selected : 1); i++) {
            attendees.push({
              tksource,
              coupon: promo,
              ticketType: ticketTypeId,
              row: attendee?.row ?? null,
              seat: attendee?.seat ?? null,
              webLanguage: this.props.i18n.language.toUpperCase(),
              answers: Object.keys(attendee.questionsOrder).map((questionId) => {
                const answer = attendee.questionsOrder[questionId].value || ''
                const questionType = attendee.questionsOrder[questionId].questionType
                return {
                  questionId,
                  answer: questionType === 'PHONE_NUMBER' ? answer.replace(/[^+\d]/g, '') : (answer || ''),
                }
              }),
            })
          }
        })
      })
    const billing = {
      email: this.state.billingAddress.email.value,
      address: this.state.billingAddress.address.value,
      city: this.state.billingAddress.city.value,
      country: this.state.billingAddress.country.value,
      firstName: this.state.billingAddress.firstName.value,
      lastName: this.state.billingAddress.lastName.value,
      zip: this.state.billingAddress.zip.value,
    }
    const variables = {
      attendees,
      // billing,
      isSubscribed: this.props.isSubscribed,
      companyName: this.state.companyName?.value ?? '',
      email: this.state.companyEmail?.value ?? ''
    }
    if (isShowBilling) {
      variables.billing = billing
    }

    if (this.state.payAsCompany) {
      variables.paymentType = type || 'INVOICE_DOWNLOAD'
    } else {
      variables.paymentType = 'YOTY_PAYMENT'
    }

    client.mutate({
      mutation: CREATE_ORDER,
      variables
    })
      .then(resp => {
        const {error, ok, checkoutUrl, order, invoice} = resp.data.createOrder
        const ErrorToDictionaryFormat = (error) => {
          if (error.toLowerCase().indexOf('Coupon with code'.toLowerCase()) === 0
            || error.toLowerCase().indexOf('Invalid coupon'.toLowerCase()) === 0) {
            return 'Incorrect coupon'
          }
          if (error.toLowerCase().indexOf('Field is required'.toLowerCase()) === 0) {
            return 'Field is required';
          }
          if (error === 'Coupon is not active.') {
            return 'Coupon is inactive'
          }
          if (error === 'This ticket type is only with coupon.') {
            return 'This ticket type is only with coupon'
          }
          switch (error) {
            case 'The phone number entered is not valid.':
              return 'Invalid phone number'
            case 'Enter a valid email address.':
              return 'Enter a valid email address'
            case 'Question is required.':
              return 'Field is required'
            default:
              return ''
          }
        }
        if (error) {

          this.setHideItem(false)
          debugger
          if (error.question === null) {
            const errorMessage = error.message.toLowerCase()
            if (
              errorMessage.includes('row') ||
              errorMessage.includes('seat')
            ) {
              this.props.editRow({
                attendeeIndex: error.attendeeIndex,
                ticketTypeId: error.ticketType.toString(),
                field: errorMessage.includes('row') ? 'rowError' : 'seatError',
                value: error.message,
              })
              if (error.message === 'Seat is already reserved' || error.message === 'Row is already reserved') {
                this.props.editEventReducer('fetchSeats', {
                  attendeeIndex: error.attendeeIndex, ticketTypeId: error.ticketType.toString()
                })
              }
            } else {
              this.setState({error: ErrorToDictionaryFormat(error.message)})

              this.props.editCoupon({
                ticketTypeId: error.ticketType,
                attendeeIndex: error.attendeeIndex,
                field: 'error',
                value: ErrorToDictionaryFormat(error.message)
              })
            }
          } else {
            this.props.edit({
              ticketTypeId: error.ticketType,
              attendeeIndex: error.attendeeIndex,
              questionId: error.question,
              field: 'error',
              value: ErrorToDictionaryFormat(error.message),
            })
          }
        } else if (ok) {
          this.setState({loading: true});
          if (this.state.payAsCompany && type === 'INVOICE_EMAIL') {
            window.location.replace('?success=true')
          } else if (this.state.payAsCompany && type === 'INVOICE_DOWNLOAD' && invoice) {
            const a = document.createElement('a');
            a.href = invoice;
            a.target = '_blank';
            a.click();
            a.remove();
          }
          if (this.state.payAsCompany) return;
          this.props.onCreateOrder({checkoutUrl, order});
          let gaCode = this.state.googleAnalyticState
          if (gaCode) {
            ReactGA.event({
              category: 'User',
              action: 'Sent message (ticket pay)'
            });
          }
          localStorage.removeItem('referrer');
          if (checkoutUrl) { /* && window.innerWidth < 768*/
            window.location.replace(checkoutUrl)
          } else {
            window.location.reload()
          }

        }
        this.setState({createOrderLoading: false})
      })
      .catch(e => {
        const error = e.graphQLErrors && e.graphQLErrors[0]
        const state = {
          createOrderLoading: false,
        }
        debugger
        if (error && error.message) {
          debugger
          switch (error.message) {
            case '\'NoneType\' object has no attribute \'merchant_id\'':
              this.setState({...state, error: 'Something went wrong, contact support'})
              break
            case 'You can\'t create order without tickets.':
              this.setState({...state, error: error.message})
              break
            default:
              this.setState({...state, error: 'Something went wrong, contact support'})
          }
        } else {
          this.setState(state)
        }
      })
      .finally(() => {
        this.setState({loading: false})
      })
  }

  scroll = (e) => (!this.state.isAgreeCheck || !this.isSelected) ? animatedScroll('buy') : this.handleCollection(e)
  getTotalSum = () => {
    const {ticketTypes} = this.props
    const ticketTypeIds = []
    Object.keys(ticketTypes).forEach((ticketTypeId) => {
      for (let i = 0; i < ticketTypes[ticketTypeId].selected; i++) ticketTypeIds.push(ticketTypeId)
    })
    if (!ticketTypeIds.length) return
    this.setState({loadingPrice: true})
    client.mutate({mutation: GET_GROUP_DISCOUNT_SUM, variables: {ticketTypeIds}})
      .then(resp => {
        this.setState({loadingPrice: false, price: resp.data.getGroupDiscountSum})
      }).catch(e => {
      this.setState({loadingPrice: false})
    })
  }
  closeModal = () => {
    ReactGA.ga('send', 'pageview', '/mypage');
    this.setState({isPopupShow: false, languageWithUrl: ''})
  }

  get ticketTypeKeys() {
    return Object.keys(this.props.ticketTypes)
  }

  get isSelected() {
    return this.ticketTypeKeys.some((id) => this.props.ticketTypes[id].selected > 0)
  }

  get allFree() {
    return this.ticketTypeKeys.filter((id) => this.props.ticketTypes[id].selected > 0).every((key) => this.props.ticketTypes[key].price === 0)
  }

  textButton = () => {
    const freeTickets = this.ticketTypeKeys.every((key) => this.props.ticketTypes[key].price === 0)
    const somePay = this.ticketTypeKeys.filter((id) => this.props.ticketTypes[id].selected > 0).every((key) => this.props.ticketTypes[key].price > 0)
    const {eventLanguage: lng} = this.props
    if (freeTickets) {
      return <Trans i18nKey='event.ticket.reg'/>
    } else if (somePay && this.isSelected) {
      return <Trans i18nKey='event.ticket.pay'/>
    } else if (somePay) {
      return <Trans i18nKey='event.ticket.buyTicket'/>
    } else {
      return <Trans i18nKey='event.ticket.reg'/>
    }
  }
  handleAgree = () => this.setState({isAgreeCheck: !this.state.isAgreeCheck, isAgreeCheckError: false})
  editBillingAddress = (line, field, value) => {
    debugger
    if (value && value.split('').some(w => w.match(/[А-яЁёїЇіІ]/g))){
      debugger
      return;
    }
    this.setState({
      billingAddress: {
        ...this.state.billingAddress,
        [line]: {
          ...this.state.billingAddress[line],
          error: null,
          [field]: value
        }
      }
    })
  }

  render() {
    if (this.state.apiError || this.state.apiEmpty) {
      return <NotFound content={false} render={(fnBack) => {
        return <div>
          <h1><Trans i18nKey={'error.eventIsEmpty'}/></h1>
          <button onClick={fnBack('/')} className={cx('button-orange-light', 'text-left',
            isSatchel && 'button-orange-light-black')}>
            <Trans i18nKey={'toHome'}/>
          </button>
        </div>
      }
      }/>
    }
    if (this.state.loading) return <Preloader/>
    if (!this.state.event) {
      return <>
        <div className={cx('app', 'invent')}>
          <Header/>
          <div className={cx('justify-center')}><Loader/></div>
        </div>
      </>
    }
    const {
      organizers, name, begins, ends,
      isPublished, image, description, rawDescription,
      isDarkEnabled, currency, slug,
      timezoneField, locationName, locationDescription,
      address, position, isFooterDisplay, id, isTosDisplay,
      isMultilanguage, defaultLanguage, ...eventData
    } = this.state.event
    const {eventLanguage, setEventLang} = this.props
    const {canPublish, isTeam} = this.state
    const formattedCurrentLng =
      this.state.languageWithUrl
        ? formatLanguage(this.state.languageWithUrl)
        : (formatLanguage(this.props.i18n.language) || formatLanguage(this.props.eventLanguage))
    const formattedDefaultLng = defaultLanguage ? formatLanguage(defaultLanguage) : ''
    const translatedName = !isMultilanguage
      ? name
      : (
        (eventData)[`name${formattedCurrentLng}`]
        || (eventData)[`name${formattedDefaultLng}`]
      )
    const translatedDescription = !isMultilanguage
      ? description
      : (
        (eventData)[`description${formattedCurrentLng}`]
        || (eventData)[`description${formattedDefaultLng}`]
      )
    const translatedRawDescription = !isMultilanguage ? rawDescription :
      (
        (eventData)[`rawDescription${formattedCurrentLng}`]
        || (eventData)[`rawDescription${formattedDefaultLng}`]
      )
    const translatedLocationName = (!isMultilanguage
        ? locationName
        : (
          (eventData)[`locationName${formattedCurrentLng}`]
          || (eventData)[`locationName${formattedDefaultLng}`]
        )
    )
    const translatedLocationDescription = (!isMultilanguage
        ? locationDescription
        : (
          (eventData)[`locationDescription${formattedCurrentLng}`]
          || (eventData)[`locationDescription${formattedDefaultLng}`]
        )
    ) || ''
    const isShowBilling = !isNaN(+this.props.total) && (+this.props.total > 0)
    return (
      <>
        <div className={cx('app', 'invent')}>
          <Header/>
          {isTeam && <PreviewBlock
            locationName={locationName}
            address={address}
            eventLanguage={eventLanguage}
            canPublish={canPublish} eventId={id}
            ticketTypes={this.props.ticketTypes}
            refetch={this.fetchData}
            isPublished={isPublished}
            slug={slug}
          />}
          <Banner img={image}/>

          <div className={'container containerInvent'}>
            <EventDetails begins={begins}
                          icalLink={eventData.icalLink}
                          gcalLink={eventData.gcalLink}
                          eventLanguage={eventLanguage}
                          ends={ends}
                          isOnline={eventData.isOnline}
                          name={translatedName}
                          address={address}
                          locationName={translatedLocationName}
                          locationDescription={translatedLocationDescription}
                          textButton={this.textButton()}
                          position={position}
                          description={translatedDescription}
                          rawDescription={translatedRawDescription}
                          timezoneField={timezoneField}
                          slug={slug}/>

            <div ref={this.myRef}> {/*start show fix block*/} </div>

            <EventOrganizers
              organizers={organizers.edges} eventLanguage={eventLanguage}
              description={translatedDescription}
            />

            <div ref={this.myRefTicketBlock}/>
            {/*end show fix block*/}

            {!isPublished &&
              <div className={`ribbon draft`}><Trans i18nKey='event.isDraft'/></div>}
            <div id='buy' className={cx('s-tickets')}>
              <TicketsForm defaultLanguage={defaultLanguage}
                           editBillingAddress={this.editBillingAddress}
                           eventLanguage={eventLanguage}
                           formattedCurrentLng={formattedCurrentLng}
                           isMultilanguage={isMultilanguage}
                           currency={currency} price={this.state.price}
                           eventImage={image} getTotalSum={this.getTotalSum}
                           keysTicketsForPriority={this.state.keysTicketsForPriority}
                           eventID={id} isPublished={isPublished} slug={slug}
                           hideItem={this.state.hideItem} setHideItem={this.setHideItem}
              />

              {isShowBilling && <BillingForm
                billingAddress={this.state.billingAddress}
                edit={this.editBillingAddress}
              />}
              {isTosDisplay &&
                <div className={cx('wrap-checkbox')}>
                  <div className={cx({WrapCheckboxError: this.state.isAgreeCheckError})}>
                    <Checkbox
                      onChange={this.handleAgree}
                      checked={this.state.isAgreeCheck}
                      id="agree"
                      custom
                      className={cx('Checkbox')}
                    />

                    <label className={cx('wrap-agree')} htmlFor="agree">
                      <Trans i18nKey={'event.agree'}/>
                      <Link target={"_blank"} to={'/terms'}><Trans i18nKey={'event.agreeUse'}/></Link>
                      <Trans i18nKey={'event.agreeAnd'}/>
                      <Link target={"_blank"} to={'/privacy'}><Trans i18nKey={'event.agreePolicy'}/></Link>
                    </label>
                  </div>
                  <div>
                    Service Provided by Satchelpay Technologies MB
                  </div>
                </div>}

              {this.state.error &&
                <FieldError style={{display: 'flex', justifyContent: 'center', margin: '10px 0', fontSize: 14}}
                            error={this.state.error}/>}
              <Button text='event.ticket.buyTicket'
                      loading={this.state.createOrderLoading}
                      classNames={cx('btn-green', 'orange', 'center')}
                      disabled={!this.isSelected || this.state.isAgreeCheckError
                        // || (this.state.payAsCompany && (!this.state.companyName || !this.state.companyEmail))
                      }
                      callback={(e) => this.handleCollection(e)}
              >
                {<this.textButton/>}
              </Button>
              <Button
                classNames={cx('BtnText')}
                callback={() => this.setState({payAsCompany: !this.state.payAsCompany})}
              >
                <Trans i18nKey={'Pay as a legal entity'}/>
              </Button>
              {this.state.payAsCompany && (
                <>

                  <div className={cx('Person')}>
                    <p className={cx('PersonTitle')}>
                      <Trans i18nKey={'Pay as a legal entity (please enter your billing information)'}/>
                    </p>
                    <div>
                      <QuestionField
                        labelCustomName={cx('Label')}
                        name={this.props.t('Company name')}
                        error={this.state.companyName?.error}
                        value={this.state.companyName.value ?? ''}
                        edit={(value) => this.setState({companyName: {value, error: null}})}
                        questionType={'INPUT'}
                      />
                      <QuestionField
                        labelCustomName={cx('Label')}
                        name={'E-mail'}
                        error={this.state.companyEmail?.error}
                        value={this.state.companyEmail.value ?? ''}
                        edit={(value) => this.setState({companyEmail: {value, error: null}})}
                        questionType={'INPUT'}
                      />
                    </div>

                  </div>
                  <Button loading={this.state.createOrderLoading}
                          classNames={cx('btn-green', 'orange', 'center')}
                          disabled={this.state.isAgreeCheckError}
                          callback={(e) => this.handleCollection(e, 'INVOICE_DOWNLOAD')}
                  >
                    <Trans i18nKey={'Download invoice'}/>
                  </Button>
                  <Button
                    classNames={cx('BtnText')}
                    callback={(e) => this.handleCollection(e, 'INVOICE_EMAIL')}
                  >
                    <Trans i18nKey={'Send an invoice by mail'}/>
                  </Button>
                </>
              )}
              {this.isSelected && !this.allFree && <div className={cx('totalSum')}>
                <p><Trans i18nKey='event.totalAmount'/></p>
                <span>
                  {this.props.total}
                  <small>EUR</small>

							</span>
              </div>}
            </div>

          </div>

          <Footer classNames={'Footer-custom'} hideContacts={true} isShort={!isFooterDisplay}/>

          <div className={cx('bottom-block', {show: this.state.showBottom})}>
            <div className="container">
              <div className={cx('bottom-inner')}>
                <button
                  onClick={this.scroll}
                  className={cx('btn-green', 'orange')}>
                  {<this.textButton/>}
                </button>

              </div>
            </div>
          </div>
        </div>

        <ModalWrapper
          // isOpen={true}
          isOpen={this.state.isPopupShow}
          close={this.closeModal}>
          <div className={cx('justify-center', 'mt20', 'mb20', 'upAndDown')}>
            {this.state.popupType === 'success'
              ? <CompleteIcon width={50} height={50}/>
              : (
                <span className={cx('Icon')}>
                  <CloseIconSimple/>
                </span>
              )}
          </div>
          {this.state.popupType === 'success' && (
            <div className={cx('fz1728', 'c8c', 'justify-center', 'popup-content')}>
              <span> <Trans i18nKey={'event.popup.messageFirst'}/>: </span>
              <strong>"{translatedName}".</strong>
              <span>
              <Trans i18nKey={'event.popup.messageSecond'}/><Trans
                i18nKey={'event.popup.messageThree'}/>.
            </span>
            </div>)}

          {this.state.popupType === 'error' && (
            <div className={cx('fz1728', 'c8c', 'justify-center', 'popup-content')}>
              <span>{this.props.t("An error occurred while paying for the event")}</span>
              <span>{this.props.t("Please try again later")}</span>
            </div>
          )}
        </ModalWrapper>
      </>
    )
  }
}


const mapState = ({Event, EventEdit, router}) => ({
  fetchSeats: Event.fetchSeats,
  googleAnalytics: EventEdit.googleAnalytics,
  order: Event.order,
  checkoutUrl: Event.checkoutUrl,
  attendees: Event.attendees,
  ticketTypes: Event.ticketTypes,
  paymentSubmitted: Event.paymentSubmitted,
  isSubscribed: Event.isSubscribed,
  total: Event.total,
  promocodes: Event.promocodes,
  queryString: router.location.search,
  eventLanguage: Event.eventLanguage
});

const mapDispatch = (dispatch) => ({
  editRow({field, attendeeIndex, ticketTypeId, value}) {
    dispatch(editAttendeeRow({
      attendeeIndex,
      ticketTypeId,
      field,
      value,
    }))
  },
  editEventReducer(field, value) {
    dispatch(editOrderInfo({field, value}))
  },
  setSubscribe(value) {
    dispatch(setSubscribe(value))
  },
  setTickets(tickets) {
    dispatch(setTickets(tickets))
  },
  setAttendees() {
    dispatch(setAttendees())
  },
  reset() {
    dispatch(reset())
  },
  resetAttedeesErrors(attendees) {
    dispatch(setAttendees(attendees))
  },
  edit(payload) {
    dispatch(editAttendee(payload))
  },
  setEventLang(lng) {
    dispatch(changeEventLang(lng))
  },
  onCreateOrder(order) {
    dispatch(onCreateOrder(order))
  },
  editCoupon(payload) {
    dispatch(editAttendeeCoupon(payload))
  },
  addNotification(config) {
    dispatch(addNotification(config))
  }
})

export default compose(
  withTranslation(),
  connect(mapState, mapDispatch),
)(EventPage)
