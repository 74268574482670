export default {
  long: {
    ln: 'Литовська',
    ru: 'Російська',
    en: 'Англійська',
    uk: 'Українська',
    he: 'Іврит',
    pl: 'Польська',
    lv: 'Латвійська'
  },
  short: {
    ln: 'Лит',
    ru: 'Рос',
    en: 'Анг',
    uk: 'Укр',
    he: 'Івр',
    pl: 'Пол',
    lv: 'Лат'
  },
  ln: 'Литовська',
  ru: 'Російська',
  en: 'Англійська',
  uk: 'Українська',
  he: 'Іврит',
  pl: 'Польська',
  lv: 'Латвійська'
}
