import React, {ForwardedRef, forwardRef} from "react";
import s from './index.module.scss';
import classNames from "classnames/bind";
import {CheckboxPropsType} from "./index.props";
import {CheckIcon} from "assets/img/icons/check";

const cx = classNames.bind(s);


export const Checkbox = forwardRef<ForwardedRef<HTMLInputElement>, CheckboxPropsType>(
  ({label, classNameComponent, float = 'right', view = 'default', ...props}, ref) => {
    const renderLabel = label ? <p className={cx('Label')}>{label}</p> : ''
    return (
      <label className={cx('Component', classNameComponent || '', {ComponentRoller: view === 'roller'})}>
        {float === 'left' && renderLabel}
        <input
          ref={ref as any}
          type={'checkbox'}
          {...props}
        />
        <span
          style={{
            marginLeft: float === 'left' ? 10 : 0,
            marginRight: float === 'right' ? 10 : 0
          }}
          className={cx('Checkbox')}>
          {view === 'default' && <CheckIcon className={cx('Icon')}/>}
        </span>
        {float === 'right' && renderLabel}
      </label>
    )
  }
)