import { useState, useEffect } from "react";
import {useLocation} from "react-router";

export function useToggle (initialState: boolean): [boolean, () => void, React.Dispatch<React.SetStateAction<boolean>>] {
  const [value, setState] = useState<boolean>(initialState)

  function toogle () {
    setState(!value)
  }

  return [value, toogle, setState]
}

export function useField<T = string> (value?:T, error = ''): [T, React.Dispatch<React.SetStateAction<T>>, string, React.Dispatch<React.SetStateAction<string>>] {
  const [fieldError, setError] = useState(error)
  const [fieldValue, setValue] = useState<T>((value || '') as T)
  return [fieldValue, setValue, fieldError, setError]
}
export function usePageView(){
  const {pathname} = useLocation()
  useEffect(()=> {
    window.scrollTo(0,0)
  },[pathname])
}
