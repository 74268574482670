import ActionTypes from './actionTypes'
import update from 'immutability-helper'
import i18n from '../../locale';
import {LANGUAGES} from 'constants/';
import {DateTime} from "luxon";
import uuid from 'react-uuid'

/**
 * @important
 * All dates will use luxon for more easy working
 * TicketTypes will use it too
 */

const availableLanguages = {}
LANGUAGES.forEach(lng => availableLanguages[lng] = false)

export const NEW_TICKET_TYPE_MODEL = {
  from: null,
  to: null,
  rows: null,
  file: null,
  isKeyword: false,
  id: null,
  name: '',
  nameEn: '',
  nameRu: '',
  nameUk: '',
  nameHe: '',
  namePl: '',
  nameLv: '',
  nameLn: '',
  description: '',
  descriptionEn: '',
  descriptionRu: '',
  descriptionUk: '',
  descriptionHe: '',
  descriptionPl: '',
  descriptionLv: '',
  descriptionLn: '',
  price: '0',
  quantity: '',
  isUnlimited: false,
  isAwaitingApproval: false,
  simplePay: false,
  begins: null,
  ends: null,
  couponOnly: false,
  couponTypes: null,
  color: null,
  isCustomTicketLetterEnabled: false,
  customTicketLetterSubject: '',
  customTicketLetter: '',
  isSeasonTicketType: false,
  isRows: false
}

export const DEFAULT_QUESTION_TYPE_FIELDS = [
  {
    id: null,
    helpText: null,
    helpTextEn: null,
    helpTextHe: null,
    helpTextLv: null,
    helpTextPl: null,
    helpTextRu: null,
    helpTextUk: null,
    isDefault: true,
    isRequired: true,
    name: "First Name",
    nameEn: "First Name",
    nameHe: "First Name",
    nameLv: "Vārds",
    nameLn: "Pirmas vardas",
    namePl: "Imię",
    nameRu: "Имя",
    nameUk: "Ім'я",
    options: null,
    optionsEn: null,
    optionsHe: null,
    optionsLv: null,
    optionsPl: null,
    optionsLn: null,
    optionsRu: null,
    optionsUk: null,
    priority: 1,
    questionType: "INPUT"
  },
  {
    helpText: null,
    helpTextEn: null,
    helpTextHe: null,
    helpTextLv: null,
    helpTextLn: null,
    helpTextPl: null,
    helpTextRu: null,
    helpTextUk: null,
    id: null,
    isDefault: true,
    isRequired: true,
    name: "Last Name",
    nameEn: "Last Name",
    nameHe: "Last Name",
    nameLn: "Pavardė",
    nameLv: "Uzvārds",
    namePl: "Nazwisko",
    nameRu: "Фамилия",
    nameUk: "Прізвище",
    options: null,
    optionsEn: null,
    optionsHe: null,
    optionsLv: null,
    optionsPl: null,
    optionsLn: null,
    optionsRu: null,
    optionsUk: null,
    priority: 2
  },
  {
    id: null,
    helpText: null,
    helpTextEn: null,
    helpTextHe: null,
    helpTextLv: null,
    helpTextLn: null,
    helpTextPl: null,
    helpTextRu: null,
    helpTextUk: null,
    isDefault: true,
    isRequired: true,
    name: "Email",
    nameLn: "El. paštas",
    nameEn: "Email",
    nameHe: "Email",
    nameLv: "E-pasts",
    namePl: "E-mail",
    nameRu: "Email",
    nameUk: "Email",
    options: null,
    optionsEn: null,
    optionsHe: null,
    optionsLv: null,
    optionsLn: null,
    optionsPl: null,
    optionsRu: null,
    optionsUk: null,
    priority: 3,
    questionType: "EMAIL",
  },
  {
    id: null,
    helpText: null,
    helpTextEn: null,
    helpTextHe: null,
    helpTextLv: null,
    helpTextPl: null,
    helpTextLn: null,
    helpTextRu: null,
    helpTextUk: null,
    isDefault: true,
    isRequired: true,
    name: "Phone Number",
    nameEn: "Phone Number",
    nameHe: "Phone Number",
    nameLv: "Telefona numurs",
    namePl: "Numer telefonu",
    nameRu: "Номер телефона",
    nameUk: "Номер телефону",
    nameLn: "Telefono numeris",
    options: null,
    optionsEn: null,
    optionsHe: null,
    optionsLv: null,
    optionsPl: null,
    optionsRu: null,
    optionsUk: null,
    optionsLn: null,
    priority: 4,
    questionType: "PHONE_NUMBER",
  }
]
export const ROW_MODEL = {
  id: null,
  fromSeat: null,
  toSeats: null,
}

export const initialState = {
  isKeywordAllEvent: false,
  file: null,
  //check if field for update general
  titleLnTicketsSection: null,
  updateFieldGeneralInfo: [],
  removeImage: false,

  //checked field for update ticket type
  deleteTicketTypes: [],
  updateTicketTypes: {},
  addTicketTypes: [],
  addedPriorityTicket: false,

  //checked field for update question
  deleteQuestionForUpdate: [],
  updateQuestion: {},
  addNewQuestion: [],
  addedPriorityQuestion: [],

  showAdditionalOptionId: null, //for ticketTypeCart additional option
  ticketTypeIdQuestions: null,
  colors: [
    {color: '#00B68A'},
    {color: '#FFD657'},
    {color: '#F24822'},
    {color: '#6FCF97'},
    {color: '#FF7800'},
    {color: '#56CCF2'},
    {color: '#2D9CDB'},
    {color: '#9B51E0'},
    {color: '#FF7ABA'},
    {color: '#FF7ABA'},
    {color: '#306BD3'},
  ],

  locationName: '',
  locationDescription: '',
  position: [0, 0],
  address: '',
  questions: [],
  isMenuEventShow: true,
  isModalIntegration: false,
  tabIntegration: 'ga', // ga or fp
  id: null,
  slug: null,
  slugState: '',
  slugChange: false,
  existsSlug: false,
  ticketTypes: [],
  image: '',
  isPublished: null,
  currency: 'EUR',
  name: '',
  description: '',
  isDescriptionBig: false,
  isDarkEnabled: true,
  organizers: [],
  isOwner: null,
  myRole: null,
  isHidden: null,
  isWithSms: null,
  begins: DateTime.local().setZone('local', {keepLocalTime: true}),
  ends: DateTime.local().plus({day: 1}).setZone('local', {keepLocalTime: true}),
  timezoneField: DateTime.local().zoneName,
  loading: true,
  isOnline: false,
  isOneLocation: true,
  /**
   * New fields for multilanguage
   * Make availableLanguages obj dynamic
   */
  // @see createEvent/updateEvent
  savedIsMultilanguage: false,
  isMultilanguage: false,
  // @see ActionTypes.EDIT:
  savedDefaultLanguage: i18n.language,
  defaultLanguage: i18n.language,
  availableLanguages,
  nameUk: '',
  nameRu: '',
  nameEn: '',
  namePl: '',
  nameLv: '',
  nameLn: '',
  descriptionUk: '',
  descriptionRu: '',
  descriptionEn: '',
  descriptionPl: '',
  descriptionLv: '',
  descriptionLn: '',
  locationNameUk: '',
  locationNameRu: '',
  locationNameEn: '',
  locationNamePl: '',
  locationNameLn: '',
  locationNameLv: '',
  locationDescriptionUk: '',
  locationDescriptionRu: '',
  locationDescriptionEn: '',
  locationDescriptionPl: '',
  locationDescriptionLn: '',
  locationDescriptionLv: '',
}

const EventEdit = (state = initialState, {payload, type, ...action}) => {
  switch (type) {
    //Promocodes
    case ActionTypes.OPERATION_WITH_TICKET_TYPE_UPDATE:
      const updateTicketKeys = Object.keys(state.updateTicketTypes)
      let newObj = {...state.updateTicketTypes}
      if (updateTicketKeys.length === 0 || updateTicketKeys.every(key => key !== action.ticketId)) {
        newObj = {
          ...newObj,
          [action.ticketId]: [action.field]
        }
      } else {
        updateTicketKeys.forEach(key => {
          if (key === action.ticketId) {
            if (!newObj[key].some(el => el === action.field)) {
              newObj[key] = [...newObj[key], action.field]
            }
          }
        })
      }
      return {
        ...state,
        updateTicketTypes: newObj
      }
    case ActionTypes.OPERATION_WITH_TICKET_TYPE_PRIORITY:
      let obj = {...state.updateTicketTypes}
      let updateTicketTypeKeys = Object.keys(state.updateTicketTypes)
      state.ticketTypes.filter(ticket => ticket.oldTicket).map((ticket) => {
        if (updateTicketTypeKeys.every(el => el !== ticket.id)) {
          obj[ticket.id] = ['priority']
        }
        updateTicketTypeKeys.map((ticketId) => {
          if ((ticketId === ticket.id) && ticket.oldTicket) {
            obj[ticketId] = [...obj[ticketId], 'priority']
          }
        })
      })
      return {
        ...state,
        updateTicketTypes: obj,
        addedPriorityTicket: true
      }
    case ActionTypes.OPERATION_WITH_QUESTION_PRIORITY:
      let objNew = {...state.updateQuestion}
      state.ticketTypes.map(ticket => {
        if (ticket.id === state.ticketTypeIdQuestions) {
          objNew[ticket['id']] = ticket.questions.map(question => {
            return !question.isDefault && question.id
          })
        }
      })
      return {
        ...state,
        updateQuestion: objNew,
        addedPriorityQuestion: state.addedPriorityQuestion.some(el => el === state.ticketTypeIdQuestions) ? state.addedPriorityQuestion : [...state.addedPriorityQuestion, state.ticketTypeIdQuestions]
      }
    case ActionTypes.OPERATION_WITH_TICKET_TYPE_DELETE:
      let newUpdateTicket = {...state.updateTicketTypes}
      let newUpdateQuestion = {...state.updateQuestion}
      let updateTicketTypesKeys = Object.keys(newUpdateTicket)
      if (updateTicketTypesKeys.some(el => el === action.ticketId)) {
        delete newUpdateTicket[action.ticketId]
      }
      if (Object.keys(state.updateQuestion).some(el => el === action.ticketId)) {
        delete newUpdateQuestion[action.ticketId]
      }
      return {
        ...state,
        deleteTicketTypes: [...state.deleteTicketTypes, {id: action.ticketId, event: state.id}],
        updateTicketTypes: newUpdateTicket,
        addTicketTypes: state.addTicketTypes.filter(ticket => ticket.id !== action.ticketId),
        updateQuestion: newUpdateQuestion
      }
    case ActionTypes.ADD_UPDATE_FIELDS:
      if (action.field === 'isMultilanguage') {
        return {...state, updateFieldGeneralInfo: [...state.updateFieldGeneralInfo, action.field, 'availableLanguages']}
      }
      return {...state, updateFieldGeneralInfo: [...state.updateFieldGeneralInfo, action.field]}
    case ActionTypes.DELETE_QUESTION:
      let objUpdate = {}
      const getNewObj = () => {
        if (!state.ticketTypeIdQuestions || !action.questionId) return state.updateQuestion
        objUpdate = {...state.updateQuestion}
        objUpdate[state.ticketTypeIdQuestions] = [
          ...objUpdate[state.ticketTypeIdQuestions].filter(el => el !== action.questionId)
        ]
        state.updateQuestion[state.ticketTypeIdQuestions] = state.updateQuestion[state.ticketTypeIdQuestions].filter(el => el !== action.questionId)
        return objUpdate
      }
      return {
        ...state,
        ticketTypes: state.ticketTypes.map(ticket => {
          if (ticket.id === state.ticketTypeIdQuestions) {
            return {
              ...ticket,
              questions: ticket.questions.filter(question => question.id !== action.questionId)
            }
          } else {
            return ticket
          }
        }),
        addNewQuestion: action.isEventEdit ? state.addNewQuestion.filter(el => el.questionId !== action.questionId) : state.addNewQuestion,
        deleteQuestionForUpdate: (action.oldQuestion && action.isEventEdit) ? [...state.deleteQuestionForUpdate, action.questionId] : state.deleteQuestionForUpdate,
        updateQuestion: (action.oldQuestion && action.isEventEdit) ?
          Object.keys(state.updateQuestion).some(el => el === state.ticketTypeIdQuestions && state.updateQuestion[el].some(innerEl => innerEl === action.questionId))
          && getNewObj()
          : state.updateQuestion

      }
    case ActionTypes.ADD_QUESTION:
      const {ticketTypes, ticketTypeIdQuestions} = state
      let ticketTypeKeys = Object.keys(ticketTypes)
      let ticketTypesNew = ticketTypeKeys.map(key => {
        if (ticketTypes[key].id === ticketTypeIdQuestions) {
          return {
            ...ticketTypes[key],
            questions: [...ticketTypes[key].questions, {
              ...action.questionData,
              priority: ticketTypes[key].questions.length + 1
            }]
          }
        } else return {...ticketTypes[key]}
      })
      return {
        ...state,
        ticketTypes: ticketTypesNew,
        addNewQuestion: action.isEventEdit
        && state.ticketTypes.some(el => el.id === ticketTypeIdQuestions && el.oldTicket)
        && !state.addNewQuestion.some(el => el.ticketTypeId === ticketTypeIdQuestions)
          ? [...state.addNewQuestion, {
            questionId: action.questionData.id,
            ticketTypeId: ticketTypeIdQuestions
          }] : state.addNewQuestion
      }
    case ActionTypes.EDIT_QUESTION:
      let idOldQuestion = null
      const updateQuestionKeys = Object.keys(state.updateQuestion)
      let updateObj = {}
      const getUpdateQuestionObj = () => {
        if (!idOldQuestion || !state.ticketTypeIdQuestions) {
          return state.updateQuestion
        }
        if (updateQuestionKeys.some(el => el === state.ticketTypeIdQuestions) && idOldQuestion) {
          updateObj[state.ticketTypeIdQuestions] = [...state.updateQuestion[state.ticketTypeIdQuestions], idOldQuestion]
        } else {
          updateObj[state.ticketTypeIdQuestions] = [idOldQuestion]
        }
        return updateObj
      }
      let newTicketTypes = state.ticketTypes.map(ticketType => {
        if (ticketType.id === state.ticketTypeIdQuestions) {
          let questions = ticketType.questions = ticketType.questions.map(question => {
            if (question.id === action.questionId) {
              if (question.oldQuestion) {
                idOldQuestion = question.id
              }
              return action.questionData
            } else return question
          })
          return {...ticketType, questions}
        } else return ticketType
      })
      return {
        ...state,
        ticketTypes: newTicketTypes,
        updateQuestion: action.isEventEdit ? getUpdateQuestionObj() :
          state.updateQuestion
      }
    case ActionTypes.ADD_ORGANIZER:
      let hasOrganizer = state.organizers.some(el => el === action.id)
      if (hasOrganizer) {
        return {
          ...state, organizers: state.organizers.filter(organizer => organizer !== action.id)
        }
      } else {
        return {
          ...state, organizers: [...state.organizers, action.id]
        }
      }
    case ActionTypes.ADD_TICKET_COLOR:
      return {
        ...state,
        colors: [...state.colors, {color: action.color}]
      }
    case ActionTypes.ADD_TICKET_TYPE:
      let reBegins = DateTime.local()
      let reEnds = reBegins.plus({day: 1})
      let ticketTypeId = uuid().toString()
      let ticketTypeIdQuestionsObj = {}
      if (state.ticketTypes.length === 0) {
        ticketTypeIdQuestionsObj = {ticketTypeIdQuestions: ticketTypeId}
      }
      return {
        ...state,
        ...ticketTypeIdQuestionsObj,
        addTicketTypes: action.newTicketForEventEdit ? [...state.addTicketTypes, {
          id: ticketTypeId,
          event: state.id
        }] : state.addTicketTypes,
        ticketTypes: [...state.ticketTypes,
          {
            ...NEW_TICKET_TYPE_MODEL,
            color: state.colors[Math.floor(Math.random() * 10)].color,
            id: ticketTypeId,
            begins: reBegins,
            ends: null,
            questions: [
              ...DEFAULT_QUESTION_TYPE_FIELDS.map(el => ({...el, id: uuid().toString()}))
            ]
          }
        ]
      }
    case ActionTypes.CHANGE_TICKET_TYPE:
      return {
        ...state,
        ticketTypes: state.ticketTypes.map((el) => {
          if (el.id === action.id) {
            return {...el, [action.field]: action.value}
          } else return el
        })
      }
    case ActionTypes.REMOVE_TICKET_TYPE:
      let filterTypes = state.ticketTypes.filter(ticketType => ticketType.id !== action.id)
      let tabId = null
      if (filterTypes.length === 0) {
        tabId = null
      } else if (state.ticketTypeIdQuestions === action.id && filterTypes.length > 1) {
        if (filterTypes.length === 1) {
          tabId = filterTypes[0].id
        } else if (filterTypes.length > 1 && action.id === state.ticketTypeIdQuestions && filterTypes[0].id !== state.ticketTypeIdQuestions) {
          tabId = filterTypes[0].id
        } else {
          tabId = filterTypes[1].id
        }
      } else {
        tabId = filterTypes[0].id
      }
      return {
        ...state,
        ticketTypes: filterTypes,
        ticketTypeIdQuestions: filterTypes.length > 1 && action.id !== state.ticketTypeIdQuestions ? filterTypes[0].id : tabId
      }
    case ActionTypes.REQUEST_EVENT_DATA:
      return {...state, loading: true}
    case ActionTypes.SET_EVENT_DATA :
      return update(state, {
        $merge: {...payload, loading: false}
      })
    case ActionTypes.EDIT:
      if (payload.field === 'isMultilanguage') {
        // User set true to isMultilanguage
        if (payload.value && !state.isMultilanguage) {
          return update(state, {
            [payload.field]: {
              $set: payload.value
            },
            availableLanguages: {
              [state.defaultLanguage]: {$set: true}
            }
          })
          // User set false to isMultilanguage and defaultLanguage is empty
        } else if (!payload.value && state.isMultilanguage && state.defaultLanguage === '') {
          return update(state, {
            [payload.field]: {
              $set: payload.value
            },
            defaultLanguage: {$set: state.savedDefaultLanguage}
          })
        }
      }
      return update(state, {
        [payload.field]: {
          $set: payload.value
        }
      })
    case ActionTypes.RESET:
      return initialState

    case ActionTypes.SCHEMA_GENERATE:
      // if (action.from === state.schema.from && action.to === state.schema.to) return state;
      let from = action.from
      let to = action.to
      if (from < 0) {
        from = null
      }
      if (to < 0) {
        to = null
      }

      let rows = []
      if (!!from && !!to) {
        from = Math.min(from, to)
        to = Math.max(from, to)
        for (let i = from; i <= to; i++) {
          rows = [...rows, {
            ...ROW_MODEL,
            fromSeat: 1,
            toSeats: 30,
            id: Date.now() + i, rowNumber: i,
          }]
        }
      }
      return {
        ...state,
        ticketTypes: state.ticketTypes.map(t => t.id === action.ticketId ? ({
          ...t,
          from, to, rows
        }) : t)
      }
    case ActionTypes.SCHEMA_COPY_ROW: {
      const ticket = state.ticketTypes.find(t => t.id === action.ticketId)
      const rowNumber = ticket.rows[ticket.rows?.length - 1].rowNumber + 1
      const row = {
        ...ticket.rows.find(r => r.id === action.id),
        rowNumber,
        id: Date.now()
      }
      return {
        ...state,
        ticketTypes: state.ticketTypes.map(t => t.id === action.ticketId ? ({
          ...t,
          to: t.to + 1,
          rows: [...t.rows, row]
        }) : t),

      }
    }

    case ActionTypes.SCHEMA_EDIT_ROW:
      const fieldName = action.fieldName
      const value = action.value
      const ticketId = action.ticketId
      const newTicket = state.ticketTypes.map(t => t.id === ticketId ? ({
        ...t,
        rows: t.rows.map((r) => r.id === action.id ? ({...r, [fieldName]: value}) : r)
      }) : t)
      return {
        ...state,
        ticketTypes: newTicket
      }

    default:
      return state
  }
}

export default EventEdit
