import React, {CSSProperties, FC} from 'react';
import s from './index.module.scss';
import {DividerPropsType} from "./index.props";
import classNames from "classnames/bind";

const cx = classNames.bind(s);

export enum OrientationSymmetric {
  Horizontal,
  Vertical
}

export const Divider: FC<DividerPropsType> =
  ({
     gap, color = `rgba(0, 0, 0, .1)`, height = 1, className, style, maxWidth = '100%',
     orientation = OrientationSymmetric.Horizontal, ...divProps
   }) => {
    const styles = {
      marginTop: gap?.y || gap?.top || 0,
      marginBottom: gap?.y || gap?.bottom || 0,
      backgroundColor: color,
      maxWidth,
      transform: orientation === OrientationSymmetric.Horizontal ? 'none' : 'rotate(90deg)',
      height,
      ...style
    } as CSSProperties

    return (
      <div
        className={cx('Divider', className ?? '')}
        style={styles}
        {...divProps}
      />
    )
  }