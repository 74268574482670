import error from './error'
import landing from './landing'
import sign from './sign'
import event from './event'
import user from './user'
import footer from './footer'
import languages from './languages'
import searchPage from './search_page'
import postersPage from './posters_page'

export default {
  sign,
  error,
  event,
  landing,
  user,
  languages,
  searchPage,
  postersPage,
  footer,
  'Buy a ticket only if you have a promotional code': 'Купить билет, только если у вас есть промокод',
  'Billing address': 'Billing address',
  "email": "Email",
  "address": "Address",
  "city": "City",
  "country": "Country",
  "firstName": "First name",
  "lastName": "Last name",
  "zip": "Postal code",
  "Max length": "Max length: {{chars}} chars",

  'Pay as a legal entity (please enter your billing information)': 'Оплатить как юридическое лицо (пожалуйста, введите свои платежные данные)',
  'Pay as a legal entity': 'Оплатить как юридическое лицо',
  'Download invoice': 'Скачать счет',
  'Paid': 'Оплаченно',
  'Not paid': 'Не оплаченно',
  'Sum': 'Сумма',
  'Company name': 'Название компании',

  'Date created': 'Дата создания',
  'Number of tickets': 'Количество билетов',
  'Resend invoice': 'Отправить счет повторно',
  'Mark as paid': 'Отметить как оплаченное',
  'Mark as not paid': 'Отметить как не оплаченный',
  goFuck: 'Російський корабель, іди нах*й!',
  helpArmy: 'Помощь украинской армии',
  showBy: 'Показать по',
  show: 'Показать',
  copy: 'Скопировать',
  copied: 'Скопировано',
  aliasDescr1: 'Вы можете указать уникальный alias и давать ссылки людям, в facebook и т.д. в виде:',
  aliasDescr2: 'Это удобно, так как люди будут видеть только ваши события.',
  learnMore: 'Узнать больше',
  subscribeForm: {
    header: 'Получайте письма от satchel.events, чтобы всегда быть в курсе обновлений!',
    success: 'Благодарим за подписку 😁'
  },
  ticketActivation: {
    title: 'Активация билета',
    ticketCreated: 'Билет оформлен',
    order: 'Заказ'
  },
  toHome: 'На главную',
  cancel: 'Отменить',
  notifications: {
    smsSent: 'SMS успешно отправлено.',
    saved: 'Сохранено',
    error: 'Произошла ошибка!',
  },
  verify: {
    sendEmailAgain: 'Отправить письмо снова',
    verificatePhone: 'Подтвердить номер телефона',
    emailVerificated: 'Поздравляем, email подтвержден!',
    emailSended: 'Письмо отправлено',
    email: 'Подтвердите ваш email.',
    phone: 'Подтвердите ваш номер телефона'
  },
  changeLang: 'Изменить язык',
  terms: {
    title: 'Правила сервиса',
    tabs: {
      organizer: 'Для организаторов',
      customer: 'Для покупателей'
    }
  },
  policy: {
    title: 'Политика конфиденциальности'
  },
  cookie: {
    text: 'Используя satchel.events, вы соглашаетесь с нашей политикой в отношении файлов cookie. <0>Узнать больше.</0>',
    accept: 'Принять',
  },
  send: 'Отправить',
  remove: 'Удалить',
  download: 'Скачать',
  weekDays: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс']
}
