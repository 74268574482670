import React, {useCallback} from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import {useDropdown, useStore} from 'helpers/hooks/index'
import {Trans, useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {Avatar} from "components/Avatar";
import {HistoryIcon, HouseSimpleIcon, RectangleIcon, SignOutIcon, UserIcon} from "assets/img/icons/modules";
import {getToken, logOut} from "helpers/localStorage";
import {showSignModal} from "store/Base/actions";
import TicketBG from "../../Icons/TicketBG";
import {useHistory, useLocation} from "react-router";

const cx = classNames.bind(styles)

const UserActions = () => {
  const dropdown = useDropdown()
  const {pathname} = useLocation()
  const {push} = useHistory()
  const isAuth = !!getToken()
  const {firstName, avatar} = useStore((state) => state.Profile)
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const onClickLoginBtn = useCallback(() => {
    const token = getToken()
    if (token) {
      push('/dashboard')
    } else {
      dispatch(showSignModal({tab: 'login'}))
    }
  }, [dispatch])
  const onClickLogoutBtn = () => logOut()


  if (!isAuth)
    return (
      <button
        onClick={onClickLoginBtn}
        className={cx('TicketButton')}>
        <TicketBG size={270}/>
        <span>{isAuth ? t('user.myEvents') : t('sign.enter')}</span>
      </button>)
  return (
    <>
      <div ref={dropdown.ref} className={cx('Component')}>
        <div className={cx('Wrap')}>
          <button type='button' onClick={dropdown.toggle} className={cx('Control')}>
            <Avatar
              className={cx('Avatar')}
              avatar={avatar}
              firstName={firstName}
              label={firstName ?? 'Unknown'}
            />
            <RectangleIcon orientation={dropdown.open ? 'top' : 'bottom'}/>
          </button>
        </div>
        {dropdown.open && (
          <div className={cx('Content')}>
            {!pathname.includes('/create-event') && isAuth && (
              <>
                <Link to='/create-event' className={cx('CreateLink', 'Landscape')}>
                  <Trans i18nKey="event.createEvent"/>
                </Link>
                <div className={cx('Divider', 'Landscape')}></div>
              </>)}
            <Link onClick={dropdown.toggle} to='/dashboard'>
              <div className={cx('Icon')}><HouseSimpleIcon/></div>
              <span>{t('user.myEvents')}</span>
            </Link>
            <Link onClick={dropdown.toggle} to='/profile'>
              <div className={cx('Icon')}><UserIcon width={16} height={16}/></div>
              <span>{t('user.profile')}</span>
            </Link>
            <Link onClick={dropdown.toggle} to='/reservations'>
              <div className={cx('Icon')}><HistoryIcon/></div>
              <span>{t('My tickets')}</span>
            </Link>
            <div className={cx('Divider')}></div>
            <button onClick={onClickLogoutBtn}>
              <div className={cx('Icon')}><SignOutIcon orientation={'left'}/></div>
              <span>{t('Log out')}</span>
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default UserActions
