import React, {FC, HTMLAttributes, useEffect, useState} from 'react'
import './index.scss'
import uuid from "react-uuid";
import {useTranslation} from "react-i18next";

interface CheckboxPropsI extends HTMLAttributes<HTMLInputElement> {
  checked?: boolean,
  onChange: any,
  className?: string,
  required: boolean,
  labelClassName?: string,
  size?: string,
  circle?: boolean,
  custom?: boolean,
  trans?: boolean,
  label: string
}

const Checkbox: FC<CheckboxPropsI>
  = ({
       size = 'small',
       trans = false,
       checked = false,
       className, onChange,
       required,
       circle, labelClassName,

       custom = false, label, ...props
     }) => {
  const [id, setId] = useState(props.id || label)
  const [t] = useTranslation();
  useEffect(() => {
    if (!label && !props.id) {
      setId(uuid())
    }
  }, [props.id, label])


  return (
    <div className={`checkbox-component  ${className ?? ''} ${custom ? 'custom' : ''} ${size ? size : ''} `}>
      <input
        onChange={(e) => onChange(e.target.checked)}
        type="checkbox"
        id={id}
        required={required}
        checked={checked}
        {...props}
      />
      <span className={`checkmark  ${size ? size : ''} ${circle ? 'circle' : ''}`}>
         {!circle && <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path
             d="M7.78397 1.39936L3.16105 5.98518C3.03503 6.11019 2.83151 6.10939 2.7065 5.98336L0.274868 3.53204C0.149859 3.40601 0.150662 3.2025 0.276716 3.07746L0.88526 2.47382C1.01129 2.34881 1.21481 2.34961 1.33981 2.47566L2.94139 4.09023L6.7276 0.334441C6.85363 0.209432 7.05714 0.210236 7.18215 0.336263L7.7858 0.94478C7.91081 1.07083 7.91 1.27435 7.78397 1.39936Z"
             fill="#8C8C8C"/>
         </svg>}
        {circle &&
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="4.50035" cy="4.49987" r="3.86364" fill="#8C8C8C"/>
        </svg>
        }
          </span>
      {label && <label className={labelClassName} htmlFor={id}>
        {trans ? t(label) : label}
      </label>}
    </div>
  )
}
export default Checkbox;