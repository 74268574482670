import React, {FC} from "react"

export type DefaultIconPropsType = {
  fill?: string, height?: number | string, width?: number | string, orientation?: 'top' | 'bottom' | 'left' | 'right'
}
export const HistoryIcon: FC<DefaultIconPropsType> = ({fill = `#333`, height = 18, width = 18}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M6.0793 3.83301C6.03749 4.25412 5.68219 4.58301 5.25008 4.58301C4.81797 4.58301 4.46267 4.25412 4.42086 3.83301H2.33341V5.16634H15.6667V3.83301H14.3293C14.2875 4.25412 13.9322 4.58301 13.5001 4.58301C13.068 4.58301 12.7127 4.25412 12.6709 3.83301H6.0793ZM6.07894 3.83382H12.6705H6.07894ZM4.41675 2.1665V1.49984C4.41675 1.0396 4.78984 0.666504 5.25008 0.666504C5.71032 0.666504 6.08342 1.0396 6.08342 1.49984V2.1665H12.6667V1.49984C12.6667 1.0396 13.0398 0.666504 13.5001 0.666504C13.9603 0.666504 14.3334 1.0396 14.3334 1.49984V2.1665H15.7501C16.6245 2.1665 17.3334 2.87539 17.3334 3.74984V14.8332C17.3334 16.2139 16.2141 17.3332 14.8334 17.3332H3.16675C1.78604 17.3332 0.666748 16.2139 0.666748 14.8332V3.74984C0.666748 2.87539 1.37563 2.1665 2.25008 2.1665H4.41675ZM15.6667 6.83431H2.33341V14.8343C2.33341 15.2945 2.70651 15.6676 3.16675 15.6676H14.8334C15.2937 15.6676 15.6667 15.2945 15.6667 14.8343V6.83431ZM4.49976 9.83236C4.03952 9.83236 3.66642 9.45926 3.66642 8.99902C3.66642 8.53879 4.03952 8.16569 4.49976 8.16569H7.49975C7.95999 8.16569 8.33309 8.53879 8.33309 8.99902C8.33309 9.45926 7.95999 9.83236 7.49975 9.83236H4.49976ZM4.49976 12.8333C4.03952 12.8333 3.66642 12.4602 3.66642 11.9999C3.66642 11.5397 4.03952 11.1666 4.49976 11.1666H10.4998C10.96 11.1666 11.3331 11.5397 11.3331 11.9999C11.3331 12.4602 10.96 12.8333 10.4998 12.8333H4.49976Z"
            fill={fill}/>
    </svg>

  )
}