import React, {useCallback, useState} from 'react'
import classNames from 'classnames/bind'
import s from './HeaderSetting.module.scss'
import {ACCESS_ROLES} from 'constants/';
import {useSelector} from "react-redux";
import generateNavs from "./generateNavs";
import {EyeIcon, PowerIcon} from "../../../containers/Pages/Event/Icons/Icons";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router";

const cx = classNames.bind(s)

const HeaderSetting = ({changePublish, match}) => {
  const {isOwner, myRole, isPublished, slug: slugState} = useSelector(({EventEdit}) => EventEdit)
  const slug = match && match.params && match.params.slug
  const {push} = useHistory()
  const {pathname} = useLocation()
  const [t] = useTranslation()
  const path = `/event-edit/${ slug || slugState}`
  const isFullAccess = isOwner || myRole === ACCESS_ROLES.CO_ORGANIZER
  const [tab, setabT] = useState(null)

  const onTabClick = useCallback((e, tab) => {
    e && e.preventDefault()
    // history.block(true)
    push({
      pathname: `${path}${tab ? '/' + tab : ''}`,
      state: {some: slugState}
    })
    setabT(tab)
  }, [tab])
  let navs = generateNavs(isOwner, myRole) || []
  return (
    <div className={cx('wrap-setting')}>
      <div className={'container'}>
        <div className={cx('items')}>

          <div className={cx('wrap-item')}>
            {navs.map(({tab, trans, icon}) => {
              const isDesc = tab === 'description'
              return <a onClick={e => onTabClick(e, isDesc ? "" : tab)}
                        className={cx('nav', {active: isDesc ? pathname === path : pathname.includes(`/${tab}`)})}>
                <div className={cx('icon')}>{icon}</div>
                <span>{t(trans)}</span>
              </a>
            })}
            <Link className={cx('nav')} to={{
              pathname: `/event/${slug}?preview=true`
            }}>
              <div className={cx('icon')}><EyeIcon fill={'var(--accent-color)'}/></div>
              <span>{t('event.view')}</span>
            </Link>
          </div>
        </div>
        {isFullAccess && <>
          <div className={cx('publish', {draft: isPublished})} onClick={changePublish}>
            <PowerIcon/>
            <span className={cx('publish-text')}>{t(`${isPublished ? 'event.draft' : 'event.publish'}`)}</span>
          </div>
        </>}
      </div>

    </div>
  )
}
export default HeaderSetting
