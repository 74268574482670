import React, {Fragment} from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import IconPin from 'components/Icons/Pin'
import IconEmail from 'components/Icons/Email'
import {useLocation} from 'react-router'
import IconPhone from 'components/Icons/Phone'

const cx = classNames.bind(styles)
const app_type = process.env.REACT_APP_TYPE || 'TEEKO'
type Props = {
  hideContacts?: boolean,
  isShort?: boolean,
  classNames?: any,
  type?: string,
}

function Footer({hideContacts = false, isShort = false, classNames = '', type = ''}: Props) {
  const [t] = useTranslation()
  const {pathname} = useLocation()
  return (
    <footer
      className={cx('Component',
        {
          Component_white_bg: (pathname === '/search' || pathname === '/dashboard' || type === 'white')
        })}>
      {!isShort && (
        <div className={cx('Main')}>
          <div className={cx('Container', classNames)}>
            <nav className={cx('Nav')}>
              <h4><Link to='/organizers'>{t('footer.resources')}</Link></h4>
              <Link to='/organizers'>{t('footer.organizerLanding')}</Link>
              <Link to='/terms'>{t('footer.terms')}</Link>
              <Link to='/privacy'>{t('footer.policy')}</Link>
              <Link to='/refunds_and_cancellation_policy'>{t('footer.cancellationPolicy')}</Link>
              <Link to='/faq'>{t('footer.faq')}</Link>
            </nav>
            <div className={cx('Contacts')}>
              <h4> {t('footer.office.title')}</h4>
              <address>
                <IconPin className={cx('IconPin')}/>
                46712291405, Vilniaus m. sav., Vilniaus m. sav. teritorija
              </address>

              <Fragment>
                <a href="tel:+37062489695"><IconPhone/> +37062489695</a>
                <a href="mailto:support@satchel.events"><IconEmail/> support@satchel.events</a>
              </Fragment>
              {/*<a href="https://t.me/teekohelp" target={'_blank'}> {t('footer.telegram')}</a>*/}
            </div>
            {/*<div className={cx('Subscribe')}>*/}
            {/*  {app_type === 'TEEKO' && <SubscribeForm/>}*/}
            {/*  <div className={cx('Socials')}>*/}
            {/*    <a href="https://www.instagram.com/teekoio/" target={"_blank"}><IconInstagram/></a>*/}
            {/*    <a href="https://www.facebook.com/teekoio" target={"_blank"}><IconFacebook/></a>*/}
            {/*    <a href="https://t.me/teeko_events" target={"_blank"}><IconTelegram/></a>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      )}
      <div className={cx('Additional', classNames)}>
        <div className={cx('PaymentSystems')}>
          <div><img src={require('assets/img/paymentSystems/master_card_logo.svg')} alt=""/></div>
          <div><img width={65} src={require('assets/img/paymentSystems/mastercard_id_check.jpg')} alt=""/></div>
          <div><img src={require('assets/img/paymentSystems/visa_logo.svg')} alt=""/></div>
          <div><img width={55} src={require('assets/img/paymentSystems/visa_secure.jpg')} alt=""/></div>
        </div>
        <p>© {app_type.toLowerCase()}, 2023</p>
      </div>
    </footer>
  )
}


export default Footer
