import React, {FC, forwardRef, memo, useState} from "react";
import s from './index.module.scss';
import classnames from "classnames/bind";
import {useTranslation} from "react-i18next";
import {isSatchel} from "constants/";
import {UploadFilePropsType} from "../../index.props";
import UploadFile from "../..";
import IconClose from "components/Icons/Close";
import {ProccessLoadingIcon} from "containers/Pages/Event/Icons/Icons";

const cx = classnames.bind(s);
const UploadImage: FC<Omit<UploadFilePropsType, 'setLoad' | 'loading' | 'onChangeUpload'> & {
  file: any | null, onRemove: () => void, text?: string, view?: 'area' | 'text'
} & { ref: any }> =
  forwardRef(({
                className,
                children,
                file,
                onRemove,
                text,
                onChange,
                view = 'area',
                ...uploadFileProps
              }, ref) => {
      const [loading, setLoading] = useState(false)
      const [fileName, setFileName] = useState<string | null>(null)
      const [t] = useTranslation()
      const isViewText = view === 'text'
      const onUpload = async (file: any, fileName: string) => {
        try {
          setFileName(fileName)
          await onChange?.(file);
        } catch (e) {
          // @ts-ignore

        } finally {
          setLoading(false)
        }
      }

      return (
        <div className={cx('Component', {ComponentText: isViewText})}>
          <UploadFile
            loading={loading}
            setLoad={setLoading}
            // @ts-ignore
            ref={ref}
            type={"file"}
            className={cx('UploadFile', className ?? '')}
            onChangeUpload={onUpload}
            {...uploadFileProps}

          >
            {file
              ? <div className={cx('FileName')}>{fileName}</div>
              : <span
                className={`button-orange-light ${isSatchel ? 'button-orange-light-black' : ''}`}>{t('event.downloadFile')}</span>
            }

            <span className={cx('Pen')}>
              {loading && <div className={cx('loading')}>
                <ProccessLoadingIcon
                  // @ts-ignore*
                  width={50} height={50} fill={'var(--accent-color)'}/>
              </div>}
            </span>
            {!file && text && <p className={cx('Text')}>{t(text)}</p>}

          </UploadFile>
          {file && (
            <>
              <button onClick={() => {
                setFileName(null)
                onRemove && onRemove()
              }} className={cx("Close")}>
                <IconClose/>
              </button>
            </>
          )}
        </div>
      )
    }
  )
export default memo(UploadImage)