import imageCompression from "browser-image-compression";

export const toBase64 = (file: any): Promise<any> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  if (!file) return;
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const compress = async (file: any,
                               options: { maxSizeMB?: number } = {
                                 maxSizeMB: 2
                               }) => {
  try {
    return await imageCompression(file, options);
  } catch (err) {
    console.error(err)
    return null
  }
}