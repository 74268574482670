import {combineReducers} from 'redux'
import {connectRouter, RouterState} from 'connected-react-router'
import Base from './Base/reducer'
import Profile from './Profile/reducer'
import Notifications from './Notifications/reducer'
import Event from './Event/reducer'
import EventEdit from './EventEdit/reducer'
import Posters from './Posters/reducer'
import {History} from 'history'
import {financesReducer} from "./Finances/financesReducer";
import {Reducer} from "./PromoAndDiscountReducer/reducer";

export const initialState = {}

export default (history: History) => combineReducers({
  router: connectRouter(history),
  Base,
  PromoAndDiscountReducer: Reducer as any,
  Notifications,
  Event,
  EventEdit: EventEdit as any,
  Posters,
  Profile,
  financesReducer: financesReducer as any,


})

export interface ApplicationState {
  Base: ReturnType<typeof Base>
  Profile: ReturnType<typeof Profile>
  PromoAndDiscountReducer: ReturnType<typeof Reducer>
  Posters: ReturnType<typeof Posters>,
  EventEdit: ReturnType<typeof EventEdit>,
  Event: ReturnType<typeof Event>,
  financesReducer: ReturnType<typeof financesReducer>,
  router: RouterState,
}

export type EventEditReducerType = ReturnType<typeof EventEdit>

