export default {
  intro: {
    header: "Service to sell tickets on your events",
    analytics: "Analytics\nand reports",
    tickets: "PromoAndDiscount \nvalidation and activation",
    money: "Withdraw profit \nto the card",
    notifications: "Client \nnotifications via sms",
    fb: "Synchronization with your event \nFacebook page",
    promocode: "Promocodes\nwith discounts",
  },
  howItWorks: {
    title: "How it works?",
    items: [
      {
        header: "Create and edit your events",
        text:
          "Our system allows you to create and manage your event. You can instantly understand how many tickets are sold and how much you earned so far. You can setup the look and feel of the page to align with your brand and communicate to the audience with event cover image and description",
        list: [
          "fast signup 24/7",
          "Accounts with different access levels",
          "Clients notification via sms",
        ],
      },
      {
        header: "Easier marketing for your events",
        text:
          "Our system provides easy to use tools to export and then import attendees lists to your mail campaigns tools like MailChimp, Sendgrid or eSputnik.",
        list: [
          "Embed sales script on your site",
          "Flexible discounts system via promocodes",
          "Use our tools with marketing tools integration",
        ],
      },
      {
        header: "Manage your attendees and check the results",
        text:
          "Different reports will help you to understand which channels are most efficient. Also you can easily import and export your attendees base.",
        list: [
          "Different reports",
          "Clients import and export",
          "Understand efficient sales channels with our reports",
        ],
      },
    ],
  },
  pricing: {
    title: "Pricing",
    items: [
      {
        header: "Free for event organizers",
        text: "No monthly payments and subscriptions to use our service",
      },
      {
        header: "Free tickets to free events",
        text: "All functionality is free of charge for free events",
      },
      {
        header: "Low fee for paid tickets",
        text:
          "We charge 5% fee in total for payments processing and platform service",
      },
    ],
  },
  aboutUs: {
    title: "Best assistant for your events management",
    text:
      "satchel.events – is the platform to organize and manage events. We have considered the best experience and feedback from tens of event organizers to provide the best and most useful features. Our goal is to make event management easy and productive for you.",
  },
  advantages: {
    title: "Advantages",
    header: "Advantages using our service",
    items: [
      {
        header: "Support\n answering your questions",
        text: "We support you from the first page till the last attendee",
      },
      {
        header: "Fast sales launch\n",
        text: "It usually takes 1-2 hours to 1 day to launch sales",
      },
      {
        header: "Fast withdraw\n from ticket sales",
        text:
          "With us you receive money within 1-2 days when payment is done and not after the event",
      },
    ],
  },
  startCooperate: {
    header: "Start tickets sales with satchel.events and earn more money!",
    btn: "Start now",
  },
};
