import ActionTypes from './actionTypes'
import update from 'immutability-helper'

const SET_DISCOUNTS = 'SET_DISCOUNTS'
const EDIT_PROMOCODE = 'EDIT_PROMOCODE'
const CLEAN_PROMOCODE = 'CLEAN_PROMOCODE'
const SET_SUBSCRIBE = 'SET_SUBSCRIBE'
const SET_TOTAL = 'SET_TOTAL'

export const setDiscounts = (discounts) => ({type: SET_DISCOUNTS, discounts})
export const editPromocode = (value, ticketId, error = '') => ({type: EDIT_PROMOCODE, value, ticketId, error})
export const cleanPromocode = () => ({type: CLEAN_PROMOCODE})
export const setSubscribe = (value) => ({type: SET_SUBSCRIBE, value})
export const setTotal = (total) => ({type: SET_TOTAL, total})

let initialState = {
  fetchSeats: null,
  isSubscribed: true,
  ticketTypes: {},
  attendees: {},
  checkoutUrl: '',
  paymentSubmitted: false,
  orders: [],
  bookPlaces: {},
  attendeesCheckDiscount: [],
  promocodes: {},
  questionTypePromocode: {},
  total: null,
  eventLanguage: null
}

const Event = (state = initialState, action) => {
  const {type, payload} = action
  switch (type) {
    case ActionTypes.CHANGE_EVENT_LANG:
      return {
        ...state, eventLanguage: action.payload
      }
    case SET_TOTAL:
      return {
        ...state, total: action.total
      }
    case SET_DISCOUNTS:
      return {
        ...state,
        attendeesCheckDiscount: action.discounts,
      }
    case EDIT_PROMOCODE :
      const key = action.ticketId
      const obj = {
        [action.ticketId]: {value: action.value, ticketId: action.ticketId, error: ''},
      }
      return {
        ...state,
        promocodes: {...state.promocodes, ...obj},
      }

    case CLEAN_PROMOCODE:
      return {
        ...state, promocodes: {}
      }
    case SET_SUBSCRIBE:
      return {
        ...state, isSubscribed: action.value
      }
    case ActionTypes.RESET:
      return {...initialState}
    case ActionTypes.ON_CREATE_ORDER:
      return {
        ...state,
        checkoutUrl: payload.checkoutUrl,
        attendees: [],
        orders: payload.order,
        paymentSubmitted: !payload.checkoutUrl,
      }
    case ActionTypes.EDIT_ORDER_INFO:
      return {...state, [payload.field]: payload.value}
    case ActionTypes.SET_ORDER_ID:
      return {...state, order: payload}
    case ActionTypes.SET_ATTENDEES:
      if (payload) return {...state, attendees: payload}

      const attendees = {}
      const promocodes = {...state.promocodes}
      // Array of ticketType ids
      // [38,39,40,50,55]
      Object.keys(state.ticketTypes).forEach(ttId => {
        if (!(ttId in promocodes)) {
          promocodes[ttId] = {value: '', error: '', ticketId: ttId}
        }
        attendees[ttId] = state.attendees[ttId] || []

        const ticketType = state.ticketTypes[ttId]
        const attendeesLength = attendees[ttId].length
        const {selected, simplePay, withPromocode} = ticketType

        if ((attendeesLength === selected) || (simplePay && selected !== 0 && attendeesLength !== 0)) return null

        if (attendeesLength > selected) {
          attendees[ttId] = attendees[ttId].slice(0, selected)
          return null
        }

        // If ticketType have any active coupons -- add coupon field for attendee config

        const questionsOrder = {}
        ticketType.questionsOrder.forEach(q => {
          questionsOrder[q.id] = q
        })
        const form = {
          questionTypePromocode: null,
          questionsOrder,
          row: null,
          rowError: '',
          seat: null,
          seatError: '',
          coupon: withPromocode ? {value: ''} : undefined,
        }

        const generateForms = simplePay ? 1 : selected - attendeesLength

        for (let i = 0; i < generateForms; i++) {
          attendees[ttId].push({
            id: Math.random(),
            ...form,
          })
        }
      })
      return {...state, attendees, promocodes}
    case ActionTypes.REMOVE_ATTENDEE:
      return update(state, {
        ticketTypes: {
          [payload.ticketTypeId]: (ticketType) => {
            return {
              ...ticketType,
              selected: ticketType.simplePay ? 0 : ticketType.selected - 1,
            }
          },
        },
        attendees: {
          [payload.ticketTypeId]: (attendees) => ([
            ...attendees.slice(0, payload.attendeeIndex),
            ...attendees.slice(payload.attendeeIndex + 1),
          ]),
        },
      })
    case ActionTypes.SET_TICKET_TYPES:
      return {...state, ticketTypes: payload}
    case ActionTypes.EDIT_TICKET_TYPE:
      return update(state, {
        ticketTypes: {
          [payload.id]: {
            selected: {
              $set: payload.value,
            },
          },
        },
      })
    case ActionTypes.EDIT_ATTENDEE_COUPON:
      return update(state, {
        attendees: {
          [payload.ticketTypeId]: {
            [payload.attendeeIndex]: {
              coupon: {
                [payload.field]: {
                  $set: payload.value,
                },
              },
            },
          },
        },
      })
    case ActionTypes.EDIT_ATTENDEE:
      return update(state, {
        attendees: {
          [payload.ticketTypeId]: {
            [payload.attendeeIndex]: {
              questionsOrder: {
                [payload.questionId]: {
                  [payload.field]: {
                    $set: payload.value,
                  },
                },
              },
            },
          },
        },
      })
    case ActionTypes.EDIT_ATTENDEE_ROW:
      return update(state, {
        attendees: {
          [payload.ticketTypeId]: {
            [payload.attendeeIndex]: {
              [payload.field]: {
                $set: payload.value,
              },
            },
          },
        },
      })
    case ActionTypes.EDIT_QUESTION_PROMOCODE:
      return update(state, {
        attendees: {
          [payload.ticketTypeId]: {
            [payload.attendeeIndex]: {
              questionTypePromocode: {$set: payload.promoValue}
            },
          },
        },
      })

    default:
      return state
  }
}

export default Event
